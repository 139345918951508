<template>
  <div class="grid grid-cols-12 gap-6 mt-8">
    <AsideModules :course="course" />
    <div class="col-span-12 lg:col-span-9 2xl:col-span-10">
      <!-- BEGIN: File Manager Filter -->
      <div
        class="
          intro-y
          flex flex-col-reverse
          sm:flex-row
          items-center
          justify-end
        "
      >
        <router-link
          :to="{
            name: 'course-pages-create',
            param: { slug: this.$route.params.slug },
          }"
          data-toggle="modal"
          data-target="#create-modules-modal"
          class="btn btn-primary"
          >Добавить страницу
        </router-link>
      </div>
      <!-- END: File Manager Filter -->
      <div>
        <div class="intro-y col-span-12 overflow-auto">
          <table class="table table-report mt-5">
            <thead>
              <tr>
                <th class="whitespace-nowrap">Название</th>
                <th class="text-center whitespace-nowrap">Статус</th>
                <th class="text-center whitespace-nowrap">Действие</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(page, index) in pages.data"
                :key="index"
                class="intro-x"
              >
                <td class="truncate">
                  <router-link
                    :to="{
                      name: 'course-pages-show',
                      params: { slug: this.slug, id: page.slug },
                    }"
                    class="font-medium"
                    >{{ page.title }}</router-link
                  >
                </td>
                <td class="w-40">
                  <div
                    class="flex items-center justify-center"
                    :class="{
                      'text-theme-9': !page.deleted_at,
                      'text-theme-6': page.deleted_at,
                    }"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-2" />
                    {{ page.deleted_at ? "Удален " : "Активен" }}
                  </div>
                </td>
                <td class="table-report__action w-56">
                  <div class="flex justify-center items-center">
                    <router-link
                      :to="{
                        name: 'course-pages-update',
                        params: {
                          slug: this.$route.params.slug,
                          id: page.slug,
                        },
                      }"
                      class="flex items-center mr-3"
                    >
                      <CheckSquareIcon class="w-4 h-4 mr-1" />
                      Изменить
                    </router-link>
                    <button
                      type="button"
                      class="flex items-center text-theme-6"
                      @click="$refs.deleteModal.openModal(page.slug)"
                    >
                      <Trash2Icon class="w-4 h-4 mr-1" /> Удалить
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <Pagination
          v-if="pages"
          :per_page="pages.per_page"
          :total="pages.total"
          :current-page="currentPage"
        />

        <AlertRemove ref="deleteModal" :onDelete="onDelete" />
      </div>
    </div>
  </div>
</template>

<script>
import AsideModules from '@/components/aside/Modules'

export default {
  components: {
    AsideModules
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1')
    },
    course() {
      return this.$store.state.courses.course
    },
    slug() {
      return this.$route.params.slug
    },
    pages() {
      return this.$store.state.pages.pages
    }
  },
  watch: {
    currentPage() {
      this.pagination(this.currentPage)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$store.dispatch('courses/getCourse', this.slug)
      this.$store.dispatch('pages/getPages', { course_slug: this.slug })
    },
    pagination(page) {
      this.$store.dispatch('pages/getPages', {
        course_slug: this.slug,
        pages: page
      })
    },
    onDelete(deletingItem) {
      this.$api.delete(`/dashboard/course/${this.slug}/pages/` + deletingItem)
      this.$store.dispatch('pages/getPages', { course_slug: this.slug })
    }
  }
}
</script>
