<template>
    <!-- BEGIN: Delete Confirmation Modal -->
    <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <div class="p-5 text-center">
                        <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
                        <div class="text-3xl mt-5">Вы уверены?</div>
                        <div class="text-gray-600 mt-2">
                            Вы действительно хотите удалить эти записи? <br /> Этот процесс не может быть отменен.
                        </div>
                    </div>
                    <div class="px-5 pb-8 text-center">
                        <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">
                            Отменить
                        </button>
                        <button type="button" @click="deleteing" class="btn btn-danger w-24">Удалить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
</template>

<script>
export default {
  props: ['onDelete'],
  data() {
    return {
      deletingItem: 0,
      otherData: null
    }
  },
  methods: {
    deleteing() {
      cash('#delete-confirmation-modal').modal('hide')
      this.onDelete(this.deletingItem, ...this.otherData)
    },
    openModal(id, ...props) {
      this.deletingItem = id
      this.otherData = [...props]
      cash('#delete-confirmation-modal').modal('show')
    }
  }
}
</script>
