import AlertRemove from './modals/Alert/Remove'
import Pagination from './pagination/Main'
import LangSwitcher from './multi-lang/Switcher'
import LangContent from './multi-lang/Content'
import Chart from './chart/Main.vue'
import Litepicker from './litepicker/Main.vue'
import Tippy from './tippy/Main.vue'
import TomSelect from './tom-select/Main.vue'
import TinySlider from './tiny-slider/Main.vue'
import ClassicEditor from './ckeditor/ClassicEditor.vue'
import Dropzone from './dropzone/Main.vue'
import * as featherIcons from '@zhuowenli/vue-feather-icons'
import { Can } from '@casl/vue'

export default app => {
  app.component('Can', Can)
  app.component('Chart', Chart)
  app.component('Litepicker', Litepicker)
  app.component('Tippy', Tippy)
  app.component('TomSelect', TomSelect)
  app.component('TinySlider', TinySlider)
  app.component('Dropzone', Dropzone)
  app.component('ClassicEditor', ClassicEditor)
  app.component('AlertRemove', AlertRemove)
  app.component('Pagination', Pagination)
  app.component('LangSwitcher', LangSwitcher)
  app.component('LangContent', LangContent)

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = '24'
    app.component(key, icon)
  }
}
