<template>
    <div>
        <div class="intro-y flex items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Изменить слайдер</h2>
          <LangSwitcher @changeLang="changeLang" />
        </div>
        <LangContent>
          <form @submit.prevent="save">
            <div class="intro-y grid grid-cols-12 gap-5 mt-8">
              <div class="intro-y col-span-12 lg:col-span-8">
                <!-- BEGIN: Display Information -->
                <div class="intro-y box">
                    <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mr-auto">Данные слайдера</h2>
                    </div>
                    <div class="p-5">
                        <div class="flex xl:flex-row flex-col">
                            <div class="flex-1">
                                <div class="grid grid-cols-12 gap-x-5">
                                    <div class="mt-2 col-span-12 2xl:col-span-6">
                                        <label for="create-slider-hash_title" class="form-label">#-Хеш заголовок</label>
                                        <input id="create-slider-hash_title" type="text" class="form-control" required
                                            placeholder="Введите хэш заголовок" v-model="hash_title[lang]" />
                                    </div>
                                    <div class="mt-2 col-span-12 2xl:col-span-6">
                                        <label for="create-slider-title" class="form-label">Заголовок</label>
                                        <input id="create-slider-title" type="text" class="form-control" required
                                            placeholder="Введите заголовок" v-model="title[lang]" />
                                    </div>
                                    <div class="mt-3 col-span-12">
                                        <label for="create-slider-description" class="form-label">Описание</label>
                                        <textarea id="create-slider-description" class="form-control"
                                            placeholder="Введите описание" v-model="description[lang]"></textarea>
                                    </div>
                                    <div class="col-span-12 2xl:col-span-7 alert alert-secondary show mb-2 mt-5" role="alert">
                                      <div class="flex items-center">
                                          <div class="font-medium text-lg">Придупреждение ознакомьтесь</div>
                                          <div class="text-xs bg-gray-800 px-1.5 rounded-md text-white ml-auto">Обязятельно</div>
                                      </div>
                                      <div class="mt-3">Чтобы слайдер работала коректно желательно заполнить <br> все поля которые выши прописаны</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <!-- BEGIN: Post Info -->
              <div class="col-span-12 lg:col-span-4">
                  <div class="intro-y box p-5">
                      <div class="mt-3">
                        <label class="form-label">Загрузите изображение</label>
                        <div class="border-2 border-dashed dark:border-dark-5 rounded-md pt-4">
                            <div class="flex flex-wrap px-4">
                                <div v-if="image"
                                    class="w-24 h-24 relative image-fit mb-5 mr-5 cursor-pointer zoom-in">
                                    <img class="rounded-md" alt="Smarthub"
                                        :src="image" />
                                    <Tippy tag="div" content="Удалить постер" @click="removeImage(1)"
                                        class="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 -mt-2">
                                        <xIcon class="w-4 h-4" />
                                    </Tippy>
                                </div>
                            </div>
                            <div class="px-4 pb-4 flex items-center cursor-pointer relative">
                                <ImageIcon class="w-4 h-4 mr-2" />
                                <span class="text-theme-1 dark:text-theme-10 mr-1">Загрузить файл</span>
                                или перетащите
                                <input type="file" @change="onFileChange($event, 1)"
                                    class="w-full h-full top-0 left-0 absolute opacity-0" />
                            </div>
                        </div>
                      </div>
                      <div class="mt-3">
                        <label for="create-slider-url" class="form-label">URL-ссылка</label>
                        <input id="create-slider-url" type="url" class="form-control"
                            placeholder="Введите url - адресс" v-model="form.url" />
                      </div>
                  </div>
                  <button class="btn btn-primary shadow-md flex items-center mt-5 w-full"
                      type="submit">
                      Сохранить
                  </button>
              </div>
              <!-- END: Post Info -->
            </div>
          </form>
        </LangContent>
    </div>
</template>

<script>

export default {
  data() {
    return {
      hash_title: {},
      title: {},
      description: {},
      form: {
        url: '',
        slider_type_id: 1
      },
      lang: 'ru',
      image: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    changeLang(lang) {
      this.lang = lang
    },
    async init() {
      await this.$api.get(`dashboard/group/${this.$route.params.id}/sliders/${this.$route.params.id}`, { headers: { lang: 'mix' } }).then((result) => {
        this.hash_title = result.hash_title ?? {}
        this.title = result.title ?? {}
        this.description = result.description ?? {}
        this.form = {
          url: result.url,
          slider_type_id: result.slider_type_id ?? 1
        }
        this.image = result.image
      })
    },
    onFileChange(e) {
      this.form.image = this.$h.readFile(e)
      this.createImage(this.form.image)
    },
    createImage(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.image = e.target.result
      }
      reader.readAsDataURL(file)
    },
    removeImage() {
      this.image = ''
      this.form.image = ''
    },
    save() {
      const formData = new FormData()

      this.form.image && formData.append('image', this.form.image)
      this.$_.each(this.hash_title, (value, key) => {
        formData.append(`hash_title[${key}]`, value)
      })
      this.$_.each(this.title, (value, key) => {
        formData.append(`title[${key}]`, value)
      })
      this.$_.each(this.description, (value, key) => {
        formData.append(`description[${key}]`, value)
      })
      this.$_.each(this.form, (value, key) => {
        formData.append(key, value)
      })
      this.$api.put('dashboard/sliders/' + this.$route.params.id, formData).then((result) => {
        this.$router.push({ name: 'sliders' })
      })
    }
  }
}
</script>
