<template>
    <div class="col-span-12 space-y-2 mt-2">
        <label for="validation-form-1" class="form-label w-full flex flex-col sm:flex-row">
            Введите ответы
            <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">Обязательное поле</span>
        </label>
        <input type="text" class="form-control" v-model="newAnswer" @keyup.enter="addAnswer" placeholder="Введите ответ и нажмите enter" />
    </div>

    <div class="answers col-span-12 space-y-2 intro-y">
        <div class="overflow-x-auto">
            <table class="table" v-if="variant.answers.length > 0">
                <thead>
                    <tr>
                        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Описание
                        </th>
                        <th class="border-b-2 dark:border-dark-5 text-right whitespace-nowrap">
                            Действие
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="intro-x" v-for="(answer, index) in variant.answers" :key="index">
                        <td class="border-b dark:border-dark-5">
                            <div class="flex items-center">
                                <span class="font-medium whitespace-nowrap mr-1 truncate">
                                    {{ answer }}
                                </span>
                                <!-- <EditIcon class="w-4 h-4 text-gray-600 ml-2"
                                    @click="editAnswer(index)" /> -->
                            </div>
                        </td>
                        <td class="answer-actions flex justify-end items-center space-x-3 border-b dark:border-dark-5 font-medium">
                            <div class="answer--accept">
                                <button type="button" class="text-theme-9" @click="checkAnswer(answer,  index)" :disabled="correct_answers.indexOf(answer) > -1">
                                    <CheckCircleIcon class="w-4 h-4 mr-2" />
                                    <span>Выбрать</span>
                                </button>
                            </div>
                            <div class="answer--remove">
                                <button type="button" class="text-theme-6" @click="removeAnswer(index)">
                                    <Trash2Icon class="w-4 h-4 mr-2" />
                                    <span>Удалить</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h3 class="text-lg font-semibold text-center mt-5 mb-2" v-if="correct_answers.length > 0">
                Правильные варианты
            </h3>
            <table class="table" v-if="correct_answers.length > 0">
                <thead>
                    <tr>
                        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                            Описание
                        </th>
                        <th class="border-b-2 dark:border-dark-5 text-right whitespace-nowrap">
                            Действие
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="-intro-x" v-for="(answer, index) in correct_answers" :key="index">
                        <td class="border-b dark:border-dark-5">
                            <div class="flex items-center">
                                <span class="font-medium whitespace-nowrap mr-1 truncate">
                                    {{ answer }}
                                </span>
                                <!-- <EditIcon class="w-4 h-4 text-gray-600 ml-2"
                                    @click="editAnswer(index)" /> -->
                            </div>
                        </td>
                        <td class="answer-actions flex justify-end items-center space-x-3 border-b dark:border-dark-5 font-medium">
                            <div class="answer--remove">
                                <button type="button" class="text-theme-6" @click="removeCorrectAnswer(index)">
                                    <Trash2Icon class="w-4 h-4 mr-2" />
                                    <span>Удалить</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</template>

<script>
export default {
  props: ['saver', 'count', 'defaultVariant'],
  data() {
    return {
      newAnswer: '',
      // The main logic
      variant: {
        answers: [],
        correct_answers: []
      },
      // This is just text
      correct_answers: []
    }
  },
  watch: {
    variant: {
      handler(newVariant) {
        this.saver(newVariant)
      },
      deep: true
    },
    count() {
      Object.assign(this.$data, this.$options.data())
    },
    defaultVariant: {
      handler(value) {
        if (value) {
          this.variant = {
            answers: [...value.answers],
            correct_answers: [...value.correct_answers]
          }

          this.correct_answers = [...this.variant.answers.filter((_, index) => this.variant.correct_answers.indexOf(index) > -1)]
        }
      },
      deep: true
    }
  },
  methods: {
    addAnswer() {
      if (this.newAnswer.trim().length === 0) {
        return
      }

      if (this.variant.answers.indexOf(this.newAnswer) > -1) {
        return alert('Repeat')
      }

      this.variant.answers.push(this.newAnswer)
      this.newAnswer = ''
    },

    removeCorrectAnswer(index) {
      this.correct_answers.splice(index, 1)
      this.variant.correct_answers.splice(index, 1)
    },

    removeAnswer(index) {
      const search = this.variant.answers.splice(index, 1)[0]

      const itemIndex = this.correct_answers.indexOf(search)

      if (itemIndex > -1) {
        this.removeCorrectAnswer(itemIndex)
      }
    },

    checkAnswer(variant, index) {
      this.correct_answers.push(variant)
      this.variant.correct_answers.push(index)
    },

    validate() {
      if (!this.variant.answers.length) {
        return 'You shoul add possible answer'
      } else if (!this.variant.correct_answers.length) {
        return 'You shoul add some correct answers'
      }
    }
  }
}
</script>
