<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Системное пополнение баланса</h2>
    </div>
    <div class="intro-y box p-5 mt-5">
      <div>
        <form @submit.prevent="addBalance">
          <div class="flex gap-5 items-center justify-between w-full">
            <div class="flex-1">
              <label for="post-form-category" class="form-label">Email пользователя <span class="text-theme-6">*</span></label>
              <input id="post-form-price" type="email" required class="form-control" v-model="form.email"
                placeholder="Введите email" />
            </div>
            <div class="flex-1">
              <label for="post-form-price" class="form-label">Сумма пополнение <span class="text-theme-6">*</span></label>
              <input id="post-form-price" type="number" class="form-control" v-model="form.amount" maxlength="6"
                placeholder="Введите сумму" />
            </div>
            <div class="flex-1">
              <label for="post-form-price" class="form-label">Описание</label>
              <input id="post-form-price" type="text" class="form-control" v-model="form.description" maxlength="254"
                placeholder="Введите описание" />
            </div>
            <div class="flex-1">
              <button
                class="btn btn-primary shadow-md flex items-center mt-5 w-full disabled:btn-danger disabled:cursor-not-allowed dis"
                aria-expanded="false" type="submit" :disabled="form.isSubmitting">
                {{ form.isSubmitting ? 'Пожалуйста подождите' : 'Пополнить' }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Транзакции</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Поле</label>
            <select id="tabulator-html-filter-field" v-model="filter.field"
              class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto">
              <option value="user">Пользователь</option>
              <option value="description">Описание</option>
              <option value="amount">Сумма</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Тип</label>
            <select id="tabulator-html-filter-type" v-model="filter.type"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto">
              <option value="like" selected>Все</option>
              <option value="=">=</option>
              <option value="<">&lt;</option>
              <option value="<=">&lt;=</option>
              <option value=">">></option>
              <option value=">=">>=</option>
              <option value="!=">!=</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Значение</label>
            <input id="tabulator-html-filter-value" v-model="filter.value" type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0" placeholder="Поиск..." />
          </div>
          <div class="mt-2 xl:mt-0">
            <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full sm:w-16" @click="onFilter">
              Искать
            </button>
            <button id="tabulator-html-filter-reset" type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1" @click="onResetFilter">
              Сбросить
            </button>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2" @click="onPrint">
            <PrinterIcon class="w-4 h-4 mr-2" /> Печатать
          </button>
          <div class="dropdown w-1/2 sm:w-auto">
            <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
              <FileTextIcon class="w-4 h-4 mr-2" /> Экспортировать
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a id="tabulator-export-csv" href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv">
                  <FileTextIcon class="w-4 h-4 mr-2" /> Экспортировать в CSV
                </a>
                <a id="tabulator-export-json" href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson">
                  <FileTextIcon class="w-4 h-4 mr-2" /> Экспортировать в JSON
                </a>
                <a id="tabulator-export-xlsx" href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx">
                  <FileTextIcon class="w-4 h-4 mr-2" /> Экспортировать в XLSX
                </a>
                <a id="tabulator-export-html" href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportHtml">
                  <FileTextIcon class="w-4 h-4 mr-2" /> Экспортировать в HTML
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import token from '@/services/auth/header'
import moment from 'moment'
import 'moment/locale/ru'

export default defineComponent({
  setup() {
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'name',
      type: 'like',
      value: ''
    })

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Пользователь',
            minWidth: 200,
            responsive: 0,
            field: 'user',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="font-medium whitespace-nowrap">${cell.getData().balanceable?.fullname
                }</div>
                <div class="text-gray-600 text-xs whitespace-nowrap">${cell.getData().balanceable?.email
                }</div>
              </div>`
            }
          },
          {
            title: 'Описание',
            minWidth: 200,
            field: 'description',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Сумма',
            minWidth: 200,
            field: 'amount',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center text-base ${cell.getData().amount > 0 ? 'text-theme-9' : 'text-theme-6'
                }">
               ${cell.getData().amount * 100}
              </div>`
            }
          },
          {
            title: 'Дата',
            minWidth: 200,
            field: 'created_at',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return moment(cell.getData().created_at).format('LLL')
            }
          },

          // For print format
          {
            title: 'Пользователь',
            field: 'user',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Описание',
            field: 'description',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Сумма',
            field: 'amount',
            visible: false,
            print: true,
            download: true,
            formatterPrint(cell) {
              return cell.getValue()
            }
          },
          {
            title: 'Дата',
            field: 'created_at',
            visible: false,
            print: true,
            download: true,
            formatterPrint(cell) {
              return moment(cell.created_at).format('LLL')
            }
          }
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
      const ajaxConfig = {
        method: 'GET',
        headers: {
          Authorization: token
        }
      }

      tabulator.value.setData(
        `${process.env.VUE_APP_BASE_URL_API}/dashboard/transactions`,
        {},
        ajaxConfig
      )
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'name'
      filter.type = 'like'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })

    return {
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint
    }
  },
  data() {
    return {
      form: {
        email: '',
        amount: null,
        description: null,
        isSubmitting: false
      }
    }
  },
  methods: {
    addBalance() {
      this.form.isSubmitting = true

      this.$api.post('/dashboard/transactions/increase-balance', { ...this.form }).then((result) => {
        this.form.isSubmitting = false
        location.reload()
      }).catch((err) => {
        console.log(err)
        this.form.isSubmitting = false
      })
    }

  }
})
</script>
