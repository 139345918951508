<template>
    <div id="account" class="tab-pane active" role="tabpanel" aria-labelledby="account-tab">
        <form @submit.prevent>
          <div class="intro-y grid grid-cols-12 gap-5 mt-8">
            <div class="intro-y col-span-12 lg:col-span-8">
              <!-- BEGIN: Display Information -->
              <div class="intro-y box">
                  <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Персональная информация</h2>
                  </div>
                  <div class="p-5">
                      <div class="flex xl:flex-row flex-col">
                          <div class="flex-1">
                              <div class="grid grid-cols-12 gap-x-5">
                                  <div class="mt-2 col-span-12 2xl:col-span-6">
                                      <label for="create-employee-fullname" class="form-label">Ф.И.О</label>
                                      <input id="create-employee-fullname" type="text" class="form-control" readonly required
                                          placeholder="Введите Имя" :value="info?.fullname" />
                                  </div>
                                  <div class="mt-3 col-span-12 2xl:col-span-6">
                                      <label for="create-employee-email" class="form-label">E-mail</label>
                                      <input id="create-employee-email" type="email" class="form-control" readonly
                                          placeholder="Введите email" required :value="info?.email" />
                                  </div>
                                  <div class="mt-3 col-span-12 2xl:col-span-9">
                                      <label for="create-employee-nickname" class="form-label">Ник</label>
                                      <input id="create-employee-nickname" type="text" class="form-control" readonly
                                          placeholder="Введите Ник" required :value="info?.nick_name" />
                                  </div>
                                  <div class="mt-3 col-span-12">
                                      <label for="create-employee-addres" class="form-label">Адрес</label>
                                      <input id="create-employee-addres" class="form-control" readonly maxlength="64"
                                          placeholder="Введите адрес" :value="info?.address">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <!-- BEGIN: Post Info -->
            <div class="col-span-12 lg:col-span-4">
                <div class="intro-y box p-5">
                    <div class="mt-3">
                      <label for="create-employee-phone" class="form-label">Номер телефона</label>
                      <input id="create-employee-phone" type="tel" class="form-control" readonly
                          placeholder="Введите номер телефона" maxlength="9" minlength="9" :value="info?.phone" required />
                    </div>
                    <div class="mt-3">
                      <label class="form-label">Пол</label>
                      <TomSelect :value="info?.gender" required disabled
                          class="w-full">
                          <option value="M">Мужской</option>
                          <option value="W">Женский</option>
                      </TomSelect>
                    </div>
                    <div class="mt-3">
                        <label for="create-employee-age" class="form-label">Возраст</label>
                        <input id="create-employee-age" type="number" class="form-control" readonly required maxlength="2" minlength="1"
                            placeholder="Введите возраст" :value="info?.age" />
                    </div>
                </div>
            </div>
            <!-- END: Post Info -->
          </div>
        </form>
    </div>
</template>

<script>

export default {
  props: ['user'],
  computed: {
    info() {
      return this.user.user
    }
  }
}
</script>
