<template>
  <Listbox as="div" v-model="selected">
    <ListboxLabel class="sr-only"> Change published status </ListboxLabel>
    <div class="relative">
      <div class="inline-flex shadow-sm rounded-md divide-x divide-theme-24">
        <div
          class="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-theme-24"
        >
          <div
            class="relative inline-flex items-center bg-theme-26 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white"
          >
            <CheckIcon class="h-5 w-5" aria-hidden="true" />
            <p class="ml-2.5 text-sm font-medium">{{ selected.title }}</p>
          </div>
          <ListboxButton
            class="relative inline-flex items-center bg-theme-26 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-theme-24 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-theme-26"
          >
            <span class="sr-only">Change published status</span>
            <ChevronDownIcon class="h-5 w-5 text-white" aria-hidden="true" />
          </ListboxButton>
        </div>
      </div>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="origin-top-right absolute z-0 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <ListboxOption
            as="template"
            v-for="option in publishingOptions"
            :key="option.lang"
            :value="option"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                active ? 'text-white bg-theme-26' : 'text-gray-900',
                'cursor-default select-none relative p-4 text-sm'
              ]"
            >
              <div class="flex flex-col">
                <div class="flex justify-between">
                  <p :class="selected ? 'font-semibold' : 'font-normal'">
                    {{ option.title }}
                  </p>
                  <span
                    v-if="selected"
                    :class="active ? 'text-white' : 'text-theme-26'"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </div>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { ref } from 'vue'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions
} from '@headlessui/vue'

const publishingOptions = [
  {
    title: 'Выберите язык',
    lang: 'ru',
    current: false
  },
  {
    title: 'Русский',
    lang: 'ru',
    current: false
  },
  {
    title: 'Английский',
    lang: 'en',
    current: false
  },
  {
    title: 'Таджикский',
    lang: 'tj',
    current: false
  }
]

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions
  },
  watch: {
    selected(newValue, oldValue) {
      this.$emit('changeLang', newValue.lang)
    }
  },
  setup() {
    const selected = ref(publishingOptions[0])

    return {
      publishingOptions,
      selected
    }
  }
}
</script>
