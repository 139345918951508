<template>
  <div>
    <div class="flex justify-between items-center my-10 intro-y">
      <h2 class="text-lg font-medium">Слайдеры</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Страница</th>
              <th class="text-center whitespace-nowrap">Количество слайдов</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(slider, index) in sliders" :key="index" class="intro-x">
              <td class="w-60">
                <router-link
                  :to="{ name: 'sliders-show', params: { id: slider.id } }"
                  class="font-medium whitespace-nowrap"
                  >{{ slider.name }}</router-link
                >
              </td>
              <td class="text-center">{{ slider.sliders_count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sliders: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$api.get('/dashboard/group').then(response => {
        this.sliders = response
      })
    }
  }
}
</script>
