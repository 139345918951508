<template>
  <div class="grid grid-cols-12 gap-6 mt-8" v-if="course">
    <AsideModules :course="course" />
    <div class="col-span-12 lg:col-span-9 2xl:col-span-10">
      <!-- END: File Manager Filter -->
      <div class="w-full sm:w-auto flex justify-end mt-4 sm:mt-0">
        <button
          type="button"
          class="btn box text-white mr-2 flex items-center ml-auto sm:ml-0"
          :class="{
            'bg-theme-9': course.status?.name == 'draft',
            'bg-theme-7': course.status?.name == 'published'
          }"
          @click="toggleStatus(course.id)"
        >
          {{ course.status?.name == 'draft' ? 'Опубликовать' : 'В черновик' }}
        </button>
      </div>
      <div class="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-6">
        <!-- BEGIN: Boxed Accordion -->
        <div class="col-span-12">
          <div class="intro-y news p-5 box">
            <!-- BEGIN: Blog Layout -->
            <div class="flex justify-between items-center">
              <div>
                <h2 class="intro-y font-medium text-xl sm:text-2xl">
                  {{ course.title }}
                </h2>
                <div
                  class="intro-y text-gray-700 dark:text-gray-600 mt-3 text-xs sm:text-sm flex items-center"
                >
                  <div
                    class="flex items-center rounded-md font-medium"
                    v-if="course.status"
                  >
                    <span
                      :class="[
                        { 'bg-theme-16': course.status.name == 'draft' },
                        { 'bg-theme-12': course.status.name == 'pending' },
                        { 'bg-theme-9': course.status.name == 'published' },
                        'w-2 h-2 rounded-full mr-3'
                      ]"
                      v-if="course.category"
                    ></span>
                    {{ course.status.translate }}
                  </div>
                  <span class="mx-1">•</span>
                  {{ $h.formatDate(course.created_at, 'D MMMM YYYY') }}
                  <span class="mx-1">•</span>
                  <span
                    class="text-theme-1 dark:text-theme-10"
                    v-if="course.language"
                    >{{ course.language }}</span
                  >
                </div>
              </div>
              <router-link
                :to="{ name: 'courses-update', params: course.slug }"
                class="btn btn-warning"
                >Изменить</router-link
              >
            </div>
            <div class="intro-y mt-6">
              <div class="news__preview image-fit">
                <img
                  alt="Smarthub"
                  class="rounded-md w-100 h-auto"
                  :src="
                    course.image ??
                      require(`@/assets/images/${$f()[0].images[0]}`)
                  "
                  data-action="zoom"
                />
                <div
                  class="bg-black w-full h-full absolute rounded-md bg-opacity-25"
                ></div>
              </div>
            </div>
            <div
              class="intro-y flex relative pt-16 sm:pt-6 items-center pb-6"
              v-if="course.author"
            >
              <div class="intro-x flex mr-3">
                <div class="intro-x w-8 h-8 sm:w-10 sm:h-10 image-fit">
                  <Tippy
                    tag="img"
                    alt="Smarthub"
                    class="rounded-full border border-white zoom-in"
                    data-action="zoom"
                    :src="
                      course.author.thumbnail ??
                        require(`@/assets/images/${$f()[0].photos[0]}`)
                    "
                    :content="course.author.fullname"
                  />
                </div>
              </div>
              <div
                class="absolute sm:relative -mt-12 sm:mt-0 w-full flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm"
              >
                <div class="intro-x mr-1 sm:mr-3">
                  Студентов:
                  <span class="font-medium">{{
                    course.course_students_count
                  }}</span>
                </div>
                <div class="intro-x mr-1 sm:mr-3">
                  Цена:
                  <span
                    :class="[
                      {
                        'line-through': !course.discounted_price.startsWith(0)
                      },
                      'font-medium'
                    ]"
                    >{{ course.price }}</span
                  ><sup
                    class="text-sm"
                    v-if="!course.discounted_price.startsWith(0)"
                    >{{ course.discounted_price }}</sup
                  >
                </div>
                <div class="intro-x sm:mr-3 ml-auto">
                  Рейтинг: <span class="font-medium">{{ course.rating }}</span>
                </div>
              </div>
            </div>
            <article
              class="intro-y ck ck-content"
              v-html="course.description"
            ></article>
            <!-- END: Blog Layout -->
          </div>
        </div>
        <!-- END: Boxed Accordion -->
      </div>
    </div>
  </div>
</template>

<script>
import AsideModules from '@/components/aside/Modules'

export default {
  components: {
    AsideModules
  },
  computed: {
    course() {
      return this.$store.state.courses.course
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$store.dispatch('courses/getCourse', this.$route.params.slug)
    },
    toggleStatus(id) {
      this.$api.put('dashboard/status', { type: 'course', id: id }).then(() => {
        this.$router.push({ name: 'courses' })
      })
    }
  }
}
</script>
