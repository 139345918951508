<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-10">
      <h2 class="text-lg font-medium mr-auto">Список сотрудников</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          :to="{ name: 'employees-create' }"
          class="btn btn-primary shadow-md mr-2"
          >Добавить сотрудника</router-link
        >
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <!-- BEGIN: Users Layout -->
      <div
        v-for="(item, index) in employees.data"
        :key="index"
        class="intro-y col-span-12 md:col-span-6"
      >
        <div class="box">
          <div
            class="flex flex-col lg:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
              <img
                alt="Smarthub"
                class="rounded-full"
                :src="
                  item.image
                    ? item.image
                    : require('@/assets/images/profile-1.jpg')
                "
              />
            </div>
            <div
              class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0"
            >
              <router-link
                :to="`/employees/show/${item.id}`"
                class="font-medium"
                >{{ item.fullname }}</router-link
              >
              <div class="text-gray-600 text-xs mt-0.5">
                {{ item.email }}
              </div>
            </div>
            <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
              <button class="btn btn-danger py-1 px-2 mr-2" @click="$refs.deleteModal.openModal(item.id)">Удалить</button>
              <router-link
                :to="`/employees/show/${item.id}`"
                class="btn btn-primary py-1 px-2 mr-2"
                >Профиль</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <!-- END: employees Layout -->
    </div>
    <AlertRemove ref="deleteModal" :onDelete="onDelete" />

    <Pagination
      :per_page="employees.per_page"
      :total="employees.total"
      :current-page="currentPage"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      employees: []
    }
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1')
    }
  },
  watch: {
    currentPage() {
      this.init(this.currentPage)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$api
        .get('/dashboard/user', {
          params: {
            roles: ['instruction_designer', 'teacher', 'administrator'],
            page: this.currentPage
          }
        })
        .then(response => {
          this.employees = response
        })
    },
    onDelete(deletingItem) {
      this.$api.delete('/dashboard/user/' + deletingItem)
      this.init()
    }
  }
}
</script>
