<template>
  <Chart
    type="doughnut"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
  />
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    genders: {
      type: Object
    }
  },
  setup(props) {
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const data = computed(() => {
      return {
        labels: Array.from(Object.keys(props.genders)),
        datasets: [
          {
            data: Array.from(Object.values(props.genders)),
            backgroundColor: ['#1C3FAA', '#F78B00', '#FBC500'],
            hoverBackgroundColor: ['#1C3FAA', '#F78B00', '#FBC500'],
            borderWidth: 5,
            borderColor: darkMode.value ? '#303953' : '#fff'
          }
        ]
      }
    })
    const options = computed(() => {
      return {
        legend: {
          display: false
        },
        cutoutPercentage: 80
      }
    })

    return {
      data,
      options
    }
  }
})
</script>
