<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Все курсы</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <router-link
          :to="{ name: 'courses-create' }"
          class="btn btn-primary shadow-md mr-2"
          >Добавить новый курс</router-link
        >
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Название</th>
              <th class="text-center whitespace-nowrap">Рейтинг</th>
              <th class="text-center whitespace-nowrap">Статус</th>
              <th class="text-center whitespace-nowrap">Действие</th>
            </tr>
          </thead>
          <tbody v-if="courses">
            <tr
              v-for="(course, index) in courses.data"
              :key="index"
              class="intro-x"
            >
              <td>
                <router-link
                  :to="{ name: 'courses-show', params: { slug: course.slug } }"
                  class="font-medium whitespace-nowrap"
                  >{{ course.title }}</router-link
                >
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  {{ course.article }}
                </div>
              </td>
              <td class="text-center">{{ course.rating }}</td>
              <td class="w-40">
                <div
                  class="flex items-center justify-center"
                  :class="{
                    'text-theme-7': course.status?.name == 'draft',
                    'text-theme-9': course.status?.name == 'published'
                  }"
                >
                  <EyeOffIcon class="w-4 h-4 mr-2" v-if="course.status?.name == 'draft'"  />
                  <CheckCircleIcon class="w-4 h-4 mr-2" v-if="course.status?.name == 'published'" />
                  {{ course.status.translate }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link
                    class="flex items-center mr-3"
                    :to="{ name: 'courses-update', params: { slug: course.slug } }">
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Изменить
                  </router-link>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    @click="$refs.deleteModal.openModal(course.slug)"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Удалить
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <Pagination
        v-if="courses"
        :per_page="courses.per_page"
        :total="courses.total"
        :current-page="currentPage"
      />
      <AlertRemove ref="deleteModal" :onDelete="onDelete" />
      <!-- END: Pagination -->
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1')
    },
    courses() {
      return this.$store.state.courses.courses
    }
  },
  watch: {
    currentPage() {
      this.init(this.currentPage)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(pages) {
      this.$store.dispatch('courses/getCourses', pages)
    },
    onDelete(deletingItem) {
      this.$api.delete('/dashboard/course/' + deletingItem)
      this.init()
    }
  }
}
</script>
