<template>
  <div>
    <div
      class="intro-y flex flex-col sm:flex-row items-center mt-8 justify-between"
    >
      <h2 class="text-lg font-medium mr-auto">Просмотр теста</h2>
      <button @click="$router.go(-1)" class="btn btn-primary w-24 mr-1">
        Назад
      </button>
    </div>
    <LangContent>
      <div class="post intro-y grid grid-cols-12 gap-5">
        <!-- BEGIN: Post Content -->
        <div class="intro-y col-span-12">
          <input
            type="text"
            v-model="form.title[lang]"
            readonly
            required
            class="
                  intro-y
                  form-control
                  py-3
                  px-4
                  box
                  pr-10
                  placeholder-theme-13
                "
            placeholder="Название курса"
          />
          <div class="post intro-y overflow-hidden box mt-5">
            <div
              class="
                    post__tabs
                    nav nav-tabs
                    flex-col
                    sm:flex-row
                    bg-gray-300
                    dark:bg-dark-2
                    text-gray-600
                  "
              role="tablist"
            >
              <Tippy
                id="content-tab"
                tag="a"
                content="Fill in the article content"
                data-toggle="tab"
                data-target="#content"
                href="javascript:;"
                class="
                      w-full
                      sm:w-40
                      py-4
                      text-center
                      flex
                      justify-center
                      items-center
                      active
                    "
                role="tab"
                aria-controls="content"
                aria-selected="true"
              >
                <FileTextIcon class="w-4 h-4 mr-2" /> Контент
              </Tippy>
            </div>
            <div class="post__content tab-content">
              <div
                id="content"
                class="tab-pane p-5 active"
                role="tabpanel"
                aria-labelledby="content-tab"
              >
                <div
                  class="mt-5 ck ck-content"
                  v-html="form.description[lang]"
                ></div>
              </div>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5">
            <div class="mt-3">
              <label for="post-form-7" class="form-label"
                >Количество попыток</label
              >
              <input
                id="post-form-7"
                type="number"
                class="form-control"
                v-model="form.attempts"
                readonly
                placeholder="Введите кол/во попыток"
                maxlength="2"
              />
            </div>
          </div>
          <div class="mt-5 intro-y py-4 px-4 box">
            <h4 class="font-medium flex items-center">
              Ваши созданные тесты
            </h4>
            <div
              class="my-3 flex justify-between align-items-center"
              v-for="(variant, index) in form.variants"
              :key="index"
            >
              <div class="mt-3">
                {{ variant.question }}
              </div>
              <button type="button" @click="editVariant(index)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <!-- END: Post Content -->
      </div>
    </LangContent>
  </div>
</template>

<script>

export default {
  data() {
    return {
      form: {
        title: {},
        description: {},
        attempts: '',
        course_id: '',
        variants: []
      },
      lang: 'ru',
      quize: null,
      currentVariant: {},
      types: []
    }
  },
  computed: {
    course() {
      return this.$store.state.courses.course
    },
    quize_variants() {
      return this.$store.state.quizes.variants
    }
  },
  mounted() {
    this.init()
    this.getQuize()
    this.getQuizeTypes()
  },
  methods: {
    changeLang(lang) {
      this.lang = lang
    },
    init() {
      if (this.course.slug != this.$route.params.slug) {
        this.$store.dispatch('courses/getCourse', this.$route.params.slug)
      }
    },
    async getQuizeTypes() {
      this.$api
        .get(`dashboard/course/${this.$route.params.slug}/quizes/types`)
        .then(response => {
          this.types = response
        })
    },

    async getQuize() {
      this.$api
        .get(
          `dashboard/course/${this.$route.params.slug}/quizes/${this.$route.params.id}`,
          { headers: { lang: 'mix' } }
        )
        .then(response => {
          this.quize = response

          this.form = {
            title: response.title,
            description: response.description,
            attempts: response.attempts,
            variants: response.variants
          }
        })
    },

    editVariant(index) {
      this.currentVariant = this.quize.variants[index]

      this.$refs.modal.openModal()
    }
  }
}
</script>
