/* eslint-disable camelcase */

const state = () => {
  return {
    students: []
  }
}

const actions = {
  async getStudents({
    commit
  }, data) {
    try {
      const response = await this.$api.get(`dashboard/course/${data.course_slug}/students`,
        {
          params: {
            page: data.pages
          }
        })
      if (response) {
        commit('SET_STUDENTS', response)
      }
    } catch (error) {

    }
  }
}

const mutations = {
  SET_STUDENTS(state, students) {
    state.students = students
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
