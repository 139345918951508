<template>
  <div class="password">
    <div class="intro-x relative w-full js-parent">
      <div class="absolute inset-y-0 right-0 flex items-center px-2">
        <input
          class="hidden js-password-toggle"
          :id="label"
          type="checkbox"
          @change="passwordToggle"
        />
        <label
          class="absolute inset-y-0 right-0 flex items-center pr-3 top-7 js-password-label cursor-pointer"
          :for="label"
        >
          <span class="show block">
            <EyeIcon class="h-6 w-6 text-gray-600" />
          </span>
          <span class="hide hidden">
            <EyeOffIcon class="h-6 w-6 text-gray-600" />
          </span>
        </label>
      </div>
      <div class="mt-1">
            <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['label'],
  methods: {
    passwordToggle(event) {
      const dels = event.target.closest('.js-parent')
      const password = dels.querySelector('.js-password')
      const passwordLabelShow = dels.querySelector('.js-password-label .show')
      const passwordLabelHide = dels.querySelector('.js-password-label .hide')

      if (password.type === 'password') {
        password.type = 'text'
        passwordLabelShow.classList.add('hidden')
        passwordLabelHide.classList.remove('hidden')
      } else {
        password.type = 'password'
        passwordLabelShow.classList.remove('hidden')
        passwordLabelHide.classList.add('hidden')
      }
      password.focus()
    }
  }
}
</script>
