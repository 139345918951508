<template>
    <div>
        <div class="intro-y flex items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Изменить профиль</h2>
        </div>
        <div class="grid grid-cols-12 gap-6">
            <AsideProfile />
            <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
                <form @submit.prevent="save">
                    <!-- BEGIN: Display Information -->
                    <div class="intro-y box lg:mt-5">
                        <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                            <h2 class="font-medium text-base mr-auto">Персональная информация</h2>
                        </div>
                        <div class="p-5">
                            <div class="flex xl:flex-row flex-col">
                                <div class="flex-1 mt-6 xl:mt-0">
                                    <div class="grid grid-cols-12 gap-x-5">
                                        <div class="col-span-12 2xl:col-span-6">
                                            <div>
                                                <label for="update-profile-form-1" class="form-label">Ф.И.О</label>
                                                <input id="update-profile-form-1" type="text" class="form-control" maxlength="42"
                                                    placeholder="Введите Ф.И.О" v-model="form.fullname" />
                                            </div>
                                            <div class="mt-3">
                                                <label for="update-profile-form-nick_name" class="form-label">Ник</label>
                                                <input id="update-profile-form-nick_name" type="text" class="form-control" maxlength="18"
                                                    placeholder="Введите Ник" required v-model="form.nick_name" />
                                            </div>
                                            <div class="mt-3">
                                                <label for="update-profile-form-4" class="form-label">Возраст</label>
                                                <input id="update-profile-form-4" type="number" class="form-control" required maxlength="2" minlength="1"
                                                    placeholder="Введите возраст" v-model="form.age" />
                                            </div>
                                        </div>
                                        <div class="col-span-12 2xl:col-span-6">
                                            <div class="mt-3 2xl:mt-0">
                                                <label for="update-profile-form-4" class="form-label">Номер телефона</label>
                                                <input id="update-profile-form-4" type="tel" class="form-control"
                                                    placeholder="Введите номер телефона" maxlength="9" minlength="9" v-model="form.phone" required />
                                            </div>
                                            <div class="mt-3">
                                                <label for="update-profile-form-gender" class="form-label">Пол</label>
                                                <select id="update-profile-form-gender" v-model="form.gender" required
                                                    class="w-full form-control">
                                                    <option value="M">Мужской</option>
                                                    <option value="W">Женский</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-span-12">
                                            <div class="mt-3">
                                                <label for="update-profile-form-5" class="form-label">Адрес</label>
                                                <textarea id="update-profile-form-5" class="form-control" maxlength="64"
                                                    placeholder="Введите адрес" v-model="form.address"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" class="btn btn-primary w-24 mt-3">
                                        Сохранить
                                    </button>
                                </div>
                                <div class="w-52 mx-auto xl:mr-0 xl:ml-6">
                                    <div class="mt-5">
                                      <div class="border-2 border-dashed shadow-sm border-gray-200 dark:border-dark-5 rounded-md p-5">
                                          <div v-if="avatar" class="h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                                            <img class="rounded-md" alt="Загрузите аватарку"
                                                :src="avatar" />
                                            <Tippy tag="div" content="Удалить постер" @click="removeImage(1)"
                                                class="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 -mt-2">
                                                <xIcon class="w-4 h-4" />
                                            </Tippy>
                                          </div>
                                          <div class="mx-auto cursor-pointer relative mt-5">
                                              <span class="btn btn-primary w-full">Загрузить аватарку</span>
                                              <input type="file" @change="onFileChange($event, 1)"
                                                  class="w-full h-full top-0 left-0 absolute opacity-0" />
                                          </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import AsideProfile from '@/components/aside/Profile'
import _ from 'lodash'

export default {
  components: {
    AsideProfile
  },
  data() {
    return {
      avatar: '',
      form: {
        fullname: '',
        gender: 'M',
        nick_name: '',
        phone: '',
        age: '',
        address: ''
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const user = this.$store.state.auth.user
      this.avatar = user.thumbnail
      this.form = {
        fullname: user.fullname,
        gender: user.gender,
        nick_name: user.nick_name,
        phone: user.phone,
        age: user.age,
        address: user.address
      }
    },
    onFileChange(e) {
      this.form.avatar = this.$h.readFile(e)
      this.createImage(this.form.avatar)
    },
    createImage(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.avatar = e.target.result
      }
      reader.readAsDataURL(file)
    },
    removeImage() {
      this.avatar = ''
    },
    save() {
      const formData = new FormData()
      formData.append('avatar', this.form.avatar)
      _.each(this.form, (value, key) => {
        formData.append(key, value)
      })

      this.$api.put('auth/update', formData).then(response => {
        this.$router.push({ name: 'profile' })
        this.$store.dispatch('auth/me', response)
      })
    }
  }
}
</script>
