<template>
  <div id="option-module">
    <div class="preview">
      <!-- BEGIN: Modal Content -->
      <div
        id="create-options-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <form @submit.prevent="save" class="modal-content">
            <!-- BEGIN: Modal Header -->
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">Добавить опцию</h2>
              <button type="button" data-dismiss="modal">
                <XCircleIcon class="w-6 h-6" />
              </button>
            </div>
            <!-- END: Modal Header -->
            <!-- BEGIN: Modal Body -->
            <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
              <div class="col-span-12">
                <label for="module-title" class="form-label font-medium"
                  >Выберите тип </label
                >
                <TomSelect v-model="form.option_type" @change="changeType" :options="{ placeholder: 'Выберите опцию для модуля' }" class="w-full">
                  <option value>Выберите опцию для модуля</option>
                  <option value="page">Страницы</option>
                  <option value="quize">Тесты</option>
                  <option value="discussion">Обсуждение</option>
                </TomSelect>
              </div>
              <div class="col-span-12">
                <div v-if="form.option_type" class="preview mt-4 max-h-80 overflow-y-auto py-5 border rounded-md">
                  <div class="px-2">
                      <label class="font-medium">Выберите значение из списка</label>
                      <div class="form-check mt-2">
                        <input id="radio-switch-default" class="form-check-input" type="radio" v-model="option_id" :value="'default'">
                        <label class="form-check-label" for="radio-switch-default">Добавить</label>
                      </div>
                      <div class="form-check mt-2" v-for="(item, index) in options.data" :key="index">
                        <input :id="`radio-switch-${item.id}`" class="form-check-input" type="radio" v-model="option_id" :value="item.id">
                        <label class="form-check-label" :for="`radio-switch-${item.id}`">{{ item.title }}</label>
                      </div>
                  </div>
                </div>
                <div v-if="option_id == 'default'" class="preview mt-4">
                  <div class="col-span-12">
                    <label for="module-title" class="form-label font-medium"
                      >Название</label
                    >
                    <input
                      id="module-title"
                      type="text"
                      v-model="title"
                      class="form-control"
                      placeholder="Введите название"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- END: Modal Body -->
            <!-- BEGIN: Modal Footer -->
            <div class="modal-footer text-right">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-auto mr-1 px-2.5"
              >
                Отменить
              </button>
              <button type="submit" class="btn btn-primary w-auto px-2.5">
                Добавить
              </button>
            </div>
            <!-- END: Modal Footer -->
          </form>
        </div>
      </div>
      <!-- END: Modal Content -->
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      options: [],
      title: '',
      option_id: '',
      form: {
        option_type: ''
      }
    }
  },
  computed: {
    module_slug() {
      return this.$store.state.modules.slug
    }
  },
  methods: {
    save() {
      this.option_id == 'default' ? this.form.title = this.title : this.form.option_id = this.option_id
      console.log(this.form.option_type)
      this.$api.post(`dashboard/course/${this.$route.params.slug}/modules/${this.module_slug}/bind`, this.form).then((result) => {
        cash('#create-options-modal').modal('hide')
        this.$store.dispatch('modules/getModules', this.$route.params.slug)
        this.title = ''
        this.option_id = ''
        this.options = []
        this.form.option_type = ''
      })
    },
    changeType() {
      this.$api.get(`dashboard/course/${this.$route.params.slug}/${this.form.option_type}s`).then((result) => {
        this.options = result
      })
    }
  }
}
</script>
