/* eslint-disable camelcase */

const state = () => {
  return {
    quizes: [],
    variants: [],
    variant: []
  }
}

const actions = {
  async getQuizes({
    commit
  }, data) {
    try {
      const response = await this.$api.get(`dashboard/course/${data.course_slug}/quizes`, {
        params: {
          page: data.pages
        }
      })
      if (response) {
        commit('SET_QUIZES', response)
      }
    } catch (error) {

    }
  },
  addVariants({
    commit
  }, variants) {
    commit('SET_VARIANTS', variants)
  },
  clearVariants({
    commit
  }) {
    commit('CLEAR_VARIANTS')
  },
  setVariant({ commit }, variant) {
    commit('SET_VARIANT', variant)
  }
}

const mutations = {
  SET_QUIZES(state, quizes) {
    state.quizes = quizes
  },
  SET_VARIANTS(state, variants) {
    state.variants.push(variants)
  },
  SET_VARIANT(state, variant) {
    state.variant = variant
  },
  CLEAR_VARIANTS(state) {
    state.variants = []
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
