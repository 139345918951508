import * as types from './mutation-types'

const state = () => {
  return {
    darkMode: false
  }
}

// getters
const getters = {
  darkMode: state => state.darkMode
}

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    const mode = localStorage.setItem('darkMode', darkMode)
    commit(types.SET_DARK_MODE, mode)
  }
}

// mutations
const mutations = {
  [types.SET_DARK_MODE](state) {
    const darkmode = localStorage.getItem('darkMode')
    if (darkmode) {
      try {
        state.darkMode = JSON.parse(darkmode)
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
