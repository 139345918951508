<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Главная</h2>
            <button
              @click="getStatistic"
              class="ml-auto flex items-center text-theme-1 dark:text-theme-10"
            >
              <RefreshCcwIcon class="w-4 h-4 mr-3" /> Обновить статистику
            </button>
          </div>
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <UsersIcon class="report-box__icon text-theme-10" />
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">{{ statistic.opportunities }}</div>
                  <div class="text-base text-gray-600 mt-1">Возможностей</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <UserIcon class="report-box__icon text-theme-11" />
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">{{ statistic.students }}</div>
                  <div class="text-base text-gray-600 mt-1">Студентов</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <BookIcon class="report-box__icon text-theme-12" />
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">{{ statistic.courses }}</div>
                  <div class="text-base text-gray-600 mt-1">Курсов</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <BriefcaseIcon class="report-box__icon text-theme-9" />
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">{{ statistic.teachers }}</div>
                  <div class="text-base text-gray-600 mt-1">Учителей</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 lg:col-span-6 mt-8">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Отчеты о продаже</h2>
            <div
              class="sm:ml-auto mt-3 sm:mt-0 relative text-gray-700 dark:text-gray-300"
            >
              <CalendarIcon
                class="w-4 h-4 z-10 absolute my-auto inset-y-0 ml-3 left-0"
              />
              <Litepicker
                v-model="salesReportFilter"
                :options="{
                  autoApply: false,
                  singleMode: false,
                  numberOfColumns: 2,
                  numberOfMonths: 2,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  }
                }"
                class="form-control sm:w-56 box pl-10"
              />
            </div>
          </div>
          <div class="intro-y box p-5 mt-12 sm:mt-5">
            <div class="flex flex-col xl:flex-row xl:items-center">
              <div class="flex">
                <div>
                  <div
                    class="text-theme-19 dark:text-gray-300 text-lg xl:text-xl font-medium"
                  >
                    $00,000
                  </div>
                  <div class="mt-0.5 text-gray-600 dark:text-gray-600">
                    Этот месяц
                  </div>
                </div>
                <div
                  class="w-px h-12 border border-r border-dashed border-gray-300 dark:border-dark-5 mx-4 xl:mx-5"
                ></div>
                <div>
                  <div
                    class="text-gray-600 dark:text-gray-600 text-lg xl:text-xl font-medium"
                  >
                    $00,000
                  </div>
                  <div class="mt-0.5 text-gray-600 dark:text-gray-600">
                    Пред. месяц
                  </div>
                </div>
              </div>
              <div class="dropdown xl:ml-auto mt-5 xl:mt-0">
                <button
                  class="dropdown-toggle btn btn-outline-secondary font-normal"
                  aria-expanded="false"
                >
                  Фильтр по категриям
                  <ChevronDownIcon class="w-4 h-4 ml-2" />
                </button>
                <div class="dropdown-menu w-40">
                  <div
                    class="dropdown-menu__content box dark:bg-dark-1 p-2 overflow-y-auto h-32"
                  >
                    <a
                      href=""
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      >Фильтр не работает</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="report-chart">
              <ReportLineChart :height="169" class="mt-6" />
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
        <!-- BEGIN: Weekly Top Seller -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">По возрасту</h2>
          </div>
          <div class="intro-y box p-5 mt-5">
            <ReportPieChart v-if="statistic.age" :height="300" class="mt-3" :ages="statistic.age" />
            <div class="mt-8 flex flex-col flex-col-reverse">
              <div v-for="(value, propertyName, index) in statistic.age" :key="index" class="flex items-center mt-4">
                <div class="w-2 h-2 rounded-full mr-3" :class="`bg-stats-${index+1}`"></div>
                <span class="truncate">{{ propertyName }} лет</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ countPercentage(value, statistic.age) }}%</span>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Weekly Top Seller -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">По полу</h2>
          </div>
          <div class="intro-y box p-5 mt-5">
            <ReportDonutChart v-if="statistic.gender" :height="300" class="mt-3" :genders="statistic.gender"/>
            <div class="mt-8">
              <div v-for="(value, propertyName, index) in statistic.gender" :key="index" class="flex items-center mt-4">
                <div :class="`w-2 h-2 rounded-full mr-3 bg-stats-${index+1}`"></div>
                <span class="truncate">{{ propertyName == 'm' ? 'Мужчины' : 'Женщины' }}</span>
                <div
                  class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                ></div>
                <span class="font-medium xl:ml-auto">{{ countPercentage(value, statistic.gender) }}%</span>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
        <!-- BEGIN: Weekly Top Products -->
        <div class="col-span-12 mt-6">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              Популярные курсы за неделю
            </h2>
          </div>
          <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
            <table class="table table-report sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">Название</th>
                  <th class="text-center whitespace-nowrap">Рейтинг</th>
                  <th class="text-center whitespace-nowrap">Статус</th>
                  <th class="text-center whitespace-nowrap">Действие</th>
                </tr>
              </thead>
               <tbody v-if="popularCourses">
                <tr
                  v-for="(course, index) in popularCourses.data"
                  :key="index"
                  class="intro-x"
                >
                  <td>
                    <router-link
                      :to="{ name: 'courses-show', params: { slug: course.slug } }"
                      class="font-medium whitespace-nowrap"
                      >{{ course.title }}</router-link
                    >
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ course.article }}
                    </div>
                  </td>
                  <td class="text-center">{{ course.rating }}</td>
                  <td class="w-40">
                    <div
                      class="flex items-center justify-center"
                      :class="{
                        'text-theme-7': course.status?.name == 'draft',
                        'text-theme-9': course.status?.name == 'published'
                      }"
                    >
                      <EyeOffIcon class="w-4 h-4 mr-2" v-if="course.status?.name == 'draft'"  />
                      <CheckCircleIcon class="w-4 h-4 mr-2" v-if="course.status?.name == 'published'" />
                      {{ course.status.translate }}
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex justify-center items-center">
                      <router-link
                        class="flex items-center mr-3"
                        :to="{ name: 'courses-update', params: { slug: course.slug } }">
                        <CheckSquareIcon class="w-4 h-4 mr-1" />
                        Изменить
                      </router-link>
                      <a
                        class="flex items-center text-theme-6"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#delete-confirmation-modal"
                      >
                        <Trash2Icon class="w-4 h-4 mr-1" /> Удалить
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
            <!-- BEGIN: Pagination -->
            <Pagination
              v-if="popularCourses"
              :last-page="popularCourses.last_page || 1"
              :current-page="currentPage"
              :url="baseUrl"
            />
            <!-- END: Pagination -->
        </div>
        <!-- END: Weekly Top Products -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, provide, computed } from 'vue'
import { useStore } from 'vuex'

import ReportLineChart from '@/components/report-line-chart/Main.vue'
import ReportDonutChart from '@/components/report-donut-chart/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'

export default defineComponent({
  components: {
    ReportLineChart,
    ReportDonutChart,
    ReportPieChart
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1')
    },
    baseUrl() {
      return this.$route.path
    }
  },
  setup() {
    const store = useStore()
    const salesReportFilter = ref()
    const importantNotesRef = ref()

    provide('bind[importantNotesRef]', el => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }
    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }

    return {
      popularCourses: computed(() => store.state.courses.popularCourses),
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes
    }
  },
  data() {
    return {
      statistic: {}
    }
  },
  mounted() {
    this.getStatistic()
    this.getPopularCourses()
  },
  methods: {
    async getStatistic() {
      await this.$api.get('/dashboard/statistic').then(response => {
        this.statistic = response
      })
    },

    async getPopularCourses() {
      this.$store.dispatch('courses/getPopularCourses')
    },
    countPercentage(current, property) {
      return (current * 100 / (this.$_.sum(this.$_.values(property)))).toFixed(2)
    }
  }
})
</script>
