import { createStore } from 'vuex'

// Dashboard
import main from './modules/dashboard/main'
import sideMenu from './modules/dashboard/side-menu'

// Other
import { auth } from './modules/auth'
import loader from './modules/loader'

// Courses
import courses from './modules/courses/courses'
import quizes from './modules/courses/quizes'
import pages from './modules/courses/pages'
import discussions from './modules/courses/discussions'
import modules from './modules/courses/modules'
import students from './modules/courses/students'

// Roles
import roles from './modules/roles'

const store = createStore({
  modules: {
    auth,
    main,
    sideMenu,
    loader,
    courses,
    quizes,
    pages,
    discussions,
    modules,
    students,
    roles
  }
})

export function useStore() {
  return store
}

export default store
