<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Настройки изменить</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div class="input-form mt-2">
            <label for="crud-form-1" class="form-label">Значение</label>
            <input
              id="crud-form-1"
              type="text"
              :value="setting.description"
              readonly
              class="form-control"
              placeholder="Введите значение настройки"
            />
          </div>
          <div class="input-form mt-3">
            <label class="form-label">Описание настройки</label>
            <textarea
              class="form-control"
              v-html="setting.value"
              readonly
            ></textarea>
          </div>
          <div class="flex justify-between mt-5">
            <router-link
              to="/settings"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              Назад
            </router-link>
            <router-link
              :to="{
                name: 'settings-update',
                params: { slug: this.$route.params.id }
              }"
              class="btn btn-warning w-24"
            >
              Изменить
            </router-link>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      setting: []
    }
  },
  mounted() {
    this.$api
      .get(`/dashboard/settings/${this.$route.params.id}`)
      .then(response => {
        this.setting = response
      })
  }
}
</script>
