<template>
    <div>
        <div class="intro-y flex items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Профиль пользователя</h2>
        </div>
        <!-- BEGIN: Profile Info -->
        <div class="intro-y box px-5 pt-5 mt-5">
            <div class="
            flex flex-col
            lg:flex-row
            border-b border-gray-200
            dark:border-dark-5
            pb-5
            -mx-5
          ">
                <div class="flex flex-1 px-5 items-center justify-center lg:justify-start">
                    <div class="
                w-20
                h-20
                sm:w-24 sm:h-24
                flex-none
                lg:w-32 lg:h-32
                image-fit
                relative
              ">
                        <img :alt="user.fullname" class="rounded-full" :src="
                  require(`@/assets/images/${
                    user.image ? user.image : 'profile-1.jpg'
                  }`)
                " />
                    </div>
                    <div class="ml-5">
                        <div class="
                  w-24
                  sm:w-40
                  truncate
                  sm:whitespace-normal
                  font-medium
                  text-lg
                ">
                            {{ user.fullname }}
                        </div>
                        <div class="text-gray-600">{{ user.nickname }}</div>
                    </div>
                </div>
                <div class="
              mt-6
              lg:mt-0
              flex-1
              dark:text-gray-300
              px-5
              border-l border-r border-gray-200
              dark:border-dark-5
              border-t
              lg:border-t-0
              pt-5
              lg:pt-0
            ">
                    <div class="font-medium text-center lg:text-left lg:mt-3">
                        Контактная информация
                    </div>
                    <div class="
                flex flex-col
                justify-center
                items-center
                lg:items-start
                mt-4
              ">
                        <div class="truncate sm:whitespace-normal flex items-center">
                            <MailIcon class="w-4 h-4 mr-2" />
                            {{ user.email }}
                        </div>
                        <div v-if="social_links" class="flex flex-col justify-center items-center lg:items-start">
                            <div class="truncate sm:whitespace-normal flex items-center mt-3"
                                v-for="(item, index) in social_links" :key="index">
                                <InstagramIcon class="w-4 h-4 mr-2" /> Instagram
                                {{ $f()[0].users[0].name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="
              mt-6
              lg:mt-0
              flex-1 flex
              items-center
              justify-center
              px-5
              border-t
              lg:border-0
              border-gray-200
              dark:border-dark-5
              pt-5
              lg:pt-0
            ">
                    <div class="text-center rounded-md w-20 py-3">
                        <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">
                            201
                        </div>
                        <div class="text-gray-600">Файлов</div>
                    </div>
                    <div class="text-center rounded-md w-20 py-3">
                        <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">
                            1k
                        </div>
                        <div class="text-gray-600">Курсов</div>
                    </div>
                    <div class="text-center rounded-md w-20 py-3">
                        <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">
                            492
                        </div>
                        <div class="text-gray-600">Отзывов</div>
                    </div>
                </div>
            </div>
            <div class="
            nav nav-tabs
            flex-col
            sm:flex-row
            justify-center
            lg:justify-start
          " role="tablist">
                <a id="profile-tab" data-toggle="tab" data-target="#profile" href="javascript:;"
                    class="py-4 sm:mr-8 flex items-center active" role="tab" aria-controls="profile"
                    aria-selected="true">
                    <UserIcon class="w-4 h-4 mr-2" /> Профиль
                </a>
                <a id="account-tab" data-toggle="tab" data-target="#account" href="javascript:;"
                    class="py-4 sm:mr-8 flex items-center" role="tab" aria-selected="false">
                    <ShieldIcon class="w-4 h-4 mr-2" /> Личные данные
                </a>
                <a id="change-password-tab" data-toggle="tab" data-target="#change-password" href="javascript:;"
                    class="py-4 sm:mr-8 flex items-center" role="tab" aria-selected="false">
                    <LockIcon class="w-4 h-4 mr-2" /> Изменить пароль
                </a>
                <a id="settings-tab" data-toggle="tab" data-target="#settings" href="javascript:;"
                    class="py-4 sm:mr-8 flex items-center" role="tab" aria-selected="false">
                    <SettingsIcon class="w-4 h-4 mr-2" /> Настройки
                </a>
            </div>
        </div>
        <!-- END: Profile Info -->
        <div class="tab-content mt-5">
            <div id="profile" class="tab-pane active" role="tabpanel" aria-labelledby="profile-tab">
                <div class="grid grid-cols-12 gap-6">
                    <!-- BEGIN: Latest Uploads -->
                    <div class="intro-y box col-span-12 lg:col-span-6">
                        <div class="
                  flex
                  items-center
                  px-5
                  py-5
                  sm:py-3
                  border-b border-gray-200
                  dark:border-dark-5
                ">
                            <h2 class="font-medium text-base mr-auto">Последние файлы</h2>
                            <div class="dropdown ml-auto sm:hidden">
                                <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false">
                                    <MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300" />
                                </a>
                                <div class="dropdown-menu w-40">
                                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                        <router-link :to="{ name: 'file-manager' }" class="
                          block
                          p-2
                          transition
                          duration-300
                          ease-in-out
                          bg-white
                          dark:bg-dark-1
                          hover:bg-gray-200
                          dark:hover:bg-dark-2
                          rounded-md
                        ">Все файлы</router-link>
                                    </div>
                                </div>
                            </div>
                            <router-link :to="{ name: 'file-manager' }"
                                class="btn btn-outline-secondary hidden sm:flex">
                                Все файлы
                            </router-link>
                        </div>
                        <div class="p-5">
                            <div class="flex items-center">
                                <div class="file">
                                    <a href="" class="w-12 file__icon file__icon--directory"></a>
                                </div>
                                <div class="ml-4">
                                    <a class="font-medium" href="">Documentation</a>
                                    <div class="text-gray-600 text-xs mt-0.5">40 KB</div>
                                </div>
                                <div class="dropdown ml-auto">
                                    <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false">
                                        <MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300" />
                                    </a>
                                    <div class="dropdown-menu w-40">
                                        <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                            <a href="" class="
                            flex
                            items-center
                            block
                            p-2
                            transition
                            duration-300
                            ease-in-out
                            bg-white
                            dark:bg-dark-1
                            hover:bg-gray-200
                            dark:hover:bg-dark-2
                            rounded-md
                          ">
                                                <UsersIcon class="w-4 h-4 mr-2" /> Share File
                                            </a>
                                            <a href="" class="
                            flex
                            items-center
                            block
                            p-2
                            transition
                            duration-300
                            ease-in-out
                            bg-white
                            dark:bg-dark-1
                            hover:bg-gray-200
                            dark:hover:bg-dark-2
                            rounded-md
                          ">
                                                <TrashIcon class="w-4 h-4 mr-2" /> Delete
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center mt-5">
                                <div class="file">
                                    <a href="" class="w-12 file__icon file__icon--file">
                                        <div class="file__icon__file-name text-xs">MP3</div>
                                    </a>
                                </div>
                                <div class="ml-4">
                                    <a class="font-medium" href="">Celine Dion - Ashes</a>
                                    <div class="text-gray-600 text-xs mt-0.5">40 KB</div>
                                </div>
                                <div class="dropdown ml-auto">
                                    <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false">
                                        <MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300" />
                                    </a>
                                    <div class="dropdown-menu w-40">
                                        <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                            <a href="" class="
                            flex
                            items-center
                            block
                            p-2
                            transition
                            duration-300
                            ease-in-out
                            bg-white
                            dark:bg-dark-1
                            hover:bg-gray-200
                            dark:hover:bg-dark-2
                            rounded-md
                          ">
                                                <UsersIcon class="w-4 h-4 mr-2" /> Share File
                                            </a>
                                            <a href="" class="
                            flex
                            items-center
                            block
                            p-2
                            transition
                            duration-300
                            ease-in-out
                            bg-white
                            dark:bg-dark-1
                            hover:bg-gray-200
                            dark:hover:bg-dark-2
                            rounded-md
                          ">
                                                <TrashIcon class="w-4 h-4 mr-2" /> Delete
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center mt-5">
                                <div class="file">
                                    <a href="" class="w-12 file__icon file__icon--empty-directory"></a>
                                </div>
                                <div class="ml-4">
                                    <a class="font-medium" href="">Resources</a>
                                    <div class="text-gray-600 text-xs mt-0.5">0 KB</div>
                                </div>
                                <div class="dropdown ml-auto">
                                    <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false">
                                        <MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300" />
                                    </a>
                                    <div class="dropdown-menu w-40">
                                        <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                            <a href="" class="
                            flex
                            items-center
                            block
                            p-2
                            transition
                            duration-300
                            ease-in-out
                            bg-white
                            dark:bg-dark-1
                            hover:bg-gray-200
                            dark:hover:bg-dark-2
                            rounded-md
                          ">
                                                <UsersIcon class="w-4 h-4 mr-2" /> Share File
                                            </a>
                                            <a href="" class="
                            flex
                            items-center
                            block
                            p-2
                            transition
                            duration-300
                            ease-in-out
                            bg-white
                            dark:bg-dark-1
                            hover:bg-gray-200
                            dark:hover:bg-dark-2
                            rounded-md
                          ">
                                                <TrashIcon class="w-4 h-4 mr-2" /> Delete
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END: Latest Uploads -->
                    <!-- BEGIN: Work In Progress -->
                    <div class="intro-y box col-span-12 lg:col-span-6">
                        <div class="
                  flex
                  items-center
                  px-5
                  py-5
                  sm:py-3
                  border-b border-gray-200
                  dark:border-dark-5
                ">
                            <h2 class="font-medium text-base mr-auto">Статистика</h2>
                            <div class="dropdown ml-auto sm:hidden">
                                <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false">
                                    <MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300" />
                                </a>
                            </div>
                        </div>
                        <div class="p-5">
                            <div class="tab-content">
                                <div id="work-in-progress-new" class="tab-pane active" role="tabpanel"
                                    aria-labelledby="work-in-progress-new-tab">
                                    <div>
                                        <div class="flex">
                                            <div class="mr-auto">Pending Tasks</div>
                                            <div>20%</div>
                                        </div>
                                        <div class="progress h-1 mt-2">
                                            <div class="progress-bar w-1/2 bg-theme-1" role="progressbar"
                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div class="mt-5">
                                        <div class="flex">
                                            <div class="mr-auto">Completed Tasks</div>
                                            <div>2 / 20</div>
                                        </div>
                                        <div class="progress h-1 mt-2">
                                            <div class="progress-bar w-1/4 bg-theme-1" role="progressbar"
                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div class="mt-5">
                                        <div class="flex">
                                            <div class="mr-auto">Tasks In Progress</div>
                                            <div>42</div>
                                        </div>
                                        <div class="progress h-1 mt-2">
                                            <div class="progress-bar w-3/4 bg-theme-1" role="progressbar"
                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END: Work In Progress -->
                    <!-- BEGIN: Daily Sales -->
                    <div class="intro-y box col-span-12 lg:col-span-6">
                        <div class="
                  flex
                  items-center
                  px-5
                  py-5
                  sm:py-3
                  border-b border-gray-200
                  dark:border-dark-5
                ">
                            <h2 class="font-medium text-base mr-auto">Курсы</h2>
                            <div class="dropdown ml-auto sm:hidden">
                                <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false">
                                    <MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300" />
                                </a>
                            </div>
                        </div>
                        <div class="p-5">
                            <div class="relative flex items-center">
                                <div class="w-12 h-12 flex-none image-fit">
                                    <img alt="Smarthub" class="rounded-full"
                                        :src="require(`@/assets/images/${$f()[0].photos[0]}`)" />
                                </div>
                                <div class="ml-4 mr-auto">
                                    <a href="" class="font-medium">{{ $f()[0].users[0].name }}</a>
                                    <div class="text-gray-600 mr-5 sm:mr-5">
                                        Bootstrap 4 HTML Admin Template
                                    </div>
                                </div>
                                <div class="font-medium text-gray-700 dark:text-gray-600">
                                    +$19
                                </div>
                            </div>
                            <div class="relative flex items-center mt-5">
                                <div class="w-12 h-12 flex-none image-fit">
                                    <img alt="Smarthub" class="rounded-full"
                                        :src="require(`@/assets/images/${$f()[1].photos[0]}`)" />
                                </div>
                                <div class="ml-4 mr-auto">
                                    <a href="" class="font-medium">{{ $f()[1].users[0].name }}</a>
                                    <div class="text-gray-600 mr-5 sm:mr-5">
                                        Tailwind HTML Admin Template
                                    </div>
                                </div>
                                <div class="font-medium text-gray-700 dark:text-gray-600">
                                    +$25
                                </div>
                            </div>
                            <div class="relative flex items-center mt-5">
                                <div class="w-12 h-12 flex-none image-fit">
                                    <img alt="Smarthub" class="rounded-full"
                                        :src="require(`@/assets/images/${$f()[2].photos[0]}`)" />
                                </div>
                                <div class="ml-4 mr-auto">
                                    <a href="" class="font-medium">{{ $f()[2].users[0].name }}</a>
                                    <div class="text-gray-600 mr-5 sm:mr-5">
                                        Vuejs HTML Admin Template
                                    </div>
                                </div>
                                <div class="font-medium text-gray-700 dark:text-gray-600">
                                    +$21
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      user: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$api
        .get(`/dashboard/user/${this.$route.params.id}`)
        .then((response) => {
          this.user = response
        })
    }
  }
}
</script>
