<template>
    <div>
        <form @submit.prevent="save">
            <div class="intro-y flex flex-col sm:flex-row items-center mt-8 custom-index justify-between">
                <h2 class="text-lg font-medium mr-auto">Добавить новый тест</h2>
                <div class="flex items-center space-x-5">
                  <LangSwitcher @changeLang="changeLang" />
                  <button class="btn btn-primary shadow-md flex items-center" type="submit">
                      Сохранить
                  </button>
                </div>
            </div>
            <LangContent>
              <div class="post intro-y grid grid-cols-12 gap-5">
                  <!-- BEGIN: Post Content -->
                  <div class="intro-y col-span-12 lg:col-span-8">
                      <input type="text" v-model="form.title[lang]" required class="
                  intro-y
                  form-control
                  py-3
                  px-4
                  box
                  pr-10
                  placeholder-theme-13
                " placeholder="Название теста" />
                      <div class="post intro-y overflow-hidden box mt-5">
                          <div class="
                    post__tabs
                    nav nav-tabs
                    flex-col
                    sm:flex-row
                    bg-gray-300
                    dark:bg-dark-2
                    text-gray-600
                  " role="tablist">
                              <Tippy id="content-tab" tag="a" content="Fill in the article content" data-toggle="tab"
                                  data-target="#content" href="javascript:;" class="
                      w-full
                      sm:w-40
                      py-4
                      text-center
                      flex
                      justify-center
                      items-center
                      active
                    " role="tab" aria-controls="content" aria-selected="true">
                                  <FileTextIcon class="w-4 h-4 mr-2" /> Контент
                              </Tippy>
                          </div>
                          <div class="post__content tab-content">
                              <div id="content" class="tab-pane p-5 active" role="tabpanel" aria-labelledby="content-tab">
                                  <div>
                                      <label class="font-medium flex items-center">Описание </label>
                                      <div class="mt-5">
                                          <ClassicEditor v-model="form.description[lang]" />
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="mt-5 intro-y py-4 px-4 box">
                        <h4 class="font-medium flex items-center">Ваши созданные тесты</h4>
                        <div class="flex flex-col">
                          <div class="my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                              <div class="align-middle inline-block min-w-full overflow-hidden border-b border-gray-200">
                                  <table class="min-w-full">
                                      <thead>
                                          <tr>
                                              <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                                                  Вопрос
                                              </th>
                                              <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                                                  Тип
                                              </th>
                                              <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                                                  Оценка
                                              </th>
                                              <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                                          </tr>
                                      </thead>
                                      <tbody class="bg-white">
                                          <tr v-for="(variant, index) in quize_variants" :key="index">
                                              <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                                  {{ variant.question }}
                                              </td>
                                              <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-700">
                                                  {{ types.find(el => el.id == variant.quize_type_id).name }}
                                              </td>
                                              <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-700">
                                                  {{ variant.points }}
                                              </td>
                                              <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                                                  <a href="javascript:;" class="text-theme-10 hover:text-theme-1 focus:outline-none focus:underline" @click="showVariants(variant, index)">Изменить</a>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <!-- END: Post Content -->
                  <!-- BEGIN: Post Info -->
                  <div class="col-span-12 lg:col-span-4">
                      <div class="intro-y box p-5">
                          <div class="mt-3">
                              <label for="post-form-7" class="form-label">Количество попыток</label>
                              <input id="post-form-7" type="number" class="form-control" v-model="form.attempts"
                                  placeholder="Введите кол/во попыток" maxlength="2" />
                          </div>
                      </div>
                      <CreateVariants :types="types"/>
                  </div>
                  <!-- END: Post Info -->
              </div>
            </LangContent>
            <ShowVariants :types="types" :defaultVariant="open_variant" :count="open_variant_index"  />
        </form>
    </div>
</template>

<script>
import CreateVariants from '@/components/modals/Quizes/Main'
import ShowVariants from '@/components/modals/Quizes/Show'

export default {
  components: {
    CreateVariants,
    ShowVariants
  },
  data() {
    return {
      isOpen: false,
      form: {
        title: {},
        description: {},
        attempts: '',
        course_id: '',
        variants: []
      },
      open_variant: [],
      open_variant_index: null,
      lang: 'ru',
      types: []
    }
  },
  computed: {
    course() {
      return this.$store.state.courses.course
    },
    quize_variants() {
      return this.$store.state.quizes.variants
    }
  },
  mounted() {
    this.init()
    this.getQuizeTypes()
  },
  methods: {
    changeLang(lang) {
      this.lang = lang
    },
    showVariants(variant, index) {
      this.isOpen = !this.isOpen
      this.open_variant = variant
      this.open_variant_index = ++index
      console.log('Create-variants')
    },
    init() {
      if (this.course.slug != this.$route.params.slug) {
        this.$store.dispatch('courses/getCourse', this.$route.params.slug)
      }
    },
    save() {
      this.form.course_id = this.course.id
      this.form.variants = this.quize_variants
      this.$api
        .post(`dashboard/course/${this.$route.params.slug}/quizes`, this.form)
        .then(response => {
          this.$store.dispatch('quizes/getQuizes', { course_slug: this.course.slug })
          this.$store.dispatch('quizes/clearVariants')
          this.$router.push({ name: 'course-quizes', slug: this.course.slug })
        })
    },
    async getQuizeTypes() {
      this.$api.get(`dashboard/course/${this.$route.params.slug}/quizes/types`).then(response => {
        this.types = response
      })
    }
  }
}
</script>
