import axios from 'axios'
import token from '@/services/auth/header'
import store from '../store'
import { responser } from '../services/axios/responseHelper'

const baseURL = process.env.VUE_APP_BASE_URL_API
const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: token
  }
})
axiosInstance.defaults.showLoader = true

const post = axiosInstance.post

axiosInstance.put = (url, data, config) => {
  data instanceof FormData
    ? data.append('_method', 'PUT')
    : data = { ...data, _method: 'PUT' }

  return post(url, data, config)
}

axiosInstance.interceptors.request.use((config) => {
  if (config.showLoader) {
    store.dispatch('loader/pending')
  }
  return config
}, (error) => {
  if (error.config.showLoader) {
    store.dispatch('loader/done')
  }
  return Promise.reject(error)
})

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.config.showLoader) {
      store.dispatch('loader/done')
    }
    responser(response)
    return response.data
  },
  (error) => {
    const response = error.response

    if (response.config.showLoader) {
      store.dispatch('loader/done')
    }
    responser(error.response)
    return Promise.reject(error)
  }
)

export default axiosInstance
