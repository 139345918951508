const state = () => ({
  roles: [],
  permissions: []
})

const actions = {
  async getRoles({ commit }) {
    await this.$api.get('dashboard/roles').then((result) => {
      commit('SET_ROLES', result.data)
    })
  },
  async getPermissions({ commit }, page) {
    try {
      await this.$api.get('dashboard/permissions', {
        params: {
          page: page
        }
      }).then((result) => {
        commit('SET_PERMISSIONS', result)
      })
    } catch (error) {

    }
  }
}
const mutations = {
  SET_ROLES(state, roles) {
    state.roles = roles
  },
  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
