/* eslint-disable camelcase */

const state = () => {
  return {
    discussions: []
  }
}

const actions = {
  async getDiscussions({
    commit
  }, data) {
    try {
      const response = await this.$api.get(`/dashboard/course/${data.course_slug}/discussions`,
        {
          params: {
            page: data.pages
          }
        })
      if (response) {
        commit('SET_DISCUSSIONS', response)
      }
    } catch (error) {

    }
  }
}

const mutations = {
  SET_DISCUSSIONS(state, discussions) {
    state.discussions = discussions
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
