const authHeader = () => {
  const token = JSON.parse(localStorage.getItem('token'))
  if (token) {
    return 'Bearer' + token
  } else {
    return null
  }
}

export default authHeader()
