<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-xl font-medium mr-auto">Просмотр страницы</h2>
      <button @click="$router.go(-1)" class="btn btn-primary w-24 mr-1">
        Назад
      </button>
    </div>
    <form @submit.prevent="save">
      <div class="post intro-y grid grid-cols-12 gap-5 mt-5">
        <!-- BEGIN: Post Content -->
        <div class="intro-y col-span-12">
          <input
            type="text"
            v-model="form.title"
            class="
              intro-y
              form-control
              py-3
              px-4
              box
              pr-10
              placeholder-theme-13
            "
            placeholder="Название курса"
          />
          <div class="post intro-y overflow-hidden box mt-5">
            <div
              class="
                post__tabs
                nav nav-tabs
                flex-col
                sm:flex-row
                bg-gray-300
                dark:bg-dark-2
                text-gray-600
              "
              role="tablist"
            >
              <Tippy
                id="content-tab"
                tag="a"
                content="Fill in the article content"
                data-toggle="tab"
                data-target="#content"
                href="javascript:;"
                class="
                  w-full
                  sm:w-40
                  py-4
                  text-center
                  flex
                  justify-center
                  items-center
                  active
                "
                role="tab"
                aria-controls="content"
                aria-selected="true"
              >
                <FileTextIcon class="w-4 h-4 mr-2" /> Контент
              </Tippy>
            </div>
            <div class="post__content tab-content">
              <div
                id="content"
                class="tab-pane p-5 active"
                role="tabpanel"
                aria-labelledby="content-tab"
              >
                <div class="ck ck-content" v-html="form.description"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Post Content -->
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        title: '',
        description: ''
      }
    }
  },
  computed: {
    slug() {
      return this.$route.params.slug
    }
  },
  mounted() {
    this.getCoursePage()
  },
  methods: {
    save() {
      this.$api
        .put(
          `dashboard/course/${this.slug}/pages/${this.$route.params.id}`,
          this.form
        )
        .then(response => {
          this.$router.push(`/courses/${this.slug}/pages`)
        })
    },
    async getCoursePage() {
      this.$api
        .get(`dashboard/course/${this.slug}/pages/${this.$route.params.id}`)
        .then(response => {
          this.form = response
        })
    }
  }
}
</script>
