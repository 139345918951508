/* eslint-disable camelcase */

export const state = () => {
  return {
    course: [],
    courses: [],
    popularCourses: [],
    privateCourseApplications: []
  }
}

const actions = {
  async getCourses({
    commit
  }, page) {
    try {
      const response = await this.$api.get('dashboard/course', {
        params: {
          page: page
        }
      })
      if (response) {
        commit('SET_COURSES', response)
      }
    } catch (error) {
      // console.log(`[requestClient] Запрос не удался по причине -  ${error}`)
    }
  },
  async getCourse({
    commit
  }, course_slug) {
    try {
      const response = await this.$api.get(`/dashboard/course/${course_slug}`)
      if (response) {
        commit('SET_COURSE', response)
      }
    } catch (error) {
      // console.log(`[requestClient] Запрос не удался по причине -  ${error}`)
    }
  },
  async getPopularCourses({ commit }) {
    try {
      const response = await this.$api.get('/dashboard/course', {
        params: {
          type: 'popular'
        }
      })
      if (response) {
        commit('SET_POPULAR_COURSES', response)
      }
    } catch (error) {
      // console.log(`[requestClient] Запрос не удался по причине -  ${error}`)
    }
  },
  async getPrivateCourseApplications({ commit }, page) {
    try {
      const response = await this.$api.get('/dashboard/course/private-applications', {
        params: {
          page: page
        }
      })
      if (response) {
        commit('SET_PRIVATE_COURSE_APPLICATIONS', response)
      }
    } catch (error) {
      // console.log(`[requestClient] Запрос не удался по причине -  ${error}`)
    }
  }
}

const mutations = {
  SET_COURSES(state, course) {
    state.courses = course
  },

  SET_COURSE(state, course) {
    state.course = course
  },

  SET_POPULAR_COURSES(state, popularCourses) {
    state.popularCourses = popularCourses
  },
  SET_PRIVATE_COURSE_APPLICATIONS(state, privateCourseApplications) {
    state.privateCourseApplications = privateCourseApplications
  }

}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
