<template>
  <div class="grid grid-cols-12 gap-6 mt-8">
    <AsideModules :course="course" />
    <div class="col-span-12 lg:col-span-9 2xl:col-span-10">
    <!-- BEGIN: File Manager Filter -->
      <div class="intro-y flex flex-col-reverse sm:flex-row items-center justify-end">
        <div class="w-full sm:w-auto">
          <CreateModule :courseId="course.id" />
        </div>
      </div>
      <!-- END: File Manager Filter -->
      <div class="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-5">
        <!-- BEGIN: Boxed Accordion -->
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible" v-if="modules.length > 0">
          <AccordionModules  :draggable-list="modules"/>
        </div>
        <!-- END: Boxed Accordion -->
      </div>
    </div>
    <OptionModules />
  </div>
</template>

<style scoped>
.accordion .accordion-item {
  padding: 0;
}
</style>

<script>

import AccordionModules from '@/components/accordions/Modules/Main'
import CreateModule from '@/components/modals/Modules/Create'
import OptionModules from '@/components/modals/Modules/Option/Main'
import AsideModules from '@/components/aside/Modules'

export default {
  components: {
    OptionModules,
    CreateModule,
    AsideModules,
    AccordionModules
  },
  computed: {
    slug() {
      return this.$route.params.slug
    },
    course() {
      return this.$store.state.courses.course
    },
    modules() {
      return this.$store.state.modules.modules
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$store.dispatch('courses/getCourse', this.slug)
      this.$store.dispatch('modules/getModules', this.slug)
    }
  }
}
</script>
