<template>
    <div>
        <div class="intro-y flex items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Изменить пароль</h2>
        </div>
        <div class="grid grid-cols-12 gap-6">
            <AsideProfile />
            <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
                <!-- BEGIN: Change Password -->
                <form @submit.prevent="save">
                    <div class="intro-y box lg:mt-5">
                        <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                            <h2 class="font-medium text-base mr-auto">Изменить пароль</h2>
                        </div>
                        <div class="p-5">
                            <InputPassword :label="`toggle-password-form-1`">
                              <label for="change-password-form-1" class="form-label">Старый пароль</label>
                              <input id="change-password-form-1" type="password" class="form-control js-password"
                                  placeholder="Введите старый пароль" v-model="form.current_password" />
                            </InputPassword>
                            <InputPassword class="mt-3" :label="`toggle-password-form-2`">
                              <label for="change-password-form-2" class="form-label">Новый пароль</label>
                              <input id="change-password-form-2" type="password" class="form-control js-password"
                                  placeholder="Введите новый пароль" v-model="form.new_password" />
                            </InputPassword>
                            <InputPassword class="mt-3" :label="`toggle-password-form-3`">
                              <label for="change-password-form-3" class="form-label">Подтвердить новый пароль</label>
                              <input id="change-password-form-3" type="password" class="form-control  js-password"
                                  placeholder="Подтвердить новый пароль" v-model="form.new_confirm_password" />
                            </InputPassword>
                            <button type="submit" class="btn btn-primary mt-4">
                                Изменить пароль
                            </button>
                        </div>
                    </div>
                </form>
                <!-- END: Change Password -->
            </div>
        </div>
    </div>
</template>

<script>
import AsideProfile from '@/components/aside/Profile'
import InputPassword from '@/components/ui/Input/Password'
export default {
  components: {
    AsideProfile,
    InputPassword
  },
  data() {
    return {
      form: {
        current_password: '',
        new_password: '',
        new_confirm_password: ''
      }
    }
  },
  methods: {
    save() {
      this.$api.post('auth/change-password', this.form).then((result) => {
        this.$router.push({ name: 'profile' })
      })
    }
  }
}
</script>
