<template>
    <!-- BEGIN: Profile Menu -->
    <div class="col-span-12 lg:col-span-4 2xl:col-span-3 flex lg:block flex-col-reverse">
        <div class="intro-y box mt-5">
            <div class="relative flex items-center p-5">
                <div class="w-12 h-12 image-fit">
                    <img alt="Smarthub" class="rounded-full"
                        :src="user.image ?? require(`@/assets/images/${$f()[0].photos[0]}`)" />
                </div>
                <div class="ml-4 mr-auto">
                    <div class="font-medium text-base">
                        {{ user.fullname }}
                    </div>
                    <div class="text-gray-600">{{ user.teacher_info?.title ?? user.email }}</div>
                </div>
            </div>
            <div class="p-5 border-t border-gray-200 dark:border-dark-5">
                <router-link class="flex items-center" exact-active-class="text-theme-1 dark:text-theme-10 font-medium"
                    :to="{ name: 'profile' }">
                    <ActivityIcon class="w-4 h-4 mr-2" /> Профиль
                </router-link>
                <router-link class="flex items-center mt-5" :to="{ name: 'profile-change-password' }"
                    exact-active-class="text-theme-1 dark:text-theme-10 font-medium">
                    <LockIcon class="w-4 h-4 mr-2" /> Изменить пароль
                </router-link>
                <router-link class="flex items-center mt-5" :to="{ name: 'profile-social-networks' }"
                    exact-active-class="text-theme-1 dark:text-theme-10 font-medium">
                    <RadioIcon class="w-4 h-4 mr-2" /> Социальные сети
                </router-link>
            </div>
        </div>
    </div>
    <!-- END: Profile Menu -->
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.auth.user
    }
  }
}
</script>
