<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 custom-index">
      <h2 class="text-lg font-medium mr-auto">Добавить новый курс</h2>
      <LangSwitcher @changeLang="changeLang" />
    </div>
    <LangContent>
      <form @submit.prevent="save">
        <div class="post intro-y grid grid-cols-12 gap-5 mt-5">
          <!-- BEGIN: Post Content -->
          <div class="intro-y col-span-12 lg:col-span-8">
            <input
              type="text"
              v-model="title[lang]"
              class="intro-y form-control py-3 px-4 box pr-10 placeholder-theme-13"
              placeholder="Название курса"
              required
            />
            <div class="post intro-y overflow-hidden box mt-5">
              <div
                class="post__tabs nav nav-tabs flex-col sm:flex-row bg-gray-300 dark:bg-dark-2 text-gray-600"
                role="tablist"
              >
                <Tippy
                  id="content-tab"
                  tag="a"
                  content="Fill in the article content"
                  data-toggle="tab"
                  data-target="#content"
                  href="javascript:;"
                  class="w-full sm:w-40 py-4 text-center flex justify-center items-center active"
                  role="tab"
                  aria-controls="content"
                  aria-selected="true"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Контент
                </Tippy>
              </div>
              <div class="post__content tab-content">
                <div
                  id="content"
                  class="tab-pane p-5 active"
                  role="tabpanel"
                  aria-labelledby="content-tab"
                >
                  <div>
                    <label class="font-medium flex items-center"
                      >Описание
                    </label>
                    <div class="mt-5">
                      <ClassicEditor v-model="description[lang]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Post Content -->
          <!-- BEGIN: Post Info -->
          <div class="col-span-12 lg:col-span-4">
            <div class="intro-y box p-5">
              <div class="mt-3">
                <label class="form-label">загрузите обложку для курса</label>
                <div
                  class="border-2 border-dashed dark:border-dark-5 rounded-md"
                >
                  <div class="flex flex-wrap px-4">
                    <div
                      v-if="poster"
                      class="w-24 h-24 relative image-fit mb-5 mr-5 cursor-pointer zoom-in"
                    >
                      <img class="rounded-md" alt="Smarthub" :src="poster" />
                      <Tippy
                        tag="div"
                        content="Удалить постер"
                        @click="removeImage(1)"
                        class="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 -mt-2"
                      >
                        <xIcon class="w-4 h-4" />
                      </Tippy>
                    </div>
                  </div>
                  <div
                    class="px-4 pb-4 flex items-center cursor-pointer relative"
                  >
                    <ImageIcon class="w-4 h-4 mr-2" />
                    <span class="text-theme-1 dark:text-theme-10 mr-1"
                      >Загрузить файл</span
                    >
                    или перетащите
                    <input
                      type="file"
                      @change="onFileChange($event, 1)"
                      class="w-full h-full top-0 left-0 absolute opacity-0"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label for="post-form-article" class="form-label">Автор</label>
                <input
                  id="post-form-article"
                  type="text"
                  class="form-control"
                  v-model="author_course[lang]"
                  placeholder="Введите авторакурса"
                  required
                />
              </div>
              <div class="mt-3">
                <label for="post-form-article" class="form-label"
                  >Артикль</label
                >
                <input
                  id="post-form-article"
                  type="text"
                  class="form-control"
                  v-model="form.article"
                  placeholder="Введите код курса"
                  maxlength="8"
                  required
                />
              </div>
              <div class="mt-3">
                <label for="post-form-language" class="form-label"
                  >Язык курса</label
                >
                <input
                  id="post-form-language"
                  type="text"
                  class="form-control"
                  v-model="language[lang]"
                  placeholder="Введите язык курса"
                  required
                />
              </div>
              <div class="mt-3">
                <label for="post-form-price" class="form-label">Цена</label>
                <input
                  id="post-form-price"
                  type="number"
                  class="form-control"
                  v-model="form.price"
                  maxlength="9"
                  placeholder="Введите цену"
                />
              </div>
              <div class="mt-3">
                <label for="post-form-descount_price" class="form-label"
                  >Цена со скидкой</label
                >
                <input
                  id="post-form-descount_price"
                  type="number"
                  class="form-control"
                  v-model="form.descount_price"
                  maxlength="9"
                  placeholder="Введите цену со скидкой"
                />
              </div>
              <div class="mt-3">
                <label for="post-form-category" class="form-label"
                  >Категории</label
                >
                <TomSelect
                  id="post-form-category"
                  v-model="form.category_id"
                  class="w-full"
                  required
                  :options="{
                    placeholder: 'Выберите категорию'
                  }"
                >
                  <option
                    :value="category.id"
                    v-for="category in categories"
                    :key="category.id"
                  >
                    {{ category.name }}</option
                  >
                </TomSelect>
              </div>
              <div class="mt-3">
                <label>Приватный курс</label>
                <div class="mt-2">
                  <div class="form-check">
                    <input
                      id="private-course"
                      class="form-check-switch"
                      type="checkbox"
                      v-model="form.private"
                    />
                    <label class="form-check-label" for="private-course"
                      >Сделать приватным</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <button
              class="btn btn-primary shadow-md flex items-center mt-5 w-full"
              aria-expanded="false"
              type="submit"
            >
              Добавить
            </button>
          </div>
          <!-- END: Post Info -->
        </div>
      </form>
    </LangContent>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  data() {
    return {
      categories: [],
      poster: '',
      title: {},
      description: {},
      language: {},
      author_course: {},
      form: {
        article: '',
        price: null,
        descount_price: null,
        category_id: 1,
        private: 0
      },
      lang: 'ru'
    }
  },
  mounted() {
    this.getCategories()
  },
  methods: {
    changeLang(lang) {
      console.log(lang)
      this.lang = lang
    },
    getCategories() {
      this.$api.get('dashboard/category').then(response => {
        this.categories = response
      })
    },
    onFileChange(e, type) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.form.poster = files[0]
      this.createImage(files[0], type)
    },
    createImage(file, type) {
      const reader = new FileReader()
      reader.onload = e => {
        this.poster = e.target.result
      }
      reader.readAsDataURL(file)
    },
    removeImage(type) {
      this.poster = ''
    },
    save() {
      this.form.private = this.form.private == 0 ? 0 : 1
      const formData = new FormData()
      formData.append('poster', this.form.poster)
      _.each(this.title, (value, key) => {
        formData.append(`title[${key}]`, value)
      })
      _.each(this.description, (value, key) => {
        formData.append(`description[${key}]`, value)
      })
      _.each(this.author_course, (value, key) => {
        formData.append(`author_course[${key}]`, value)
      })
      _.each(this.language, (value, key) => {
        formData.append(`language[${key}]`, value)
      })
      _.each(this.form, (value, key) => {
        formData.append(key, value)
      })
      this.$api
        .post('dashboard/course', formData, {
          headers: {
            'Content-Type':
              'multipart/form-data; charset=utf-8; boundary=' +
              Math.random()
                .toString()
                .substr(2)
          }
        })
        .then(response => {
          this.$store.dispatch('courses/getCourses')
          this.$router.push('/courses')
        })
    }
  }
}
</script>
