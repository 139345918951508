/* eslint-disable camelcase */

const state = () => {
  return {
    pages: []
  }
}

const actions = {
  async getPages({
    commit
  }, data) {
    try {
      const response = await this.$api.get(`dashboard/course/${data.course_slug}/pages`,
        {
          params: {
            page: data.pages
          }
        })
      if (response) {
        commit('SET_PAGES', response)
      }
    } catch (error) {

    }
  }
}

const mutations = {
  SET_PAGES(state, pages) {
    state.pages = pages
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
