<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Заявка на возможность</h2>
    </div>
    <div class="intro-y grid grid-cols-12 gap-5 mt-8">
      <div class="intro-y col-span-12 lg:col-span-8">
        <!-- BEGIN: Display Information -->
        <div class="intro-y box">
          <div
            class="
              flex
              items-center
              p-5
              border-b border-gray-200
              dark:border-dark-5
            "
          >
            <h2 class="font-medium text-base mr-auto">Информация</h2>
          </div>
          <div class="p-5">
            <div class="flex xl:flex-row flex-col">
              <div class="flex-1">
                <div class="grid grid-cols-12 gap-x-5">
                  <div class="mt-2 col-span-12 2xl:col-span-6">
                    <label for="create-employee-fisrtname" class="form-label"
                      >Ф.И.О</label
                    >
                    <input
                      id="create-employee-fisrtname"
                      type="text"
                      class="form-control"
                      maxlength="14"
                      disabled
                      :value="application.fullname"
                    />
                  </div>
                  <div class="mt-3 col-span-12 2xl:col-span-6">
                    <label for="create-employee-email" class="form-label"
                      >E-mail</label
                    >
                    <input
                      id="create-employee-email"
                      type="email"
                      class="form-control"
                      disabled
                      :value="application.email"
                    />
                  </div>
                  <div class="mt-3 col-span-12 2xl:col-span-6">
                    <label for="create-employee-email" class="form-label"
                      >Телефон</label
                    >
                    <input
                      id="create-employee-email"
                      type="email"
                      class="form-control"
                      disabled
                      :value="application.phone"
                    />
                  </div>
                  <div class="mt-3 col-span-12 2xl:col-span-6">
                    <label for="create-employee-email" class="form-label"
                      >Описание</label
                    >
                    <textarea rows="5" class="form-control" v-html="application.description"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-between mt-5">
              <button @click="$router.go(-1)" class="btn btn-outline-secondary w-24 mr-1">
                Назад
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      application: {}
    }
  },
  mounted() {
    this.getApplication()
  },
  methods: {
    async getApplication() {
      this.$api
        .get(`dashboard/opportunities/${this.$route.params.slug}/applications/` + this.$route.params.id)
        .then((response) => {
          this.application = response
        })
    }
  }
}
</script>
