<template>
    <div>
        <div class="intro-y flex items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Профиль</h2>
        </div>
        <div class="grid grid-cols-12 gap-6">
            <AsideProfile />
            <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
                <!-- BEGIN: Display Information -->
                <div class="intro-y box lg:mt-5">
                    <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mr-auto">Персональная информация</h2>
                    </div>
                    <div class="p-5">
                        <div class="flex flex-col-reverse xl:flex-row flex-col">
                            <div class="flex-1 mt-6 xl:mt-0">
                                <div class="grid grid-cols-12 gap-x-5">
                                    <div class="col-span-12 2xl:col-span-6">
                                        <div>
                                            <label for="update-profile-form-1" class="form-label">Ф.И.О</label>
                                            <input id="update-profile-form-1" type="text" class="form-control"
                                                placeholder="Введите Ф.И.О" :value="user.fullname" readonly />
                                        </div>
                                        <div class="mt-3">
                                            <label for="update-profile-form-1" class="form-label">Ник</label>
                                            <input id="update-profile-form-1" type="text" class="form-control"
                                                placeholder="Введите ник" :value="user.nick_name" readonly />
                                        </div>
                                        <div class="mt-3">
                                            <label for="update-profile-form-1" class="form-label">E-mail</label>
                                            <input id="update-profile-form-1" type="text" class="form-control"
                                                placeholder="Введите e-mail" :value="user.email" readonly />
                                        </div>
                                    </div>
                                    <div class="col-span-12 2xl:col-span-6">
                                        <div class="mt-3 2xl:mt-0">
                                            <label for="update-profile-form-4" class="form-label">Номер телефона</label>
                                            <input id="update-profile-form-4" type="text" class="form-control"
                                                placeholder="Введите номер телефона" :value="user.phone" readonly />
                                        </div>
                                        <div div class="mt-3">
                                            <label for="update-profile-form-2" class="form-label">Пол</label>
                                            <select id="update-profile-form-2" v-model="user.gender" class="form-control"
                                                disabled>
                                                <option value="">Не выбрано</option>
                                                <option value="M">Мужской</option>
                                                <option value="W">Женский</option>
                                            </select>
                                        </div>
                                        <div class="mt-3">
                                            <label for="update-profile-form-8" class="form-label">Возраст</label>
                                            <input id="update-profile-form-8" type="tel" class="form-control"
                                                placeholder="Введите возраст" :value="user.age" readonly />
                                        </div>
                                    </div>
                                    <div class="col-span-12 mt-3">
                                        <label for="update-profile-form-5" class="form-label">Адрес</label>
                                        <textarea id="update-profile-form-5" class="form-control" placeholder="Введите адрес"
                                            v-model="user.address" readonly></textarea>
                                    </div>
                                </div>
                                <div class="flex justify-end mt-4">
                                    <router-link :to="{name: 'profile-settings'}" class="btn btn-warning w-24 mr-auto">
                                        Изменить
                                    </router-link>
                                </div>
                            </div>
                            <div class="w-52 mx-auto xl:mr-0 xl:ml-6">
                                <div
                                    class="border-2 border-dashed shadow-sm border-gray-200 dark:border-dark-5 rounded-md p-5">
                                    <div class="h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                                        <img class="rounded-md" alt="Выберите аватарку"
                                            :src="user.thumbnail ?? require(`@/assets/images/${$f()[0].photos[0]}`)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AsideProfile from '@/components/aside/Profile'

export default {
  components: {
    AsideProfile
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    }
  }
}
</script>
