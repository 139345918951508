<template>
  <Multiple
    :defaultVariant="defaultVariant"
    ref="quize"
    :saver="saver"
    :count="count"
    v-if="type == 1"
  />
  <Custom
    :defaultVariant="defaultVariant"
    ref="quize"
    :saver="saver"
    :count="count"
    v-else-if="type == 3"
  />
  <Single
    :defaultVariant="defaultVariant"
    ref="quize"
    :saver="saver"
    :count="count"
    v-else-if="type == 4"
  />
  <Boolean
    :defaultVariant="defaultVariant"
    ref="quize"
    :saver="saver"
    :count="count"
    v-else-if="type == 2"
  />
</template>

<script>
import Custom from './Custom'
import Multiple from './Multiple'
import Single from './Single'
import Boolean from './Boolean'

export default {
  props: ['type', 'saver', 'count', 'defaultVariant'],
  components: {
    Custom,
    Multiple,
    Single,
    Boolean
  }
}
</script>
