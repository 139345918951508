<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Изменить роль</h2>
    </div>
    <form>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-4">
          <div class="intro-y box">
            <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base mr-auto">Информация о роле </h2>
            </div>
            <div class="p-5 space-y-3">
              <div>
                <label for="crud-form-1" class="form-label">Алиас</label>
                <input
                  id="crud-form-1"
                  type="text"
                  class="form-control w-full"
                  placeholder="Введите алиас"
                  required
                  readonly
                  v-model="form.name"
                />
              </div>
              <div>
                <label for="crud-form-1" class="form-label">Отображаемое название</label>
                <input
                  id="crud-form-1"
                  type="text"
                  class="form-control w-full"
                  placeholder="Введите отображаемое название"
                  required
                  readonly
                  v-model="form.display_name"
                />
              </div>
              <div>
                <label for="crud-form-1" class="form-label">Описание</label>
                <textarea readonly
                  id="crud-form-1"
                  type="text"
                  class="form-control w-full"
                  placeholder="Введите описание"
                  required
                  v-model="form.description"
                />
              </div>
              <div class="text-right mt-5">
                <router-link :to="{name: 'permissions'}" class="btn btn-outline-secondary w-24 mr-1">
                  Назад
                </router-link>
                <router-link :to="{name: 'permissions-update', params:{id: $route.params.id }}" class="btn btn-warning w-24">Изменить</router-link>
              </div>
            </div>
          </div>
          <!-- END: Form Layout -->
        </div>
        <div class="intro-y col-span-12 lg:col-span-8">
          <!-- BEGIN: Form Layout -->
          <div class="intro-y box">
            <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base mr-auto">Список прав </h2>
            </div>
            <div class="p-5">
              <div class="grid 2xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 mt-2 gap-5">
                <div class="form-check mr-2" v-for="(permission, index) in list_permissions.data" :key="index">
                  <input :id="permission.name" class="form-check-input" type="checkbox" :key="index" :value="permission.id" v-model="form.permissions" disabled>
                  <label class="form-check-label" :for="permission.name">{{ permission.display_name }}</label>
                </div>
              </div>
              <Pagination
                :per_page="list_permissions.per_page"
                :total="list_permissions.total"
                :current-page="currentPage"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  data() {
    return {
      form: []
    }
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1')
    },
    list_permissions() {
      return this.$store.state.roles.permissions
    }
  },
  mounted() {
    this.init()
    this.role()
  },
  watch: {
    currentPage() {
      this.pagination(this.currentPage)
    }
  },
  methods: {
    init() {
      if (this.list_permissions.length === 0) {
        this.$store.dispatch('roles/getPermissions')
      }
    },
    role() {
      this.$api.get('dashboard/roles/' + this.$route.params.id).then((response) => {
        this.form = response
        if (this.form.length != 0) response.permissions.forEach(el => this.form.permissions.push(el.id))
      })
    },
    pagination(page) {
      this.$store.dispatch('roles/getPermissions', page)
    }
  }
}
</script>
