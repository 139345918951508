import AuthService from '@/services/auth/services'
import UserService from '@/services/auth/user.services'

const user = JSON.parse(localStorage.getItem('user'))
export const auth = {
  namespaced: true,
  state() {
    return {
      user: user || null,
      status: {
        loggedIn: !!user
      }
    }
  },
  actions: {
    login({
      commit
    }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    me({ commit }, user) {
      if (user) {
        return UserService.getUserBoard().then(
          user => {
            commit('setMe', user)
            localStorage.setItem('user', JSON.stringify(user))
            return Promise.resolve(user)
          }
        )
      }
      return Promise.resolve(user)
    },
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken)
    },
    logout({
      commit
    }) {
      AuthService.logout()
      commit('logout')
    },
    register({
      commit
    }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess')
          return Promise.resolve(response)
        },
        error => {
          commit('registerFailure')
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
    },
    registerSuccess(state) {
      state.status.loggedIn = false
    },
    registerFailure(state) {
      state.status.loggedIn = false
    },
    setMe(state, user) {
      state.user = user
    },
    refreshToken(state, accessToken) {
      state.status.loggedIn = true
      state.user = { ...state.user, accessToken: accessToken }
    }
  }
}
