import axios from '@/plugins/axios'
class AuthService {
  login(user) {
    return axios
      .post('/auth/login', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        const token = response.token
        if (token) {
          localStorage.setItem('token', JSON.stringify(token))
          this.me(token)
        }

        return token
      })
  }

  me(token) {
    return axios.get('/auth/me', { headers: { Authorization: 'Bearer ' + token } }).then(response => {
      if (response) {
        localStorage.setItem('user', JSON.stringify(response))
      }
      location.href = '/'
      return response
    })
  }

  logout() {
    localStorage.removeItem('user')
    location.href = '/'
  }

  register(user) {
    return axios.post('/auth/register', {
      fullname: user.fullname,
      email: user.email,
      password: user.password
    })
  }
}

export default new AuthService()
