<template>
  <div class="flex justify-end space-x-4">
    <Combobox v-model="selectedUser">
      <div class="relative mt-1">
        <div
          class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
        >
          <ComboboxInput
            class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
            :displayValue="person => person?.fullname"
            @change="onInput"
          />
        </div>
        <TransitionRoot
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          @after-leave="query = ''"
        >
          <ComboboxOptions
            class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
          >
            <div
              v-if="users.length === 0 && query !== ''"
              class="relative cursor-default select-none px-4 py-2 text-gray-700"
            >
              Nothing found.
            </div>

            <ComboboxOption
              v-for="person in users"
              as="template"
              :key="person.id"
              :value="person"
              v-slot="{ selected, active }"
            >
              <li
                class="relative cursor-default select-none py-2 pl-10 pr-4 hover:bg-gray-200"
                :class="{
                  'bg-teal-600': active,
                  'text-gray-900': !active
                }"
              >
                <span
                  class="block truncate"
                  :class="{ 'font-medium': selected, 'font-normal': !selected }"
                >
                  {{ person.fullname }}
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
    <button
      class="btn btn-primary py-1 px-2 mr-2"
      @click="addUser"
      :disabled="!selectedUser"
    >
      Добавить
    </button>
  </div>
</template>

<script>
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot
} from '@headlessui/vue'
import axiosInstance from '@/plugins/axios'

export default {
  components: {
    TransitionRoot,
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption
  },
  data() {
    return {
      query: '',
      selectedUser: null,
      users: []
    }
  },
  watch: {
    query(newQuery) {
      this.fetchUsers(newQuery)
    }
  },
  methods: {
    onInput(event) {
      this.query = event.target.value
      if (this.selectedUser) {
        this.selectedUser = null
      }
    },
    async fetchUsers(query) {
      if (query.length < 3) {
        this.users = []
        return
      }
      try {
        const response = await axiosInstance.get(
          `/dashboard/user/find?search=${query}`,
          {
            showLoader: false
          }
        )
        this.users = response.data
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    },
    async addUser() {
      const slug = this.$router.currentRoute.value.params.slug
      if (!this.selectedUser) {
        return
      }
      try {
        await axiosInstance.post(`/dashboard/course/${slug}/add-student`, {
          user_id: this.selectedUser.id
        })
        this.$store.dispatch('students/getStudents', { course_slug: slug })
        this.selectedUser = null
        this.users = []
      } catch (error) {
        console.error('Error adding user:', error)
      }
    }
  }
}
</script>
