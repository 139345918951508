<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Настройки</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Название</th>
              <th class="text-center whitespace-nowrap">Дата</th>
              <th class="text-center whitespace-nowrap">Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(setting, index) in settings"
              :key="index"
              class="intro-x"
            >
              <td>
                <router-link :to="{name: 'settings-show', params: {id: setting.id}}" class="font-medium">{{
                  setting.description
                }}
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5 space-x-3">
                  <span>{{ setting.key }}</span>
                </div>
                </router-link>
              </td>
              <td class="w-60">
                <div
                  class="flex items-center justify-center"
                >
                  <CalendarIcon class="w-4 h-4 mr-2" />
                  {{ $h.formatDate(setting.updated_at, 'D MMMM YYYY')  }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link class="flex items-center mr-3" :to="{name: 'settings-update', params: {id: setting.id}}">
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Изменить
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      settings: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$api.get('/dashboard/settings').then((response) => {
        this.settings = response
      })
    }
  }
}
</script>
