<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Добавить категорию</h2>
      <LangSwitcher @changeLang="changeLang" />
    </div>
    <LangContent>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <!-- BEGIN: Form Layout -->
          <form @submit.prevent="submit">
          <div class="intro-y box p-5">
            <div>
              <label for="crud-form-1" class="form-label">Название категории</label>
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full"
                placeholder="Введите название"
                required
                v-model="form.name[lang]"
              />
            </div>
            <div class="mt-3">
              <label for="crud-form-2" class="form-label">Выберите категорию</label>
              <TomSelect
                id="crud-form-2"
                v-model="form.parent_id"
                class="w-full"
              >
                <option selected>Сделать родительской</option>
                <option :value="category.id" v-for="(category, index) in categories" :key="index">{{ category.name }}</option>
              </TomSelect>
            </div>
            <div class="text-right mt-5">
              <router-link to="/categories" class="btn btn-outline-secondary w-24 mr-1">
                Назад
              </router-link>
              <button type="submit" class="btn btn-primary w-24">Сохранить</button>
            </div>
          </div></form>
          <!-- END: Form Layout -->
        </div>
      </div>
    </LangContent>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: {},
        parent_id: ''
      },
      lang: 'ru',
      categories: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    changeLang(lang) {
      this.lang = lang
    },
    async init() {
      await this.$api.get('/dashboard/category').then((response) => {
        this.categories = response
      })
    },
    submit() {
      this.$api.post('/dashboard/category', this.form).then((response) => {
        this.$router.push('/categories')
      })
    }
  }
}
</script>
