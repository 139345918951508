<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Предпросмотр категории</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <form @submit.prevent="submit">
        <div class="intro-y box p-5">
          <div>
            <label for="crud-form-1" class="form-label">Название категории</label>
            <input
              id="crud-form-1"
              type="text"
              class="form-control w-full"
              placeholder="Введите название"
              :value="category.name"
              required
              disabled
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-2" class="form-label">Уровень категории</label>
            <TomSelect
              id="crud-form-2"
              v-model="category.parent_id"
              class="w-full disabled:border-gray-500 border rounded-md"
              disabled
            >
              <option :value="item.id" v-for="(item, index) in categories" :key="index" :selected="category.parent_id == item.id ? true : false">{{ item.id == category.id ? 'Родительская категория' : item.name}}</option>
            </TomSelect>
          </div>
          <div class="flex justify-between mt-5">
            <router-link to="/categories" class="btn btn-outline-secondary w-24 mr-1">
              Назад
            </router-link>
            <router-link :to="{name: 'categories-update', params: {slug: this.$route.params.slug}}" class="btn btn-primary w-24">
              Изменить
            </router-link>
          </div>
        </div></form>
        <!-- END: Form Layout -->
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible" v-if="category">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Название</th>
              <th class="text-center whitespace-nowrap">Кол-во под-категории</th>
              <th class="text-center whitespace-nowrap">Статус</th>
              <th class="text-center whitespace-nowrap">Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(cat, index) in category.children"
              :key="index"
              class="intro-x"
            >
              <td>
                <router-link :to="{name: 'categories-show', params: {slug: cat.slug}}" class="font-medium whitespace-nowrap">{{
                  cat.name
                }}</router-link>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5 space-x-3" v-if="cat.children">
                  <span v-for="(item, index) in cat.children.slice(0, 3)" :key="index">{{ item.name }}</span>
                </div>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5" v-else>
                  <span>Под категории нет</span>
                </div>
              </td>
              <td class="text-center">{{ cat.children.length }}</td>
              <td class="w-40">
                <div
                  class="flex items-center justify-center"
                  :class="{'text-theme-9': !cat.deleted_at, 'text-theme-6': cat.deleted_at }"
                >
                  <CheckSquareIcon class="w-4 h-4 mr-2" />
                  {{ cat.deleted_at ? 'Удален ' : 'Активен' }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link :to="{name: 'categories-update', params: {slug: cat.slug}}" class="flex items-center mr-3">
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Изменить
                  </router-link>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    @click="$refs.deleteModal.openModal(cat.slug)"

                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Удалить
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <AlertRemove ref="deleteModal" :onDelete="onDelete" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      category: [],
      categories: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.$api.get('/dashboard/category').then((response) => {
        this.categories = response
      })
      await this.$api.get(`/dashboard/category/${this.$route.params.slug}`).then((response) => {
        this.category = response
      })
    },
    onDelete(deletingItem) {
      this.$api.delete('/dashboard/category/' + deletingItem)
      this.init()
    }
  }
}
</script>
