<template>
  <div id="first-step-module">
    <div class="preview">
      <!-- BEGIN: Modal Toggle -->
      <div class="text-center">
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#create-modules-modal"
          class="btn btn-primary"
          >Добавить модуль</a
        >
      </div>
      <!-- END: Modal Toggle -->
      <!-- BEGIN: Modal Content -->
      <div
        id="create-modules-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <form @submit.prevent="createModule" class="modal-content">
            <!-- BEGIN: Modal Header -->
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">Добавить модуль</h2>
              <button type="button" data-dismiss="modal">
                <XCircleIcon class="w-6 h-6" />
              </button>
            </div>
            <!-- END: Modal Header -->
            <!-- BEGIN: Modal Body -->
            <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
              <div class="col-span-12">
                <label for="module-title" class="form-label"
                  >Название модуля</label
                >
                <input
                  id="module-title"
                  type="text"
                  v-model="form.title"
                  class="form-control"
                  placeholder="Введите название модуля"
                />
              </div>
              <div class="col-span-12">
                <div class="form-check mt-2">
                  <input
                    id="is-block-until"
                    v-model="isUntilBlock"
                    class="form-check-input"
                    type="checkbox"
                    value="1"
                  />
                  <label class="form-check-label" for="is-block-until"
                    >Заблокировать до</label
                  >
                </div>
                <div v-if="isUntilBlock" class="preview mt-4">
                  <div class="relative">
                    <div
                      class="
                        absolute
                        rounded-l
                        w-10
                        h-full
                        flex
                        items-center
                        justify-center
                        bg-gray-100
                        border
                        text-gray-600
                        dark:bg-dark-1 dark:border-dark-4
                      "
                    >
                      <CalendarIcon class="w-4 h-4" />
                    </div>
                    <Litepicker
                      v-model="form.block_untill"
                      :options="{
                        autoApply: false,
                        showWeekNumbers: true,
                        dropdowns: {
                          minYear: 1990,
                          maxYear: null,
                          months: true,
                          years: true
                        },
                        format: 'YYYY-MM-DD 00:00:00'
                      }"
                      class="form-control pl-12"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- END: Modal Body -->
            <!-- BEGIN: Modal Footer -->
            <div class="modal-footer text-right">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-auto mr-1 px-2.5"
              >
                Отменить
              </button>
              <button type="submit" class="btn btn-primary w-auto px-2.5">
                Добавить модуль
              </button>
            </div>
            <!-- END: Modal Footer -->
          </form>
        </div>
      </div>
      <!-- END: Modal Content -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['courseId'],
  data() {
    return {
      isUntilBlock: false,
      form: {
        title: '',
        block_untill: '',
        position: 1,
        course_id: ''
      }
    }
  },
  methods: {
    createModule() {
      this.form.course_id = this.courseId
      this.$api
        .post(`/dashboard/course/${this.$route.params.slug}/modules`, this.form)
        .then((result) => {
          cash('#create-modules-modal').modal('hide')
          this.$store.dispatch('modules/getModules', this.$route.params.slug)
          this.isUntilBlock = false
          this.form = {
            title: '',
            block_untill: '',
            position: 1,
            course_id: ''
          }
        })
    }
  }
}
</script>
