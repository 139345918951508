<template>
  <!-- BEGIN: Failed Notification Content -->
  <div ref="errors" id="failed-notification-content" class="toastify-content hidden flex">
    <XCircleIcon class="text-theme-6" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Ошибка!</div>
      <div class="text-gray-600 mt-1 space-y-2">
        <p class="border-b-2 border-dashed pb-2">Упс, что то пошло ни так, подробнее ошибка описана ниже 👇</p>
        <ul class="ml-4 notification-message list-[auto]"></ul>
      </div>
    </div>
  </div>
  <!-- END: Failed Notification Content -->
</template>
