<template>
  <div>
    <div class="flex justify-between items-center my-10 intro-y">
      <h2 class="text-lg font-medium">Роли</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5 intro-y">
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Название</th>
              <th class="text-center whitespace-nowrap">
                Кол-во / прав
              </th>
              <th class="text-center whitespace-nowrap">Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(role, index) in roles"
              :key="index"
              class="intro-x"
            >
              <td>
                <router-link
                  :to="{
                    name: 'permissions-show',
                    params: { id: role.id }
                  }"
                  class="font-medium whitespace-nowrap"
                  >{{ role.display_name }}</router-link
                >
                <div
                  class="text-gray-600 text-xs whitespace-nowrap mt-0.5 space-x-3"
                >
                {{ role.description }}
                </div>
              </td>
              <td class="text-center">{{ role.permissions_count }}</td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link
                    :to="{
                      name: 'permissions-update',
                      params: { id: role.id }
                    }"
                    class="flex items-center mr-3 text-theme-4"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Изменить
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    roles() {
      return this.$store.state.roles.roles
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$store.dispatch('roles/getRoles')
    }
  }
}
</script>
