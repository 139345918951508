<template>
  <div class="grid grid-cols-12 gap-6 mt-8">
    <AsideModules :course="course" />
    <div class="col-span-12 lg:col-span-9 2xl:col-span-10">
      <!-- BEGIN: File Manager Filter -->
      <div
        class="intro-y flex flex-col-reverse sm:flex-row items-center justify-end"
      >
        <router-link
          :to="{
            name: 'course-quizes-create',
            params: { slug: this.$route.params.slug }
          }"
          data-toggle="modal"
          data-target="#create-modules-modal"
          class="btn btn-primary"
          >Добавить тест
        </router-link>
      </div>
      <!-- END: File Manager Filter -->
      <div>
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-report mt-5">
            <thead>
              <tr>
                <th class="whitespace-nowrap">Название</th>
                <th class="text-center whitespace-nowrap">Статус</th>
                <th class="text-center whitespace-nowrap">Действие</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(quize, index) in quizes.data" :key="index" class="intro-x">
                <td>
                  <router-link
                    :to="{
                      name: 'course-quizes-show',
                      params: { slug: course.slug, id: quize.slug }
                    }"
                    class="font-medium whitespace-nowrap"
                    >{{ quize.title }}</router-link
                  >
                </td>
                <td class="w-40">
                  <div
                    class="flex items-center justify-center"
                    :class="{
                      'text-theme-9': !quize.deleted_at,
                      'text-theme-6': quize.deleted_at
                    }"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-2" />
                    {{ quize.deleted_at ? 'Удален ' : 'Активен' }}
                  </div>
                </td>
                <td class="table-report__action w-56">
                  <div class="flex justify-center items-center">
                    <router-link
                      :to="{
                        name: 'course-quizes-update',
                        params: { slug: course.slug, id: quize.slug }
                      }"
                      class="flex items-center mr-3"
                    >
                      <CheckSquareIcon class="w-4 h-4 mr-1" />
                      Изменить
                    </router-link>
                    <button
                      type="button"
                      class="flex items-center text-theme-6"
                      @click="$refs.deleteModal.openModal(quize.slug)"
                    >
                      <Trash2Icon class="w-4 h-4 mr-1" /> Удалить
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <Pagination
          v-if="quizes"
          :per_page="quizes.per_page"
          :total="quizes.total"
          :current-page="currentPage" />
        <AlertRemove ref="deleteModal" :onDelete="onDelete" />
      </div>
    </div>
  </div>
</template>

<script>
import AsideModules from '@/components/aside/Modules'

export default {
  components: {
    AsideModules
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1')
    },
    slug() {
      return this.$route.params.slug
    },
    course() {
      return this.$store.state.courses.course
    },
    quizes() {
      return this.$store.state.quizes.quizes
    }
  },
  watch: {
    currentPage() {
      this.pagination(this.currentPage)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$store.dispatch('courses/getCourse', this.slug)
      this.$store.dispatch('quizes/getQuizes', { course_slug: this.slug })
    },
    pagination(page) {
      this.$store.dispatch('quizes/getQuizes', { course_slug: this.slug, pages: page })
    },
    onDelete(deletingItem) {
      this.$api.delete(`/dashboard/course/${this.slug}/quizes/` + deletingItem)
      this.$store.dispatch('quizes/getQuizes', { course_slug: this.slug })
    }
  }
}
</script>
