<template>
    <div v-if="isOpen" class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-50">
        <div v-if="isOpen" enter-from-class="ease-out duration-300" enter-active-class="opacity-0" enter-to-class="opacity-100" leave-from-class="ease-in duration-200" leave-active-class="opacity-100" leave-to-class="opacity-0" class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div v-if="isOpen" enter-from-class="ease-out duration-300" enter-active-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to-class="opacity-100 translate-y-0 sm:scale-100" leave-from-class="ease-in duration-200" leave-active-class="opacity-100 translate-y-0 sm:scale-100" leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="modal-header">
                <h2 class="font-medium text-base mr-auto">
                    Вариант # {{ count }}
                </h2>
                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button @click="isOpen = false" type="button" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="modal-body grid grid-cols-12 gap-4">
                <div class="col-span-12">
                    <label for="module-title" class="form-label">Введите вопрос</label>
                    <input id="module-title" type="text" v-model="variant.question" class="form-control"
                        required placeholder="Введите вопрос" />
                </div>
                <div class="col-span-12 grid grid-cols-2 gap-2">
                    <div>
                        <label for="variants-number" class="form-label">Оценка за тест</label>
                        <input id="variants-number" type="number" v-model="variant.points"
                            class="form-control" required placeholder="Введите оценку" />
                    </div>
                    <div>
                        <label for="variants-quize-type" class="form-label">Тип Теста</label>
                        <TomSelect id="variants-quize-type" @change="resetVariant" v-model="variant.quize_type_id" class="w-full" required>
                            <option v-for="(item, index) in types" :key="index" :value="item.id">{{ item.name }}</option>
                        </TomSelect>
                    </div>
                </div>
                <QuizeVariants
                    :defaultVariant="defaultVariant"
                    ref="childComponent"
                    :type="variant.quize_type_id"
                    :saver="variantSaver"
                    :count="count"
                />
            </div>
            <!-- END: Modal Body -->
            <!-- BEGIN: Modal Footer -->
            <div class="modal-footer text-right space-x-5">
                <button type="button" @click="isOpen = false"
                    class="btn btn-outline-secondary w-auto mr-1 px-2.5">
                    Отменить
                </button>
                <button type="button" @click="isOpen = false" class="btn btn-primary w-auto px-2.5">
                    Изменить тест
                </button>
            </div>
        </div>
        </div>
</template>

<script>
import QuizeVariants from '@/components/modals/Quizes/Variants/Main'

export default {
  props: ['types', 'defaultVariant', 'count'],
  components: {
    QuizeVariants
  },
  data() {
    return {
      isOpen: false,
      variant: []
    }
  },
  watch: {
    defaultVariant: {
      handler(value) {
        this.openShowModal(value)
      },
      deep: true
    }
  },
  methods: {
    save() {
      const message = this.$refs.childComponent.$refs.quize.validate()

      if (!message) {
        console.log(this.variant)
        this.$store.dispatch('quizes/addVariants', this.variant).then((result) => {
          this.variant = {
            points: 1,
            quize_type_id: 1,
            question: ''
          }
          cash('#create-variants-modal').modal('hide')
        })
      } else {
        alert(message)
      }
    },
    resetVariant() {
      this.variant = this.returnNewObjectOnlyValidKeys(
        this.variant,
        ['points', 'quize_type_id', 'question']
      )
    },
    openShowModal(variant) {
      this.isOpen = !this.isOpen
      this.variant = {
        points: variant.points,
        question: variant.question,
        quize_type_id: variant.quize_type_id,
        correct_answers: variant.correct_answers,
        answers: variant.answers
      }
    },
    variantSaver(data) {
      this.variant = { ...this.variant, ...data }
    },
    returnNewObjectOnlyValidKeys(obj, validKeys) {
      const newObject = {}
      Object.keys(obj).forEach(key => {
        if (validKeys.includes(key)) newObject[key] = obj[key]
      })
      return newObject
    }
  }
}
</script>
