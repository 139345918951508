<template>
    <div>
        <div class="preview">
            <!-- BEGIN: Modal Toggle -->
            <div class="text-center">
                <button @click="openModal" type="button"
                    class="btn btn-dark w-full mt-5">
                    <LifeBuoyIcon class="w-4 h-4 mr-2" /> Добавить тест
                </button>
            </div>
            <!-- END: Modal Toggle -->
            <!-- BEGIN: Modal Content -->
            <div id="create-variants-modal" class="modal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <form class="modal-content">
                        <!-- BEGIN: Modal Header -->
                        <div class="modal-header">
                            <h2 class="font-medium text-base mr-auto">
                                Вариант # {{ count }}
                            </h2>
                            <button type="button" data-dismiss="modal">
                                <XCircleIcon class="w-6 h-6" />
                            </button>
                        </div>
                        <!-- END: Modal Header -->
                        <!-- BEGIN: Modal Body -->
                        <div class="modal-body grid grid-cols-12 gap-4">
                            <div class="col-span-12">
                                <label for="module-title" class="form-label">Введите вопрос</label>
                                <input id="module-title" type="text" v-model="variant.question" class="form-control"
                                    required placeholder="Введите вопрос" />
                            </div>
                            <div class="col-span-12 grid grid-cols-2 gap-2">
                                <div>
                                    <label for="variants-number" class="form-label">Оценка за тест</label>
                                    <input id="variants-number" type="number" v-model="variant.points"
                                        class="form-control" required placeholder="Введите оценку" />
                                </div>
                                <div>
                                    <label for="variants-quize-type" class="form-label">Тип Теста</label>
                                    <TomSelect id="variants-quize-type" @change="resetVariant" v-model="variant.quize_type_id" class="w-full" required>
                                        <option v-for="(item, index) in types" :key="index" :value="item.id">{{ item.name }}</option>
                                    </TomSelect>
                                </div>
                            </div>
                            <QuizeVariants
                              :defaultVariant="defaultVariant"
                              ref="childComponent"
                              :type="variant.quize_type_id"
                              :saver="variantSaver"
                              :count="count"
                            />
                        </div>
                        <!-- END: Modal Body -->
                        <!-- BEGIN: Modal Footer -->
                        <div class="modal-footer text-right space-x-5">
                            <button type="button" data-dismiss="modal"
                                class="btn btn-outline-secondary w-auto mr-1 px-2.5">
                                Отменить
                            </button>
                            <button type="button" @click="save" class="btn btn-primary w-auto px-2.5">
                                Добавить тест
                            </button>
                        </div>
                        <!-- END: Modal Footer -->
                    </form>
                </div>
            </div>
            <!-- END: Modal Content -->
        </div>
    </div>
</template>

<script>
import QuizeVariants from '@/components/modals/Quizes/Variants/Main'

export default {
  props: ['types', 'defaultVariant'],
  components: {
    QuizeVariants
  },
  data() {
    return {
      count: 1,
      variant: {
        points: 1,
        quize_type_id: 1,
        question: ''
      }
    }
  },
  watch: {
    defaultVariant: {
      handler(value) {
        if (value) {
          this.variant = {
            points: value.points,
            question: value.question,
            quize_type_id: value.quize_type_id
          }
        }
      },
      deep: true
    }
  },
  methods: {
    save() {
      const message = this.$refs.childComponent.$refs.quize.validate()

      if (!message) {
        console.log(this.variant)
        this.$store.dispatch('quizes/addVariants', this.variant).then((result) => {
          this.variant = {
            points: 1,
            quize_type_id: 1,
            question: ''
          }
          cash('#create-variants-modal').modal('hide')
          this.count++
        })
      } else {
        alert(message)
      }
    },
    openModal() {
      cash('#create-variants-modal').modal('show')
    },
    resetVariant() {
      this.variant = this.returnNewObjectOnlyValidKeys(
        this.variant,
        ['points', 'quize_type_id', 'question']
      )
    },
    variantSaver(data) {
      this.variant = { ...this.variant, ...data }
    },
    returnNewObjectOnlyValidKeys(obj, validKeys) {
      const newObject = {}
      Object.keys(obj).forEach(key => {
        if (validKeys.includes(key)) newObject[key] = obj[key]
      })
      return newObject
    }
  }
}
</script>
