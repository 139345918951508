<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Возможности</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link :to="{name: 'possibilities-create'}" class="btn btn-primary shadow-md mr-2"
          >Добавить</router-link
        >
      </div>
    </div>
    <div
      class="intro-y grid grid-cols-12 gap-6 mt-5"
      v-if="!posibilities.length"
    >
      <!-- BEGIN: Blog Layout -->
      <div
        v-for="(item, index) in posibilities.data"
        :key="index"
        class="intro-y col-span-12 md:col-span-6 xl:col-span-4 box"
      >
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5 px-5 py-4"
          >
            <div class="w-10 h-10 flex-none image-fit">
              <img
                alt="Smarthub"
                class="rounded-full"
                :src="require(`@/assets/images/profile-1.jpg`)"
              />
            </div>
            <div class="ml-3 mr-auto">
              <span class="font-medium">{{ item.author.fullname }}</span>
              <div class="flex text-gray-600 truncate text-xs mt-0.5">
                <span
                  class="text-theme-1 dark:text-theme-10 inline-block truncate"
                  >Возможности</span
                >
                <span class="mx-1">•</span> {{ $h.timeAgo(item.created_at) }}
              </div>
            </div>
            <!--  -->
            <div class="dropdown ml-3">
              <a
                href="javascript:;"
                class="dropdown-toggle w-5 h-5 text-gray-600 dark:text-gray-300"
                aria-expanded="false"
              >
                <MoreVerticalIcon class="w-5 h-5" />
              </a>
              <div class="dropdown-menu w-40">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <router-link :to="`/possibilities/update/${item.slug}`"
                    class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  >
                    <Edit2Icon class="w-4 h-4 mr-2" /> Редактировать
                  </router-link>
                  <button
                    @click="$refs.deleteModal.openModal(item.slug)"
                    class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  >
                    <TrashIcon class="w-4 h-4 mr-2" /> Удалить
                  </button>
                </div>
              </div>
            </div>
          </div>
          <router-link :to="`/possibilities/show/${item.slug}`">
            <div class="p-5">
              <div class="h-40 2xl:h-56 image-fit">
                <img
                  alt="Smarthub"
                  class="rounded-md"
                  :src="
                    item.thumbnail
                      ? item.thumbnail
                      : require(`@/assets/images/preview-2.jpg`)
                  "
                />
              </div>
              <p class="block font-medium text-base mt-5">{{ item.title }}</p>
              <div class="text-gray-700 dark:text-gray-600 mt-2" v-html="$h.cutText(item.description, 280)"></div>
            </div>
          </router-link>
        <!--  -->
      </div>
      <!-- END: Blog Layout -->
    </div>
    <Pagination
      :per_page="posibilities.per_page"
      :total="posibilities.total"
      :current-page="currentPage"
    />
    <AlertRemove ref="deleteModal" :onDelete="onDelete" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      posibilities: []
    }
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1')
    }
  },
  watch: {
    currentPage() {
      this.init(this.currentPage)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$api
        .get('/dashboard/opportunities', { params: { page: this.currentPage } })
        .then(response => {
          this.posibilities = response
        })
    },
    onDelete(deletingItem) {
      this.$api.delete('/dashboard/opportunities/' + deletingItem)
      this.init()
    }
  }
}
</script>
