<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 custom-index lg:max-w-3/4">
      <h2 class="text-lg font-medium mr-auto">Изменить страницы</h2>
      <LangSwitcher @changeLang="changeLang" />
    </div>
    <LangContent>
      <form @submit.prevent="update">
        <div class="post intro-y grid grid-cols-12 gap-5 mt-5">
          <!-- BEGIN: Post Content -->
          <div class="intro-y col-span-12 lg:col-span-9">
            <input
              type="text"
              v-model="form.title[lang]"
              class="
                intro-y
                form-control
                py-3
                px-4
                box
                pr-10
                placeholder-theme-13
              "
              placeholder="Название страницы"
            />
            <div class="post intro-y overflow-hidden box mt-5">
              <div
                class="
                  post__tabs
                  nav nav-tabs
                  flex-col
                  sm:flex-row
                  bg-gray-300
                  dark:bg-dark-2
                  text-gray-600
                "
                role="tablist"
              >
                <Tippy
                  id="content-tab"
                  tag="a"
                  content="Fill in the article content"
                  data-toggle="tab"
                  data-target="#content"
                  href="javascript:;"
                  class="
                    w-full
                    sm:w-40
                    py-4
                    text-center
                    flex
                    justify-center
                    items-center
                    active
                  "
                  role="tab"
                  aria-controls="content"
                  aria-selected="true"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Контент
                </Tippy>
              </div>
              <div class="post__content tab-content">
                <div
                  id="content"
                  class="tab-pane p-5 active"
                  role="tabpanel"
                  aria-labelledby="content-tab"
                >
                  <div>
                    <label class="font-medium flex items-center">Описание </label>
                    <div class="mt-5">
                      <ClassicEditor v-model="form.description[lang]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <button
                class="btn btn-primary shadow-md mt-5 w-full md:w-1/2"
                aria-expanded="false"
                type="submit"
              >
                Сохранить
              </button>
            </div>
          </div>
          <!-- END: Post Content -->
        </div>
      </form>
    </LangContent>
  </div>
</template>

<script>

export default {
  data() {
    return {
      form: {
        title: {},
        description: {}
      },
      lang: 'ru'
    }
  },
  computed: {
    slug() {
      return this.$route.params.slug
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    changeLang(lang) {
      this.lang = lang
    },
    update() {
      this.$api.put(`dashboard/pages/${this.slug}`, this.form)
        .then(response => {
          this.$router.push('/pages')
        })
    },
    async init() {
      this.$api.get(`/dashboard/pages/${this.slug}`, { headers: { lang: 'mix' } }).then(response => {
        this.form = response
      })
    }
  }
}
</script>
