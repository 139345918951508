<template>
  <NotificationMain />
  <Loader v-if="loading" />
  <router-view />
</template>

<script>
import NotificationMain from '@/components/modals/Notifications/Main'
import Loader from '@/components/loader/Main.vue'

export default {
  components: {
    NotificationMain,
    Loader
  },
  computed: {
    loading() {
      return this.$store.state.loader.loading
    }
  }
}
</script>
