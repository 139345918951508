<template>
  <div>
    <div class="flex justify-between items-center my-10 intro-y">
      <h2 class="text-lg font-medium">Слайдеры</h2>
      <router-link
        :to="{
            name: 'sliders-create',
            params: { id: $route.params.id }
          }"
        class="btn btn-primary shadow-md mr-2"
        >Добавить слайд</router-link
      >
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5 intro-y">
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Название</th>
              <th class="text-center whitespace-nowrap">Статус</th>
              <th class="text-center whitespace-nowrap">Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(slider, index) in sliders"
              :key="index"
              class="intro-x"
            >
              <td class="font-medium whitespace-nowrap">{{ slider.title }}
                >
                <div
                  class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                >
                  <span>{{ slider.hash_title }}</span>
                </div>
              </td>
              <td class="w-40">
                <div
                  class="flex items-center justify-center"
                  :class="{
                    'text-theme-9': !slider.deleted_at,
                    'text-theme-6': slider.deleted_at
                  }"
                >
                  <CheckSquareIcon class="w-4 h-4 mr-2" />
                  {{ slider.deleted_at ? 'Удален ' : 'Активен' }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link
                    :to="{
                      name: 'sliders-update',
                      params: { id: slider.id }
                    }"
                    class="flex items-center mr-3"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Изменить
                  </router-link>
                  <button
                    type="button"
                    class="flex items-center text-theme-6"
                    @click="$refs.deleteModal.openModal(slider.id)"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Удалить
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
    <AlertRemove ref="deleteModal" :onDelete="onDelete" />

  </div>
</template>

<script>
export default {
  data() {
    return {
      sliders: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$api.get(`/dashboard/group/${this.$route.params.id}/sliders`).then(response => {
        this.sliders = response
      })
    },
    onDelete(deletingItem) {
      this.$api.delete('/dashboard/sliders/' + deletingItem)
      this.init()
    }
  }
}
</script>
