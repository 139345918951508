<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Настройки изменить</h2>
      <LangSwitcher @changeLang="changeLang" />
    </div>
    <LangContent>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <form @submit.prevent="updateSetting">
          <!-- BEGIN: Form Layout -->
          <div class="intro-y box p-5">
            <div class="mt-2">
              <label for="crud-form-1" class="form-label">Значение</label>
              <input
                id="crud-form-1"
                type="text"
                v-model="setting.description[lang]"
                class="form-control w-full"
                placeholder="Введите значение настройки"
                required
              />
            </div>
            <div class="input-form mt-3">
              <label class="form-label">Описание настройки</label>
              <textarea class="form-control" v-model="setting.value[lang]"></textarea>
            </div>
            <div class="flex justify-between mt-5">
              <router-link to="/settings" class="btn btn-outline-secondary w-24 mr-1">
                Назад
              </router-link>
              <button type="submit" class="btn btn-primary w-24">Изменить</button>
            </div>
          </div>
          <!-- END: Form Layout -->
          </form>
        </div>
      </div>
    </LangContent>
  </div>
</template>

<script>
export default {
  data() {
    return {
      setting: {
        value: {},
        description: {}
      },
      lang: 'ru'
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    changeLang(lang) {
      this.lang = lang
    },
    init() {
      this.$api.get(`/dashboard/settings/${this.$route.params.id}`, { headers: { lang: 'mix' } }).then((result) => {
        this.setting.value = result.value ?? {}
        this.setting.description = result.description ?? {}
      })
    },
    updateSetting() {
      this.$api.put(`/dashboard/settings/${this.$route.params.id}`, this.setting).then((result) => {
        this.$router.push('/settings')
      })
    }
  }
}
</script>
