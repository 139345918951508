<template>
  <div class="tab-content mt-5">
    <Account :user="user"/>
    <Courses :user="user"/>
  </div>
</template>

<script>
import Account from '@/components/tab-content/Profile/Tabs/Account'
import Courses from '@/components/tab-content/Profile/Tabs/Courses'
export default {
  props: ['user'],
  components: {
    Account,
    Courses
  }
}
</script>
