<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-xl font-medium mr-auto">Просмотр обсуждения</h2>
      <button @click="$router.go(-1)" class="btn btn-primary w-24 mr-1">
        Назад
      </button>
    </div>
    <div class="mt-10">
      <div class="intro-y">
        <!-- BEGIN: Single Item -->
        <div class="col-span-12 lg:col-span-10 mt-5">
            <div class="intro-y news p-5 box">
              <!-- BEGIN: Blog Layout -->
              <div class="flex justify-between items-center">
                <div>
                  <h2 class="intro-y font-medium text-xl sm:text-2xl">
                    {{ discussion.title }}
                  </h2>
                  <div
                    class="intro-y text-gray-700 dark:text-gray-600 mt-3 text-xs sm:text-sm flex items-center"
                  >
                    <div
                      class="flex items-center rounded-md font-medium"
                      v-if="discussion.status"
                    >
                      <span
                        :class="[
                          { 'bg-theme-16': discussion.status.name == 'draft' },
                          { 'bg-theme-12': discussion.status.name == 'pending' },
                          { 'bg-theme-9': discussion.status.name == 'published' },
                          'w-2 h-2 rounded-full mr-3'
                        ]"></span>
                      {{ discussion.status.translate }}
                    </div>
                    <span class="mx-1">•</span>
                    {{ $h.formatDate(discussion.created_at, 'D MMMM YYYY') }}
                  </div>
                </div>
                <router-link
                  :to="{ name: 'course-discussions-update', params: discussion.slug }"
                  class="btn btn-warning"
                  >Изменить</router-link
                >
              </div>
              <!-- <div class="intro-y mt-6 mb-10">
                <div class="news__preview image-fit">
                  <img
                    alt="Smarthub"
                    class="rounded-md"
                    data-action="zoom"
                    :src="discussion.image"
                  />
                </div>
              </div> -->
              <div
                class="intro-y ck ck-content"
                v-html="discussion.description"
              ></div>
              <!-- END: Blog Layout -->
            </div>
          </div>
        <!-- END: Single Item -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      discussion: {}
    }
  },
  computed: {
    slug() {
      return this.$route.params.slug
    }
  },
  mounted() {
    this.getDiscussion()
  },
  methods: {
    async getDiscussion() {
      this.$api.get(`dashboard/course/${this.slug}/discussions/${this.$route.params.id}`).then(response => {
        this.discussion = response
      })
    }
  }
}
</script>
