<template>
  <div>
    <draggable :list="draggableList" @change="changePositionModule" itemKey="name">
      <template #item="{element, index}">
        <div class="border-2 p-2 rounded mt-2" :key="index">
          <div
            class="
              bg-gray-800
              text-white
              font-bold
              rounded
              cursor-pointer
              flex
              justify-between
            "
          >
            <div @click="isOpen = index" class="w-full p-4">
              {{ element.title }}
            </div>
            <div class="flex space-x-3 p-4">
              <button type="button" @click="toggleStatus('module', element.id)">
                <PocketIcon
                  v-if="element?.status?.name == 'published'"
                  class="text-theme-9"
                />
                <SlashIcon v-else />
              </button>
              <button type="button" @click="toggleModalOption(element.slug)">
                <PlusCircleIcon />
              </button>
              <div class="dropdown ml-auto">
                <a class="dropdown-toggle" href="javascript:;" aria-expanded="false">
                  <MoreVerticalIcon />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a href="javascript:;"
                      @click="$refs.updateModal.init(element)"
                      class="
                        flex
                        items-center
                        w-full
                        p-2
                        transition
                        duration-300
                        ease-in-out
                        bg-white
                        dark:bg-dark-1
                        hover:bg-gray-200
                        dark:hover:bg-dark-2
                        rounded-md
                      "
                    >
                      <CheckSquareIcon class="w-4 h-4 mr-2" /> Изменить
                    </a>
                    <a href="javascript:;"
                      @click="$refs.deleteModal.openModal(element?.slug, 'module')"
                      class="
                        flex
                        items-center
                        w-full
                        p-2
                        transition
                        duration-300
                        ease-in-out
                        bg-white
                        dark:bg-dark-1
                        hover:bg-gray-200
                        dark:hover:bg-dark-2
                        rounded-md
                      "
                    >
                      <TrashIcon class="w-4 h-4 mr-2" /> Удалить
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mt-4 border-2 p-2 rounded"
            v-if="isOpen == index"
          >
            <draggable
              ref="getData"
              :list="element?.options"
              @change="changePositionOption"
              itemKey="name"
              class="w-full grid divide-dark-5 divide-y divide-opacity-25"
            >
              <template #item="{ element }">
                <div class="flex w-full justify-between items-center py-2.5 px-2.5">
                  <div class="flex space-x-5 items-center">
                    <div class="md:w-6 w-40">
                      <router-link :to="{
                        name: `course-${element?.modulable?.module_type}s-update`,
                        params: { slug: course_slug, id: element?.modulable?.slug }
                      }">
                      <FileTextIcon v-if="element?.modulable.module_type == 'page'" />
                      <LifeBuoyIcon
                        v-if="element?.modulable.module_type == 'quize'"
                      />
                      <MessageCircleIcon
                        v-if="element?.modulable.module_type == 'discussion'"
                      />
                      <HardDriveIcon
                        v-if="element?.modulable.module_type == 'file'"
                      />
                      </router-link>
                    </div>
                    <div>
                      <router-link :to="{
                        name: `course-${element?.modulable?.module_type}s-update`,
                        params: { slug: course_slug, id: element?.modulable?.slug }
                      }">
                        <div class="font-medium whitespace-nowrap">
                          {{ element?.modulable.title }}
                        </div>
                        <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap capitalize">
                          {{ element?.modulable.module_type == 'quize' ? 'quiz' : element?.modulable.module_type}}
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="flex text-right font-medium space-x-3">
                    <button
                      type="button"
                      @click="
                        toggleStatus(
                          element?.modulable.module_type,
                          element?.modulable.id
                        )
                      "
                    >
                      <PocketIcon
                        v-if="element?.modulable?.status.name == 'published'"
                        class="text-theme-9"
                      />
                      <SlashIcon v-else />
                    </button>
                    <div class="dropdown ml-auto">
                      <a class="dropdown-toggle" href="javascript:;" aria-expanded="false">
                        <MoreVerticalIcon />
                      </a>
                      <div class="dropdown-menu w-40">
                        <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                          <button
                            @click="$refs.deleteModal.openModal(element.id, 'option')"
                            class="
                              flex
                              items-center
                              p-2
                              transition
                              duration-300
                              ease-in-out
                              bg-white
                              dark:bg-dark-1
                              hover:bg-gray-200
                              dark:hover:bg-dark-2
                              rounded-md
                            "
                          >
                            <TrashIcon class="w-4 h-4 mr-2" /> Удалить
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </template>
    </draggable>
    <AlertRemove ref="deleteModal" :onDelete="onDelete" />
    <UpdateModule ref="updateModal" />
  </div>
</template>

<style scoped>
.sortable-ghost {
  @apply opacity-30;
}
</style>

<script>
import draggable from 'vuedraggable'
import UpdateModule from '@/components/modals/Modules/Update'

export default {
  props: ['draggableList'],
  components: {
    UpdateModule,
    draggable
  },
  data() {
    return {
      isOpen: 0,
      activeNames: ''
    }
  },
  computed: {
    course_slug() {
      return this.$route.params.slug
    }
  },
  methods: {
    changePositionModule(evt) {
      this.$api
        .put(
          `dashboard/course/${this.$route.params.slug}/modules/${evt.moved.element.id}/position`,
          { position: evt.moved.newIndex }
        )
    },
    changePositionOption(evt) {
      this.$api
        .put(
          `dashboard/course/${this.$route.params.slug}/modules/position/options/${evt.moved.element.id}`,
          { position: evt.moved.newIndex }
        )
    },
    toggleModalOption(slug) {
      this.$store.commit('modules/SET_MODULE_SLUG', slug)
      cash('#create-options-modal').modal('show')
    },
    toggleStatus(type, id) {
      this.$api.put('dashboard/status', { type: type, id: id }).then(() => {
        this.$store.dispatch('modules/getModules', this.$route.params.slug)
      })
    },
    onDelete(deletingItem, type) {
      console.log(type)
      switch (type) {
        case 'module':
          this.$api.delete(`/dashboard/course/${this.$route.params.slug}/modules/` + deletingItem)
          this.$store.dispatch('modules/getModules', this.$route.params.slug)
          break
        case 'option':
          this.$api.delete(`/dashboard/course/${this.$route.params.slug}/option/` + deletingItem)
          this.$store.dispatch('modules/getModules', this.$route.params.slug)
          break
        default:
          break
      }
    }
  }
}
</script>
