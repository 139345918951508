<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 custom-index">
      <h2 class="text-lg font-medium mr-auto">Изменить возможность</h2>
      <LangSwitcher @changeLang="changeLang" />
    </div>
    <LangContent>
      <form @submit.prevent="save">
        <div class="post intro-y grid grid-cols-12 gap-5">
          <!-- BEGIN: Post Content -->
          <div class="intro-y col-span-12">
            <input
              type="text"
              v-model="title[lang]"
              class="intro-y form-control py-3 px-4 box pr-10 placeholder-theme-13"
              placeholder="Название возможности"
            />
            <div class="post intro-y overflow-hidden box mt-5">
              <div
                class="post__tabs nav nav-tabs flex-col sm:flex-row bg-gray-300 dark:bg-dark-2 text-gray-600"
                role="tablist"
              >
                <Tippy
                  id="content-tab"
                  tag="a"
                  content="Fill in the article content"
                  data-toggle="tab"
                  data-target="#content"
                  href="javascript:;"
                  class="w-full sm:w-40 py-4 text-center flex justify-center items-center active"
                  role="tab"
                  aria-controls="content"
                  aria-selected="true"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Контент
                </Tippy>
              </div>
              <div class="post__content tab-content">
                <div
                  id="content"
                  class="tab-pane p-5 active"
                  role="tabpanel"
                  aria-labelledby="content-tab"
                >
                  <div>
                    <label
                      class="font-medium flex items-center"
                    >Описание
                    </label>
                    <div class="mt-5">
                      <ClassicEditor v-model="description[lang]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Post Content -->
          <!-- BEGIN: Post Info -->
          <div class="col-span-12">
            <div class="intro-y box p-5">
              <div class="mt-3">
                <label class="form-label">Загрузите постер</label>
                <div
                  class="border-2 border-dashed dark:border-dark-5 rounded-md pt-4"
                >
                  <div v-if="poster" class="flex flex-wrap px-4">
                    <div class="w-24 h-24 relative image-fit mb-5 mr-5 cursor-pointer zoom-in">
                      <img
                        class="rounded-md"
                        alt="Smarthub"
                        :src="poster"
                      />
                      <Tippy
                        tag="div"
                        content="Удалить постер"
                        @click="removeImage()"
                        class="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 -mt-2"
                      >
                        <xIcon class="w-4 h-4" />
                      </Tippy>
                    </div>
                  </div>
                  <div
                    v-else
                    class="px-4 pb-4 flex items-center cursor-pointer relative">
                    <ImageIcon class="w-4 h-4 mr-2" />
                    <span class="text-theme-1 dark:text-theme-10 mr-1">Загрузить файл</span>
                      или перетащите
                    <input
                      type="file"
                      @change="onFileChange($event)"
                      class="w-full h-full top-0 left-0 absolute opacity-0"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              class="btn btn-primary shadow-md flex items-center mt-5 w-full"
              aria-expanded="false"
              type="submit">
              Сохранить
            </button>
          </div>
          <!-- END: Post Info -->
        </div>
      </form>
    </LangContent>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  data() {
    return {
      poster: '',
      title: {},
      description: {},
      form: { },
      lang: 'ru'
    }
  },
  mounted() {
    this.getPossibility()
  },
  methods: {
    changeLang(lang) {
      this.lang = lang
    },
    getPossibility() {
      this.$api.get('/dashboard/opportunities/' + this.$route.params.slug, { headers: { lang: 'mix' } }).then(response => {
        this.poster = response.thumbnail
        this.title = response.title ?? {}
        this.description = response.description ?? {}
      })
    },
    onFileChange(e) {
      this.form.poster = this.$h.readFile(e)
      this.createImage(this.form.poster)
    },
    createImage(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.poster = e.target.result
      }
      reader.readAsDataURL(file)
    },
    removeImage() {
      this.poster = ''
    },
    save() {
      const formData = new FormData()
      this.form.poster && formData.append('poster', this.form.poster)
      _.each(this.title, (value, key) => {
        formData.append(`title[${key}]`, value)
      })
      _.each(this.description, (value, key) => {
        formData.append(`description[${key}]`, value)
      })
      this.$api.post(`dashboard/opportunities/update/${this.$route.params.slug}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2)
        }
      }).then(response => {
        this.$router.push('/possibilities')
      })
    }
  }
}
</script>
