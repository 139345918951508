import ability from './defineAbility.js'
import store from '../../store'
const menu = []

if (store.state.auth.user && store.state.auth.user?.roles[0]?.name === 'administrator') {
  menu.push({
    icon: 'HomeIcon',
    pageName: 'dashboard',
    title: 'Главная'
  })
}

menu.push({
  icon: 'DatabaseIcon',
  pageName: 'transactions',
  title: 'Транзакции'
})

if (ability.can('course-update') || ability.can('course-read') || ability.can('course-delete') || ability.can('course-create')) {
  menu.push({
    icon: 'HardDriveIcon',
    pageName: 'file-manager',
    title: 'Медиа файлы'
  }, {
    icon: 'BookIcon',
    pageName: 'courses',
    title: 'Курсы'
  })
}
if (ability.can('users-update') || ability.can('users-read') || ability.can('users-delete') || ability.can('users-create')) {
  menu.push('devider', {
    icon: 'UsersIcon',
    pageName: 'users',
    title: 'Пользователи'
  })
  menu.push({
    icon: 'BriefcaseIcon',
    pageName: 'employees',
    title: 'Сотрудники'
  })
}

if (ability.can('opportunity-update') || ability.can('opportunity-read') || ability.can('opportunity-delete') || ability.can('opportunity-create')) {
  menu.push({
    icon: 'CpuIcon',
    pageName: 'possibilities',
    title: 'Возможности'
  })
}
if (ability.can('opportunity_application-update') || ability.can('opportunity_application-read') || ability.can('opportunity_application-delete') || ability.can('opportunity_application-create')) {
  menu.push('devider', {
    icon: 'PaperclipIcon',
    pageName: 'apply-possibilities',
    title: 'Заявки на возможности'
  })
}
if (ability.can('course-update') || ability.can('course-read') || ability.can('course-delete') || ability.can('course-create')) {
  menu.push({
    icon: 'PaperclipIcon',
    pageName: 'private-course-applications',
    title: 'Заявки на приватные курсы'
  })
}
if (ability.can('teacher_application-update') || ability.can('teacher_application-read') || ability.can('teacher_application-delete') || ability.can('teacher_application-create')) {
  menu.push({
    icon: 'PaperclipIcon',
    pageName: 'apply-teachers',
    title: 'Заявки на учителя'
  }, 'devider')
}
if (ability.can('page-update') || ability.can('page-read') || ability.can('page-delete') || ability.can('page-create')) {
  menu.push({
    icon: 'FileIcon',
    pageName: 'pages',
    title: 'Страницы'
  })
}
if (ability.can('slider-update') || ability.can('slider-read') || ability.can('slider-delete') || ability.can('slider-create')) {
  menu.push({
    icon: 'SlidersIcon',
    pageName: 'sliders',
    title: 'Слайдер'
  })
}

if (ability.can('category-update') || ability.can('category-read') || ability.can('category-delete') || ability.can('category-create')) {
  menu.push({
    icon: 'ListIcon',
    pageName: 'categories',
    title: 'Категории'
  })
}

if (ability.can('roles-update') || ability.can('roles-read') || ability.can('roles-delete') || ability.can('roles-create')) {
  menu.push({
    icon: 'UnlockIcon',
    pageName: 'permissions',
    title: 'Права и роли'
  })
}
if (ability.can('settings-update') || ability.can('settings-read') || ability.can('settings-delete') || ability.can('settings-create')) {
  menu.push({
    icon: 'SettingsIcon',
    pageName: 'settings',
    title: 'Настройки'
  })
}

store.commit('sideMenu/SET_MENU', menu)
export default ability
