<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Заявки на приватные курсы</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-6">
      <div class="col-span-12">
        <div v-if="courses.data" class="intro-y">
          <div v-for="(course, index) in courses.data" :key="index">
            <div v-for="(item, index) in course.students" :key="index" class="intro-y-2 col-span-12 md:col-span-6">
              <div class="box grid lg:grid-cols-3 grid-cols-1
                    p-5 border-b border-gray-200 dark:border-dark-5 justify-center items-center  justify-items-center md:justify-items-stretch">
                <div class="flex flex-col
                    lg:flex-row
                    items-center">
                  <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                    <img alt="Smarthub" class="rounded-full" :src="
                    item.image
                      ? item.image
                      : require('@/assets/images/profile-1.jpg')
                  "/>
                  </div>
                  <div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                    <router-link :to="{ name: 'users-show', params: { id: item.id } }" class="font-medium">
                      {{ item.fullname }}
                    </router-link>
                    <div class="text-gray-600 text-xs mt-0.5">
                      {{ item.email }}
                    </div>
                  </div>
                </div>
                <div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                  <router-link :to="{ name: 'courses-show', params: { slug: course.slug } }"
                               class="font-medium truncate inline-block">{{
                      course.title
                    }}
                  </router-link>
                  <div class="text-gray-600 text-xs mt-0.5">
                    {{ course.category }}
                  </div>
                </div>
                <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                  <button class="btn btn-success py-1 px-2 mr-2" v-if="!item.pivot.is_active"
                          @click="onAcceptStudent(item.id, course.slug)">
                    Принять
                  </button>
                  <button class="btn btn-danger py-1 px-2 mr-2"
                          @click="$refs.deleteModal.openModal(item.id, course.slug)">
                    <span v-if="!item.pivot.is_active">
                      Отклонить
                    </span>
                    <span v-else>Удалить</span>
                  </button>
                  <router-link :to="`/users/show/${item.id}`" class="btn btn-primary py-1 px-2 mr-2">Профиль
                  </router-link>
                </div>
              </div>
              <div class="box">
              </div>
            </div>
          </div>
        </div>
        <div v-else class="
            intro-y
            text-lg
            font-medium
            text-gray-600 text-center
            h-72
            flex
            items-center
            justify-center
          ">
          Извините ничего не найдено !
        </div>
        <!-- END: Users Layout -->
        <AlertRemove ref="deleteModal" :onDelete="onDelete"/>

        <Pagination v-if="courses" :per_page="courses.per_page" :total="courses.total"
                    :current-page="currentPage"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1')
    },
    courses() {
      return this.$store.state.courses.privateCourseApplications
    }
  },
  watch: {
    currentPage() {
      this.pagination(this.currentPage)
    }
  },
  mounted() {
    this.init(this.currentPage)
  },
  methods: {
    init(pages) {
      this.$store.dispatch('courses/getPrivateCourseApplications', pages)
    },
    pagination(page) {
      this.$store.dispatch('courses/getPrivateCourseApplications', {
        pages: page
      })
    },
    onAcceptStudent(userId, slug) {
      this.$api.put(`/dashboard/course/${slug}/accept-student`, {
        user_id: userId
      })
      this.init()
    },
    onDelete(deletingItem, slug) {
      this.$api.put(`/dashboard/course/${slug}/detach`, {
        user_id: deletingItem
      })
      this.init()
    }
  }
}
</script>
