<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Пользователи</h2>
    <div class="grid grid-cols-12 gap-6 mt-8">
      <!-- BEGIN: Users Layout -->
      <div
        v-for="(item, index) in users.data"
        :key="index"
        class="intro-y col-span-12 md:col-span-6"
      >
        <div class="box">
          <div
            class="flex flex-col lg:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
              <img
                alt="Smarthub"
                class="rounded-full"
                :src="
                  item.image
                    ? item.image
                    : require('@/assets/images/profile-1.jpg')
                "
              />
            </div>
            <div
              class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0"
            >
              <router-link
                :to="{ name: 'users-show', params: { id: item.id } }"
                class="font-medium"
                >{{ item.fullname }}</router-link
              >
              <div class="text-gray-600 text-xs mt-0.5">
                {{ item.email }}
              </div>
            </div>
            <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
              <button
                class="btn btn-danger py-1 px-2 mr-2"
                @click="$refs.deleteModal.openModal(item.id)"
              >
                Удалить
              </button>
              <router-link
                :to="`/users/show/${item.id}`"
                class="btn btn-primary py-1 px-2"
                >Профиль</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <!-- END: Users Layout -->
    </div>
    <Pagination
      :per_page="users.per_page"
      :total="users.total"
      :current-page="currentPage"
    />
    <AlertRemove ref="deleteModal" :onDelete="onDelete" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: []
    }
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1')
    }
  },
  watch: {
    currentPage() {
      this.init(this.currentPage)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$api
        .get('/dashboard/user', {
          params: { roles: ['student'], page: this.currentPage }
        })
        .then(response => {
          this.users = response
        })
    },
    onDelete(deletingItem) {
      this.$api.delete('/dashboard/user/' + deletingItem)
      this.init()
    }
  }
}
</script>
