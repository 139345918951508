<template>
    <div>
        <div class="intro-y flex items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Социальные сети</h2>
        </div>
        <div class="grid grid-cols-12 gap-6">
            <AsideProfile />
            <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
                <!-- BEGIN: Change Password -->
                <div class="intro-y box lg:mt-5">
                    <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mr-auto">Социальные сети</h2>
                    </div>
                    <div>
                        <div class="grid grid-cols-12 gap-6">
                            <div class="intro-y col-span-12 2xl:col-span-9">
                                <!-- BEGIN: Form Validation -->
                                <div class="intro-y box">
                                    <div id="form-validation" class="p-5">
                                        <div class="preview" v-if="form">
                                            <!-- BEGIN: Validation Form -->
                                            <form class="validate-form" @submit.prevent="save" v-if="links.length != 0">
                                                <div class="input-form mt-3" v-for="(item, index) in links"
                                                    :key="index">
                                                    <label for="validation-form-5"
                                                        class="form-label w-full flex flex-col sm:flex-row">
                                                        {{ item.name }}
                                                        <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">Необязательно, формат URL</span>
                                                    </label>
                                                    <input type="hidden" v-model="form[index].social_network_id">
                                                    <input id="validation-form-5" v-model.trim="form[index].link"
                                                        type="url" class="form-control" />
                                                </div>
                                                <button type="submit" class="btn btn-primary mt-5">
                                                    Сохранить
                                                </button>
                                            </form>
                                            <!-- END: Validation Form -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AsideProfile from '@/components/aside/Profile'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    AsideProfile
  },
  data() {
    return {
      links: [],
      form: null
    }
  },
  mounted() {
    this.getSocialLinks()
  },
  methods: {
    async getSocialLinks() {
      this.$api.get('auth/social').then(response => {
        this.links = response

        const data = []

        this.links.forEach(item => data.push({ social_network_id: item.id, link: item.link?.link }))

        this.form = data
      })
    },

    save() {
      this.$api.post('auth/social', { links: this.form.filter(i => i.link) }).then(response => {
        this.$router.push({ name: 'profile' })
      })
    }
  }
})
</script>
