/* eslint-disable dot-notation */
import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import VueAxios from 'vue-axios'
import axios from '@/plugins/axios'
import { abilitiesPlugin } from '@casl/vue'
import ability from './services/casl/ability'

import './libs'
// SASS Theme
import './assets/sass/app.scss'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(abilitiesPlugin, ability)

globalComponents(app)
utils(app)
app.config.globalProperties.$api = axios
store.$api = axios

app.mount('#app')
