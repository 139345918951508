<template>
  <!-- BEGIN: Pagination -->
  <div v-if="lastPage > 1" class="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-6">
    <ul class="pagination">
      <li v-if="showFirstPageLink">
        <router-link class="pagination__link" :to="{ path: url, query: { page: 1 } }">
          <ChevronsLeftIcon class="w-4 h-4" />
        </router-link>
      </li>
      <li v-if="showPrevPageLink">
        <router-link class="pagination__link" :to="{ path: url, query: { page: currentPage - 1 } }">
          <ChevronLeftIcon class="w-4 h-4" />
        </router-link>
      </li>
      <li v-for="page in visiblePages" :key="page">
        <router-link :class="[{ 'pagination__link--active': currentPage === page }, 'pagination__link']"
          :to="{ path: url, query: { page: page } }">{{ page }}</router-link>
      </li>
      <li v-if="showNextPageLink">
        <router-link class="pagination__link" :to="{ path: url, query: { page: currentPage + 1 } }">
          <ChevronRightIcon class="w-4 h-4" />
        </router-link>
      </li>
      <li v-if="showLastPageLink">
        <router-link class="pagination__link" :to="{ path: url, query: { page: lastPage } }">
          <ChevronsRightIcon class="w-4 h-4" />
        </router-link>
      </li>
    </ul>
  </div>
  <!-- END: Pagination -->
</template>
<script>
export default {
  props: {
    total: {
      type: Number,
      required: true,
      default: 1
    },
    per_page: {
      type: Number,
      required: true,
      default: 1
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    url() {
      return this.$route.path
    },
    lastPage() {
      return Math.ceil(this.total / this.per_page)
    },
    visiblePages() {
      const adjacentPages = 3
      let minPage = Math.max(this.currentPage - adjacentPages, 1)
      let maxPage = Math.min(this.currentPage + adjacentPages, this.lastPage)
      if (maxPage - minPage < adjacentPages * 2) {
        if (minPage === 1) {
          maxPage = Math.min(maxPage + adjacentPages * 2 - (maxPage - minPage + 1), this.lastPage)
        } else {
          minPage = Math.max(minPage - adjacentPages * 2 + (maxPage - minPage + 1), 1)
        }
      }
      return this.$_.range(minPage, maxPage + 1)
    },
    showFirstPageLink() {
      return this.currentPage > 1
    },
    showPrevPageLink() {
      return this.currentPage > 1
    },
    showNextPageLink() {
      return this.currentPage < this.lastPage
    },
    showLastPageLink() {
      return this.currentPage < this.lastPage
    }
  }
}
</script>
