<template>
  <div v-if="user">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Профиль пользователя</h2>
    </div>
    <!-- BEGIN: Profile Info -->
    <Navigation :info="user" />
    <!-- END: Profile Info -->
    <TabContent :user="user" />
  </div>
</template>

<script>
import Navigation from '@/components/top-bar/User'
import TabContent from '@/components/tab-content/Profile/Main'
export default {
  components: {
    Navigation,
    TabContent
  },
  data() {
    return {
      user: null
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$api
        .get(`/dashboard/user/${this.$route.params.id}`)
        .then((response) => {
          this.user = response
        })
      this.$api
        .get(`/dashboard/user/progress-course/${this.$route.params.id}`)
        .then((response) => {
          console.log(response)
        })
    }
  }
}
</script>
