<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
     <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto flex" @click="$router.go(-1)" v-if="$route.path != '/'">
      <ChevronLeftIcon class="breadcrumb__icon" />
      <button>Вернуться назад</button>
    </div>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <div class="intro-x relative mr-3 sm:mr-6">
      <div class="search hidden sm:block">
        <input
          type="text"
          class="search__input form-control border-transparent placeholder-theme-13"
          placeholder="Поиск ..."
          @input="searchResult"
          @focus="showSearchDropdown"
          @blur="hideSearchDropdown"
        />
        <SearchIcon class="search__icon dark:text-gray-300" />
      </div>
      <a class="notification sm:hidden" href="">
        <SearchIcon class="notification__icon dark:text-gray-300" />
      </a>
      <div class="search-result" :class="{ show: searchDropdown }">
        <div class="search-result__content">
          <div class="search-result__content__title">Страница</div>
          <div class="mb-5">
            <router-link :to="{name: 'file-manager'}" class="flex items-center">
              <div
                class="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full"
              >
                <InboxIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Файл менеджер</div>
            </router-link>
            <router-link :to="{name: 'users'}" class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-17 text-theme-11 flex items-center justify-center rounded-full"
              >
                <UsersIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Пользователи</div>
            </router-link>
            <router-link :to="{name: 'courses'}" class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full"
              >
                <CreditCardIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Курсы</div>
            </router-link>
          </div>
          <div class="search-result__content__title">Пользователи</div>
          <div class="mb-5">
            <div v-if="!$_.isEmpty(users)">
              <router-link
                v-for="(item, index) in $_.take(users, 4)"
                :key="index"
                :to="{name: 'users-show', params: { id: item.id } }"
                class="flex items-center mt-2"
              >
                <div class="w-8 h-8 image-fit">
                  <img
                    alt="Smarthub"
                    class="rounded-full"
                    :src="item.image ?? require(`@/assets/images/preview-15.jpg`)"
                  />
                </div>
                <div class="ml-3">{{ item.fullname }}</div>
                <div
                  class="ml-auto w-48 truncate text-gray-600 text-xs text-right"
                >
                  {{ item.email }}
                </div>
              </router-link>
            </div>
            <div v-else>
              <h3 class="ml-auto truncate text-gray-600 text-md text-center">Извените ничего не найдено</h3>
            </div>
          </div>
          <div class="search-result__content__title">Курсы</div>
          <div class="" v-if="!$_.isEmpty(courses)">
            <router-link
              v-for="(item, index) in $_.take(courses, 4)"
              :key="index"
              :to="{name: 'courses-show', params: {slug: item.slug}}"
              class="flex items-center mt-2"
            >
              <div class="w-8 h-8 image-fit">
                <img
                  alt="Smarthub"
                  class="rounded-full"
                  :src="item.image ?? require(`@/assets/images/preview-15.jpg`)"
                />
              </div>
              <div class="ml-3">{{ item.title }}</div>
              <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">
                {{ item?.category?.name }}
              </div>
            </router-link>
          </div>
          <div v-else>
            <h3 class="ml-auto truncate text-gray-600 text-md text-center">Извените ничего не найдено</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Search -->
    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown w-8 h-8">
      <div
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
        role="button"
        aria-expanded="false"
      >
        <img
          alt="Smarthub"
          :src="user.image ?? require(`@/assets/images/${$f()[9].photos[0]}`)"
        />
      </div>
      <div class="dropdown-menu w-56">
        <div
          class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white"
        >
          <div class="p-4 border-b border-theme-27 dark:border-dark-3">
            <div class="font-medium">{{ user.fullname }}</div>
            <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600">
              {{ user.teacher?.info ?? user.email  }}
            </div>
          </div>
          <div class="p-2">
            <router-link
              :to="{name: 'profile'}"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            >
              <UserIcon class="w-4 h-4 mr-2" /> Профиль
            </router-link>
            <router-link
              :to="{name: 'profile-change-password'}"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            >
              <LockIcon class="w-4 h-4 mr-2" /> Изменить пароль
            </router-link>
            <router-link
              :to="{name: 'profile-social-networks'}"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            >
              <RadioIcon class="w-4 h-4 mr-2" /> Cоц. сети
            </router-link>
          </div>
          <div class="p-2 border-t border-theme-27 dark:border-dark-3">
            <button
              type="button" @click="logout"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            >
              <ToggleRightIcon class="w-4 h-4 mr-2" /> Выйти
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import api from '@/plugins/axios'
import _ from 'lodash'

export default defineComponent({
  setup() {
    const store = useStore()

    const user = computed(() => {
      return store.state.auth.user
    })
    const searchDropdown = ref(false)
    const users = ref([])
    const courses = ref([])
    const searchResult = _.debounce((event) => {
      if (event.target.value.length >= 2) {
        api.get('/dashboard/search', { showLoader: false, params: { search: event.target.value } }).then((result) => {
          searchDropdown.value = true
          users.value = result.users
          courses.value = result.courses
        })
      } else {
        users.value = []
        courses.value = []
      }
    }, 500)
    const showSearchDropdown = () => {
      searchDropdown.value = true
    }

    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }

    return {
      user,
      users,
      courses,
      searchDropdown,
      searchResult,
      showSearchDropdown,
      hideSearchDropdown
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('user')
      location.href = '/'
    }
  }
})
</script>
