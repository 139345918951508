<template>
  <div>
    <div class="flex justify-between items-center my-10 intro-y">
      <h2 class="text-lg font-medium">Категории</h2>
      <router-link
        to="/categories/create"
        class="btn btn-primary shadow-md mr-2"
        >Добавить категорию</router-link
      >
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5 intro-y">
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Название</th>
              <th class="text-center whitespace-nowrap">
                Кол-во под-категории
              </th>
              <th class="text-center whitespace-nowrap">Статус</th>
              <th class="text-center whitespace-nowrap">Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(category, index) in categories"
              :key="index"
              class="intro-x"
            >
              <td>
                <router-link
                  :to="{
                    name: 'categories-show',
                    params: { slug: category.slug }
                  }"
                  class="font-medium whitespace-nowrap"
                  >{{ category.name }}</router-link
                >
                <div
                  class="text-gray-600 text-xs whitespace-nowrap mt-0.5 space-x-3"
                  v-if="category.sub_category.length > 0"
                >
                  <span
                    v-for="(item, index) in category.sub_category.slice(0, 3)"
                    :key="index"
                    >{{ item.name }}</span
                  >
                </div>
                <div
                  class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                  v-else
                >
                  <span>Под категории нет</span>
                </div>
              </td>
              <td class="text-center">{{ category.sub_category.length }}</td>
              <td class="w-40">
                <div
                  class="flex items-center justify-center"
                  :class="{
                    'text-theme-9': !category.deleted_at,
                    'text-theme-6': category.deleted_at
                  }"
                >
                  <CheckSquareIcon class="w-4 h-4 mr-2" />
                  {{ category.deleted_at ? 'Удален ' : 'Активен' }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link
                    :to="{
                      name: 'categories-update',
                      params: { slug: category.slug }
                    }"
                    class="flex items-center mr-3"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Изменить
                  </router-link>
                  <button
                    type="button"
                    class="flex items-center text-theme-6"
                    @click="$refs.deleteModal.openModal(category.slug)"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Удалить
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <AlertRemove ref="deleteModal" :onDelete="onDelete" />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      categories: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$api.get('/dashboard/category').then(response => {
        this.categories = response
      })
    },
    onDelete(deletingItem) {
      this.$api.delete('/dashboard/category/' + deletingItem)
      this.init()
    }
  }
}
</script>
