<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Профиль сотрудника</h2>
    </div>
    <!-- BEGIN: Profile Info -->
    <Navigation :info="user" />
    <!-- END: Profile Info -->
    <TabContent :user="user" />
  </div>
</template>

<script>
import Navigation from '@/components/top-bar/User'
import TabContent from '@/components/tab-content/Profile/Main'

export default {
  components: {
    Navigation,
    TabContent
  },
  data() {
    return {
      user: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$api.get(`/dashboard/user/${this.$route.params.id}`).then((response) => {
        this.user = response
      })
    }
  }
}
</script>
