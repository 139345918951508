<template>
  <div
    id="courses"
    class="tab-pane"
    role="tabpanel"
    aria-labelledby="courses-tab"
  >
    <!-- END: Profile Info -->
    <div class="grid grid-cols-12 gap-6 mt-8">
      <!-- BEGIN: Latest Tasks -->
      <div class="intro-y box col-span-12">
        <div
          class="
            flex
            items-center
            px-5
            py-5
            border-b border-gray-200
            dark:border-dark-5
          "
        >
          <h2 class="font-medium text-base mr-auto">Последние курсы</h2>
        </div>
        <div class="p-5 space-y-5">
          <div
            class="grid sm:grid-cols-3 gap-10"
            v-for="(course, index) in courses"
            :key="index"
          >
            <div class="border-l-2 border-theme-1 pl-4 col-span-2">
              <router-link
                :to="{ name: 'courses-show', params: { slug: course.slug } }"
                class="font-medium whitespace-nowrap"
                >{{ course.title }}</router-link
              >
              <div class="text-gray-600">Автор: {{ course.author_course }}</div>
            </div>

            <div class="w-full">
              <div class="flex">
                <div class="mr-auto">Прогресс курса</div>
                <div>{{ Math.round(course.progress) }} %</div>
              </div>
              <div class="progress h-1 mt-2">
                <div
                  :class="[course.progress < 45 ? 'bg-theme-1' : course.progress < 85 ? 'bg-theme-11' : 'bg-theme-9', 'progress-bar']"
                  :style="{width: Math.round(course.progress)+'%'}"
                  role="progressbar"
                  :aria-valuenow="Math.round(course.progress)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Latest Tasks -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['user'],
  computed: {
    courses() {
      return this.user.lastCourses
    }
  }
}
</script>
