<template>
  <div>
    <div class="flex justify-between items-center my-10 intro-y">
      <h2 class="text-lg font-medium">Страницы</h2>
      <router-link
        :to="{ name: 'pages-create' }"
        class="btn btn-primary shadow-md mr-2"
        >Добавить новую страницу</router-link
      >
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Страница</th>
              <th class="text-center whitespace-nowrap">Дата</th>
              <th class="text-center whitespace-nowrap">Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(page, index) in pages" :key="index" class="intro-x">
              <td class="w-60">
                <router-link
                  :to="{ name: 'pages-show', params: { slug: page.slug } }"
                  class="font-medium whitespace-nowrap"
                  >{{ page.title }}</router-link
                >
              </td>
              <td class="w-60">
                <div class="flex items-center justify-center">
                  <CalendarIcon class="w-4 h-4 mr-2" />
                  {{ $h.formatDate(page.created_at, 'D MMMM YYYY') }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link
                    :to="{ name: 'pages-update', params: { slug: page.slug } }"
                    class="flex items-center mr-3"
                    href="javascript:;"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Изменить
                  </router-link>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    @click="$refs.deleteModal.openModal(page.slug)"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Удалить
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <AlertRemove ref="deleteModal" :onDelete="onDelete" />
      <!-- END: Data List -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pages: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$api.get('/dashboard/pages').then(response => {
        this.pages = response
      })
    },
    onDelete(deletingItem) {
      this.$api.delete('/dashboard/pages/' + deletingItem)
      this.init()
    }
  }
}
</script>
