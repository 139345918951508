<template>
    <div>
        <NotificationsSuccess />
        <NotificationsError />
    </div>
</template>

<script>
import NotificationsSuccess from '@/components/modals/Notifications/Success'
import NotificationsError from '@/components/modals/Notifications/Error'

export default {
  components: {
    NotificationsSuccess,
    NotificationsError
  }
}
</script>
