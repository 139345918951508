import { defineAbility } from '@casl/ability'
import store from '../../store'
export default defineAbility((can, cannot) => {
  const user = store.state.auth.user
  if (user) {
    const permissions = user?.roles[0]?.permissions.map(el => el.name) ?? null
    if (permissions) {
      can(permissions)
    } else {
      alert('Вы являетесь обычным пользователем, это страничка для Учителей')
    }
  }
})
