<template>
    <div class="col-span-12 lg:col-span-3 2xl:col-span-2" v-if=course>
        <h2 class="intro-y text-lg font-medium mr-auto mt-2">{{ course.title }}</h2>
        <span class="intro-y text-gray-600" v-if="course.category"
            >Категория: {{ course.category.name }}</span
        >
        <!-- BEGIN: File Manager Menu -->
        <div class="intro-y box p-5 mt-6">
            <div class="mt-1">
            <router-link :to="{name:'courses-show', param: {slug: course.slug}}" exact-active-class="bg-theme-1 text-white font-medium"
                class="flex items-center px-3 py-2 rounded-md">
                <AlertOctagonIcon class="w-4 h-4 mr-2" /> О Курсе
            </router-link>
            <router-link :to="{name:'course-modules', param: {slug: course.slug}}" exact-active-class="bg-theme-1 text-white font-medium"
                class="flex items-center px-3 py-2 rounded-md">
                <LayersIcon class="w-4 h-4 mr-2" /> Модули
            </router-link>
            <router-link :to="{name: 'course-pages', param: {slug: course.slug}}" class="flex items-center px-3 py-2 mt-2 rounded-md" exact-active-class="bg-theme-1 text-white font-medium">
                <FileTextIcon class="w-4 h-4 mr-2" /> Страницы
            </router-link>
            <router-link :to="{name: 'course-quizes', param: {slug: course.slug}}" class="flex items-center px-3 py-2 mt-2 rounded-md" exact-active-class="bg-theme-1 text-white font-medium">
                <LifeBuoyIcon class="w-4 h-4 mr-2" /> Тесты
            </router-link>
            <router-link :to="{name: 'course-discussions', param: {slug: course.slug}}" href="" class="flex items-center px-3 py-2 mt-2 rounded-md" exact-active-class="bg-theme-1 text-white font-medium">
                <MessageCircleIcon class="w-4 h-4 mr-2" /> Обсуждения
            </router-link>
            <router-link :to="{name: 'course-users', param: {slug: course.slug}}" href="" class="flex items-center px-3 py-2 mt-2 rounded-md" exact-active-class="bg-theme-1 text-white font-medium">
                <UsersIcon class="w-4 h-4 mr-2" /> Студенты
            </router-link>
            </div>
            <div class="border-t border-gray-200 dark:border-dark-5 mt-4 pt-4">
                <div class="flex items-center px-3 py-2 rounded-md">
                    <div class="w-2 h-2 bg-theme-16 rounded-full mr-3"></div>
                    Черновик
                </div>
                <div class="flex items-center px-3 py-2 mt-2 rounded-md">
                    <div class="w-2 h-2 bg-theme-9 rounded-full mr-3"></div>
                    Опубликован
                </div>
            </div>
        </div>
        <!-- END: File Manager Menu -->
        </div>
</template>

<script>
export default {
  props: ['course']
}
</script>
