<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Добавьте медиафайлы</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Multiple File Upload -->
        <div class="intro-y box mt-5">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Выберите или перетащите свои файлы</h2>
            <div class="flex justify-between">
              <button @click="$router.go(-1)" class="btn btn-outline-secondary w-24 mr-2">
                Назад
              </button>
              <button type="button" @click="upload" class="btn btn-success w-auto mr-2">
                Загрузить все файлы
              </button>
            </div>
          </div>
          <div id="multiple-file-upload" class="p-5">
            <div class="preview">
              <Dropzone
                ref-key="dropzoneMultipleRef"
                :options="{
                  url: `${baseUrl}/dashboard/media`,
                  thumbnailWidth: 150,
                  maxFilesize: 9999999,
                  addRemoveLinks: true,
                  autoQueue: false,
                  dictRemoveFile: 'Удалить файл',
                  dictCancelUpload: 'Отменить загрузку',
                  dictCancelUploadConfirmation: 'Вы действительно хотите отменить загрузку?',
                  dictUploadCanceled: 'Загрузка отменена.'
                }"
                class="dropzone"
              >
                <div class="text-lg font-medium">
                  Перетащите файлы сюда или нажмите, чтобы загрузить.
                </div>
                <div class="text-gray-600">
                  Это всего лишь демонстрационная дроп-зона. Выбранные файлы
                  <span class="font-medium">фактически</span>  не загружаются.
                </div>
              </Dropzone>
            </div>
          </div>
        </div>
        <!-- END: Multiple File Upload -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, provide, onMounted } from 'vue'

export default defineComponent({
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BASE_URL_API
    }
  },
  setup() {
    const dropzoneMultipleRef = ref()

    provide('bind[dropzoneMultipleRef]', el => {
      dropzoneMultipleRef.value = el
    })
    onMounted(() => {
      const elDropzoneMultipleRef = dropzoneMultipleRef.value
      elDropzoneMultipleRef.dropzone.on('success', (file, response) => {
        elDropzoneMultipleRef.dropzone.removeFile(file)
      })
      // elDropzoneMultipleRef.dropzone.on('error', () => {
      //   // alert('No more files please!')
      // })
    })
    return { dropzoneMultipleRef }
  },
  methods: {
    upload() {
      const myDropzone = this.dropzoneMultipleRef.dropzone
      myDropzone.enqueueFiles(myDropzone.getFilesWithStatus('added'))
    }
  }
})
</script>
