<template>
    <div>
        <div class="intro-y flex items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Добавить пользователя</h2>
        </div>
        <form @submit.prevent="save">
          <div class="intro-y grid grid-cols-12 gap-5 mt-8">
            <div class="intro-y col-span-12 lg:col-span-8">
              <!-- BEGIN: Display Information -->
              <div class="intro-y box">
                  <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Персональная информация</h2>
                  </div>
                  <div class="p-5">
                      <div class="flex xl:flex-row flex-col">
                          <div class="flex-1">
                              <div class="grid grid-cols-12 gap-x-5">
                                  <div class="mt-2 col-span-12 2xl:col-span-6">
                                      <label for="create-employee-fisrtname" class="form-label">Имя</label>
                                      <input id="create-employee-fisrtname" type="text" class="form-control" maxlength="14" required
                                          placeholder="Введите Имя" v-model="form.first_name" />
                                  </div>
                                  <div class="mt-2 col-span-12 2xl:col-span-6">
                                      <label for="create-employee-lastname" class="form-label">Фамилия</label>
                                      <input id="create-employee-lastname" type="text" class="form-control" maxlength="14" required
                                          placeholder="Введите Фамилию" v-model="form.last_name" />
                                  </div>
                                  <div class="mt-3 col-span-12 2xl:col-span-6">
                                      <label for="create-employee-email" class="form-label">E-mail</label>
                                      <input id="create-employee-email" type="email" class="form-control"
                                          placeholder="Введите email" required v-model="form.email" />
                                  </div>
                                  <div class="mt-3 col-span-12 2xl:col-span-6">
                                      <label for="create-employee-password" class="form-label">Пароль</label>
                                      <input id="create-employee-password" type="password" class="form-control"
                                          placeholder="Введите номер телефона" maxlength="18" minlength="6" v-model="form.password" required />
                                  </div>
                                  <div class="mt-3 col-span-12 2xl:col-span-9">
                                      <label for="create-employee-nickname" class="form-label">Ник</label>
                                      <input id="create-employee-nickname" type="text" class="form-control"
                                          placeholder="Введите Ник" required v-model="form.nick_name" />
                                  </div>
                                  <div class="mt-3 col-span-12">
                                      <label for="create-employee-addres" class="form-label">Адрес</label>
                                      <input id="create-employee-addres" class="form-control" maxlength="64"
                                          placeholder="Введите адрес" v-model="form.address">
                                  </div>
                                  <div class="col-span-12 2xl:col-span-7 alert alert-secondary show mb-2 mt-5" role="alert">
                                    <div class="flex items-center">
                                        <div class="font-medium text-lg">Придупреждение ознакомьтесь</div>
                                        <div class="text-xs bg-gray-800 px-1.5 rounded-md text-white ml-auto">Обязятельно</div>
                                    </div>
                                    <div class="mt-3">Чтобы статитиска работала коректно желательно заполнить <br> все поля которые выши прописаны</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <!-- BEGIN: Post Info -->
            <div class="col-span-12 lg:col-span-4">
                <div class="intro-y box p-5">
                    <div class="mt-3">
                      <label class="form-label">Загрузите аватарку</label>
                      <div class="border-2 border-dashed dark:border-dark-5 rounded-md pt-4">
                          <div class="flex flex-wrap px-4">
                              <div v-if="avatar"
                                  class="w-24 h-24 relative image-fit mb-5 mr-5 cursor-pointer zoom-in">
                                  <img class="rounded-md" alt="Smarthub"
                                      :src="avatar" />
                                  <Tippy tag="div" content="Удалить постер" @click="removeImage(1)"
                                      class="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 -mt-2">
                                      <xIcon class="w-4 h-4" />
                                  </Tippy>
                              </div>
                          </div>
                          <div class="px-4 pb-4 flex items-center cursor-pointer relative">
                              <ImageIcon class="w-4 h-4 mr-2" />
                              <span class="text-theme-1 dark:text-theme-10 mr-1">Загрузить файл</span>
                              или перетащите
                              <input type="file" @change="onFileChange($event, 1)"
                                  class="w-full h-full top-0 left-0 absolute opacity-0" />
                          </div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <label for="create-employee-phone" class="form-label">Номер телефона</label>
                      <input id="create-employee-phone" type="tel" class="form-control"
                          placeholder="Введите номер телефона" maxlength="9" minlength="9" v-model="form.phone" required />
                    </div>
                    <div class="mt-3">
                      <label class="form-label">Пол</label>
                      <TomSelect v-model="form.gender" required
                          class="w-full">
                          <option value="M">Мужской</option>
                          <option value="W">Женский</option>
                      </TomSelect>
                    </div>
                    <div class="mt-3">
                        <label for="create-employee-age" class="form-label">Возраст</label>
                        <input id="create-employee-age" type="number" class="form-control" required maxlength="2" minlength="1"
                            placeholder="Введите возраст" v-model="form.age" />
                    </div>
                    <div class="mt-3">
                        <label class="form-label">Роль</label>
                        <TomSelect v-model="form.role" class="w-full" required>
                            <option :value="role.name" v-for="role in roles" :key="role.id">
                                {{ role.name }}</option>
                        </TomSelect>
                    </div>
                </div>
                <button class="btn btn-primary shadow-md flex items-center mt-5 w-full"
                    type="submit">
                    Добавить
                </button>
            </div>
            <!-- END: Post Info -->
          </div>
        </form>
    </div>
</template>

<script>
import _ from 'lodash'
import { computed, defineComponent, reactive, ref, onMounted } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()

    const roles = computed(() => store.state.roles.roles ?? [])
    const form = reactive({
      fullname: ref(''),
      gender: ref('M'),
      nick_name: ref(''),
      email: ref(''),
      password: ref(''),
      phone: ref(''),
      avatar: ref(''),
      age: ref(),
      address: ref(''),
      role: ref(''),
      first_name: ref(''),
      last_name: ref('')
    })
    const avatar = ref('')

    onMounted(() => {
      if (roles.value.length === 0) {
        store.dispatch('roles/getRoles')
      }
    })

    return {
      form,
      avatar,
      roles
    }
  },
  methods: {
    onFileChange(e) {
      this.form.avatar = this.$h.readFile(e)
      this.createImage(this.form.avatar)
    },
    createImage(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.avatar = e.target.result
      }
      reader.readAsDataURL(file)
    },
    removeImage() {
      this.avatar = ''
      this.form.avatar = ''
    },
    save() {
      this.form.fullname = this.form.first_name + ' ' + this.form.last_name
      const formData = new FormData()
      _.each(this.form, (value, key) => {
        formData.append(key, value)
      })

      this.$api.post('dashboard/user', formData).then((result) => {
        this.$router.push({ name: 'employees' })
      })
    }
  }
})
</script>
