<template>
  <div>
    <div class="intro-y news xl:w-3/5 p-5 box mt-8">
      <!-- BEGIN: Blog Layout -->
      <h2 class="intro-y font-medium text-xl sm:text-2xl">
        {{ page.title }}
      </h2>
      <div
        class="intro-y text-gray-700 dark:text-gray-600 mt-3 text-xs sm:text-sm"
      >
        {{ $h.formatDate(page.created_at, 'D MMMM YYYY') }} <span class="mx-1">•</span>
      </div>
      <article class="w-full pt-16 sm:pt-6 pb-6 prose max-w-none prose-slate prose prose-img:rounded-xl prose-headings:underline prose-a:text-blue-600" v-html="page.description">
      </article>
    </div>
  </div>
</template>

<script>
export default ({
  data() {
    return {
      page: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$api.get(`/dashboard/pages/${this.$route.params.slug}`).then((response) => {
        this.page = response
      })
    }
  }
})
</script>
