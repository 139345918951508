import axios from '@/plugins/axios'
import token from './header'

// const API_URL = 'http://localhost:8080/api/test/'

class UserService {
  getPublicContent() {
    return axios.get('/auth/me')
  }

  getUserBoard() {
    return axios.get('/auth/me', {
      headers: {
        Authorization: token
      }
    })
  }

  getModeratorBoard() {
    return axios.get('/auth/me', {
      headers: {
        Authorization: token
      }
    })
  }

  getAdminBoard() {
    return axios.get('/auth/me')
  }
}

export default new UserService()
