<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Изменение категории</h2>
      <LangSwitcher @changeLang="changeLang" />
    </div>
    <LangContent>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <!-- BEGIN: Form Layout -->
          <form @submit.prevent="updateCategory">
          <div class="intro-y box p-5">
            <div>
              <label for="crud-form-1" class="form-label">Название категории</label>
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full"
                placeholder="Введите название"
                required
                v-model="category.name[lang]"
              />
            </div>
            <div class="mt-3">
              <label for="crud-form-2" class="form-label">Выберите категорию</label>
              <TomSelect
                id="crud-form-2"
                v-model="category.parent_id"
                class="w-full"
              >
                <option>Сделать родительской</option>
                <option :value="item.id" v-for="(item, index) in categories.filter(el => el.id != category.id)" :key="index" :selected="category.parent_id == item.id ? true : false">{{ item.name }}</option>
              </TomSelect>
            </div>
            <div class="flex justify-between mt-5">
              <router-link to="/categories" class="btn btn-outline-secondary w-24 mr-1">
                Назад
              </router-link>
              <button type="submit" class="btn btn-primary w-24">Изменить</button>
            </div>
          </div></form>
          <!-- END: Form Layout -->
        </div>
      </div>
    </LangContent>
  </div>
</template>

<script>
export default {
  data() {
    return {
      category: {
        name: {},
        parent_id: ''
      },
      categories: [],
      lang: 'ru'
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    changeLang(lang) {
      this.lang = lang
    },
    async init() {
      await this.$api.get('/dashboard/category').then((response) => {
        this.categories = response
      })
      await this.$api.get(`/dashboard/category/${this.$route.params.slug}`, { headers: { lang: 'mix' } }).then((response) => {
        this.category = {
          name: { ru: response.name },
          parent_id: response.parent_id
        }
      })
    },
    updateCategory() {
      this.$api.put(`/dashboard/category/${this.$route.params.slug}`, this.category).then((result) => {
        this.$router.push('/categories')
      })
    }
  }
}
</script>
