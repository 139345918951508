<template>
  <div class="grid grid-cols-12 gap-5 mt-8" v-if="posibility">
    <div class="intro-y col-span-12 flex flex-col sm:flex-row items-center">
      <h2 class="text-lg font-medium mr-auto">Просмот возможности</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          :to="{ name: 'possibilities-applications', params: { slug: posibility.slug } }"
          class="btn btn-warning">Все заявки</router-link>
      </div>
    </div>
    <div class="intro-y news col-span-12 lg:col-span-8 p-5 box">
      <!-- BEGIN: Blog Layout -->
        <h2 class="intro-y font-medium text-xl sm:text-2xl">
          {{ posibility.title }}
        </h2>
        <div
          class="intro-y text-gray-700 dark:text-gray-600 mt-3 text-xs sm:text-sm"
        >
          {{ $h.formatDate(posibility.created_at, 'D MMMM YYYY') }} <span class="mx-1">•</span>
          <a class="text-theme-1 dark:text-theme-10" href="">Возможности</a>
        </div>
      <div class="intro-y mt-6">
        <div class="news__preview image-fit">
          <img
            alt="Smarthub"
            class="rounded-md"
            :src="posibility.image ? posibility.image : require(`@/assets/images/${$f()[0].images[0]}`)"
          />
        </div>
      </div>
      <div class="intro-y text-justify leading-relaxed pt-16 sm:pt-6 pb-6 prose max-w-none prose-slate prose prose-img:rounded-xl prose-headings:underline prose-a:text-blue-600" v-html="posibility.description">
      </div>
      <div
        class="intro-y flex text-xs sm:text-sm flex-col sm:flex-row items-center mt-5 pt-5 border-t border-gray-200 dark:border-dark-5 mb-8"
      >
        <div class="flex items-center">
          <div class="w-12 h-12 flex-none image-fit">
            <img
              alt="Smarthub"
              class="rounded-full"
              :src="posibility.author?.image ?? require(`@/assets/images/${$f()[0].photos[0]}`)"
            />
          </div>
          <div class="ml-3 mr-auto">
            <a href="" class="font-medium">{{ posibility.author?.fullname }}</a
            >, Автор
            <div class="text-gray-600">{{ posibility.author?.email }}</div>
          </div>
        </div>
      </div>
      <!-- END: Blog Layout -->
    </div>
    <div class="intro-y col-span-12 lg:col-span-4 box p-5">
      <div class="flex items-center justify-between border-b border-slate-200 dark:border-darkmode-400 pb-5">
          <div>
              <div class="text-slate-500">Заявок в ожидании</div>
              <div class="mt-1">{{ posibility.pending_applications_count }} шт</div>
          </div>
          <MailIcon class="w-4 h-4 text-slate-500 ml-aut" />
      </div>
      <div class="flex items-center justify-between border-b border-slate-200 dark:border-darkmode-400 py-5">
          <div>
              <div class="text-slate-500">Заявок на сегодня</div>
              <div class="mt-1">{{ posibility.today_pending_applications_count }} шт</div>
          </div>
          <AlertCircleIcon class="w-4 h-4 text-slate-500 ml-aut" />
      </div>
      <div class="flex items-center justify-between border-b border-slate-200 dark:border-darkmode-400 py-5">
          <div>
              <div class="text-slate-500">Просмотренно заявок</div>
              <div class="mt-1">{{ posibility.active_applications_count }} шт</div>
          </div>
          <CheckCircleIcon class="w-4 h-4 text-slate-500 ml-aut" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      posibility: null
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$api.get(`/dashboard/opportunities/${this.$route.params.slug}`).then((response) => {
        this.posibility = response
      })
    }
  }
})
</script>
