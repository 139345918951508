import {
  createRouter,
  createWebHistory
} from 'vue-router'
import SideMenu from '../layouts/side-menu/Main.vue'
import Main from '../pages/Main.vue'
import Transactions from '../pages/transactions/Main.vue'
//  -----
// # Possibilities
import Possibilities from '../pages/possibilities/Main.vue'
import PossibilitiesShow from '../pages/possibilities/Show.vue'
import PossibilitiesCreate from '../pages/possibilities/Create.vue'
import PossibilitiesUpdate from '../pages/possibilities/Update.vue'
// Categories
import Categories from '../pages/categories/Main.vue'
import CategoryShow from '../pages/categories/Show.vue'
import CategoryCreate from '../pages/categories/Create.vue'
import CategoryUpdate from '../pages/categories/Update.vue'
// Categories
import PermissionsAccess from '../pages/permissions/Main.vue'
import PermissionShow from '../pages/permissions/Show.vue'
import PermissionUpdate from '../pages/permissions/Update.vue'
// # Users
import Users from '../pages/users/Main.vue'
import UsersShow from '../pages/users/Show.vue'
import UsersUpdate from '../pages/users/Update.vue'

// # Apply Possibilities
import ApplyPossibilitiesAll from '../pages/apply/possibilities/Main.vue'
import ApplyPossibilities from '../pages/possibilities/applications/Main.vue'
import ApplyPossibilitiesShow from '../pages/possibilities/applications/Show.vue'

// # Apply Teachers
import ApplyTeachers from '../pages/apply/teachers/Main.vue'
import ApplyTeachersShow from '../pages/apply/teachers/Show.vue'

// # Sliders
import Sliders from '../pages/sliders/Main.vue'
import SlidersCreate from '../pages/sliders/Create.vue'
import SlidersShow from '../pages/sliders/Show.vue'
import SlidersUpdate from '../pages/sliders/Update.vue'
// # Courses
import Courses from '../pages/courses/Main.vue'
import CourseCreate from '../pages/courses/Create.vue'
import CourseShow from '../pages/courses/Show.vue'
import CourseUpdate from '../pages/courses/Update.vue'
// # Courses Pages
import CoursesPages from '../pages/courses/pages/Main.vue'
import CoursePagesCreate from '../pages/courses/pages/Create.vue'
import CoursePagesShow from '../pages/courses/pages/Show.vue'
import CoursePagesUpdate from '../pages/courses/pages/Update.vue'
// # Courses Modules
import CourseModules from '../pages/courses/modules/Main.vue'
// # Courses Users
import CourseUsers from '../pages/courses/users/Main.vue'
import CourseUsersCreate from '../pages/courses/users/Create.vue'
import CourseUsersShow from '../pages/courses/users/Show.vue'
import CourseUsersUpdate from '../pages/courses/users/Update.vue'
// # Courses Discussion
import CourseDiscussions from '../pages/courses/discussions/Main.vue'
import CourseDiscussionsCreate from '../pages/courses/discussions/Create.vue'
import CourseDiscussionsShow from '../pages/courses/discussions/Show.vue'
import CourseDiscussionsUpdate from '../pages/courses/discussions/Update.vue'
// # Courses Quizes
import CourseQuizes from '../pages/courses/quizes/Main.vue'
import CourseQuizesCreate from '../pages/courses/quizes/Create.vue'
import CourseQuizesShow from '../pages/courses/quizes/Show.vue'
import CourseQuizesUpdate from '../pages/courses/quizes/Update.vue'
// # Private Course Applications
import PrivateCourseApplications from '../pages/apply/courses/private/Main.vue'
// # FileManager
import FileManager from '../pages/file-manager/Main.vue'
import FileManagerCreate from '../pages/file-manager/Create.vue'
// # Employees
import Employees from '../pages/employees/Main.vue'
import EmployeesCreate from '../pages/employees/Create.vue'
import EmployeesShow from '../pages/employees/Show.vue'
import EmployeesUpdate from '../pages/employees/Update.vue'
// # Error page not found 404
import ErrorPage from '../pages/error-page/Main.vue'
// # Settings
import Settings from '../pages/settings/Main.vue'
import SettingsShow from '../pages/settings/Show.vue'
import SettingsUpdate from '../pages/settings/Update.vue'
// Pages
import Pages from '../pages/pages/Main.vue'
import PageShow from '../pages/pages/Show.vue'
import PageCreate from '../pages/pages/Create.vue'
import PageUpdate from '../pages/pages/Update.vue'
// Profile
import Profile from '../pages/profile/Main.vue'
import ProfileSettings from '../pages/profile/Settings.vue'
import ProfileChangePassword from '../pages/profile/ChangePassword.vue'
import ProfileSocialNetworks from '../pages/profile/SocialNetworks.vue'
// # Auth Login && Register
import Login from '../pages/auth/Login.vue'
import VerifyEmail from '../pages/auth/VerifyEmail.vue'
import ResetPassword from '../pages/auth/ResetPassword.vue'

const routes = [{
  path: '/',
  component: SideMenu,
  children: [
    {
      path: '/',
      name: 'dashboard',
      component: Main,
      meta: {
        title: 'Главная'
      }
    },
    {
      path: '/transactions',
      name: 'transactions',
      component: Transactions,
      meta: {
        title: 'Транзакции'
      }
    },
    {
      path: 'settings',
      name: 'settings',
      component: Settings,
      meta: {
        title: 'Настройки'
      }
    },
    {
      path: 'private-course-applications',
      name: 'private-course-applications',
      component: PrivateCourseApplications,
      meta: {
        title: 'Заявки на приватный курс'
      }
    },
    {
      path: 'settings/show/:id',
      name: 'settings-show',
      component: SettingsShow,
      meta: {
        title: 'Просмотр настройки'
      }
    },
    {
      path: 'settings/update/:id',
      name: 'settings-update',
      component: SettingsUpdate,
      meta: {
        title: 'Редактирование настройки'
      }
    },
    {
      path: 'file-manager',
      name: 'file-manager',
      component: FileManager,
      meta: {
        title: 'Медиа файлы'
      }
    },
    {
      path: 'file-manager/create',
      name: 'file-manager-create',
      component: FileManagerCreate,
      meta: {
        title: 'Добавление медиа'
      }
    },
    {
      path: 'employees',
      name: 'employees',
      component: Employees,
      meta: {
        title: 'Сотрудники'
      }
    },
    {
      path: 'employees/show/:id',
      name: 'employees-show',
      component: EmployeesShow,
      meta: {
        title: 'Просмотр сотрудника'
      }
    },
    {
      path: 'employees/update/:id',
      name: 'employees-update',
      component: EmployeesUpdate,
      meta: {
        title: 'Редактирование сотрудника'
      }
    },
    {
      path: 'employees/create',
      name: 'employees-create',
      component: EmployeesCreate,
      meta: {
        title: 'Добавление сотрудника'
      }
    },
    {
      path: 'possibilities',
      name: 'possibilities',
      component: Possibilities,
      meta: {
        title: 'Возможности'
      }
    },
    {
      path: 'possibilities/show/:slug',
      name: 'possibilities-show',
      component: PossibilitiesShow,
      meta: {
        title: 'Просмотр возможности'
      }
    },
    {
      path: 'possibilities/update/:slug',
      name: 'possibilities-update',
      component: PossibilitiesUpdate,
      meta: {
        title: 'Редактирование возможности'
      }
    },
    {
      path: 'possibilities/create',
      name: 'possibilities-create',
      component: PossibilitiesCreate,
      meta: {
        title: 'Добавление возможности'
      }
    },
    {
      path: 'possibilities/:slug/applications',
      name: 'possibilities-applications',
      component: ApplyPossibilities,
      meta: {
        title: 'Заявки на возможности'
      }
    },
    {
      path: 'possibilities/:slug/applications/show/:id',
      name: 'possibilities-applications-show',
      component: ApplyPossibilitiesShow,
      meta: {
        title: 'Просмотр заявки на возможности'
      }
    },
    {
      path: 'sliders',
      name: 'sliders',
      component: Sliders,
      meta: {
        title: 'Слайдеры'
      }
    },
    {
      path: 'sliders/:id/create',
      name: 'sliders-create',
      component: SlidersCreate,
      meta: {
        title: 'Добавление слайдера'
      }
    },
    {
      path: 'sliders/show/:id',
      name: 'sliders-show',
      component: SlidersShow,
      meta: {
        title: 'Просмотр слайдера'
      }
    },
    {
      path: 'sliders/update/:id',
      name: 'sliders-update',
      component: SlidersUpdate,
      meta: {
        title: 'Редактирование слайдера'
      }
    },
    {
      path: 'users',
      name: 'users',
      component: Users,
      meta: {
        title: 'Студенты'
      }
    },
    {
      path: 'users/show/:id',
      name: 'users-show',
      component: UsersShow,
      meta: {
        title: 'Просмотр студента'
      }
    },
    {
      path: 'users/update/:id',
      name: 'users-update',
      component: UsersUpdate,
      meta: {
        title: 'Редактирование студента'
      }
    },
    {
      path: 'apply-possibilities',
      name: 'apply-possibilities',
      component: ApplyPossibilitiesAll,
      meta: {
        title: 'Заявки на возможности'
      }
    },
    {
      path: 'apply-teachers',
      name: 'apply-teachers',
      component: ApplyTeachers,
      meta: {
        title: 'Заявки на учителя'
      }
    },
    {
      path: 'apply-teachers/show/:id',
      name: 'apply-teachers-show',
      component: ApplyTeachersShow,
      meta: {
        title: 'Просмотр заявки на учителя'
      }
    },
    {
      path: 'categories',
      name: 'categories',
      component: Categories,
      meta: {
        title: 'Категории'
      }
    },
    {
      path: 'categories/create',
      name: 'categories-create',
      component: CategoryCreate,
      meta: {
        title: 'Добавление категории'
      }
    },
    {
      path: 'categories/show/:slug',
      name: 'categories-show',
      component: CategoryShow,
      meta: {
        title: 'Просмотр категории'
      }
    },
    {
      path: 'categories/update/:slug',
      name: 'categories-update',
      component: CategoryUpdate,
      meta: {
        title: 'Редактирование категории'
      }
    },
    {
      path: 'permissions',
      name: 'permissions',
      component: PermissionsAccess,
      meta: {
        title: 'Роли и права'
      }
    },
    {
      path: 'permissions/show/:id',
      name: 'permissions-show',
      component: PermissionShow,
      meta: {
        title: 'Просмотр ролей и прав'
      }
    },
    {
      path: 'permissions/update/:id',
      name: 'permissions-update',
      component: PermissionUpdate,
      meta: {
        title: 'Редактирование ролей и прав'
      }
    },
    {
      path: 'courses',
      name: 'courses',
      component: Courses,
      meta: {
        title: 'Курсы'
      }
    },
    {
      path: 'courses/create',
      name: 'courses-create',
      component: CourseCreate,
      meta: {
        title: 'Добавление курса'
      }
    },
    {
      path: 'courses/:slug/show',
      name: 'courses-show',
      component: CourseShow,
      meta: {
        title: 'Просмотр курса'
      }
    },
    {
      path: 'courses/:slug/update',
      name: 'courses-update',
      component: CourseUpdate,
      meta: {
        title: 'Редактирование курса'
      }
    },
    {
      path: 'courses/:slug/pages',
      name: 'course-pages',
      component: CoursesPages,
      meta: {
        title: 'Страницы'
      }
    },
    {
      path: 'courses/:slug/pages/:id/show',
      name: 'course-pages-show',
      component: CoursePagesShow,
      meta: {
        title: 'Просмотр страниц'
      }
    },
    {
      path: 'courses/:slug/pages/:id/update',
      name: 'course-pages-update',
      component: CoursePagesUpdate,
      meta: {
        title: 'Редактирование страниц'
      }
    },
    {
      path: 'courses/:slug/pages/create',
      name: 'course-pages-create',
      component: CoursePagesCreate,
      meta: {
        title: 'Добавление страниц'
      }
    },
    {
      path: 'courses/:slug/quizes',
      name: 'course-quizes',
      component: CourseQuizes,
      meta: {
        title: 'Тесты'
      }
    },
    {
      path: 'courses/:slug/quizes/:id/show',
      name: 'course-quizes-show',
      component: CourseQuizesShow,
      meta: {
        title: 'Просмотр теста'
      }
    },
    {
      path: 'courses/:slug/quizes/:id/update',
      name: 'course-quizes-update',
      component: CourseQuizesUpdate,
      meta: {
        title: 'Редактирование теста'
      }
    },
    {
      path: 'courses/:slug/quizes/create',
      name: 'course-quizes-create',
      component: CourseQuizesCreate,
      meta: {
        title: 'Добавление теста'
      }
    },
    {
      path: 'courses/:slug/discussions',
      name: 'course-discussions',
      component: CourseDiscussions,
      meta: {
        title: 'Обсуждения'
      }
    },
    {
      path: 'courses/:slug/discussions/:id/show',
      name: 'course-discussions-show',
      component: CourseDiscussionsShow,
      meta: {
        title: 'Просмотр обсужения'
      }
    },
    {
      path: 'courses/:slug/discussions/:id/update',
      name: 'course-discussions-update',
      component: CourseDiscussionsUpdate,
      meta: {
        title: 'Редактировать обсуждение'
      }
    },
    {
      path: 'courses/:slug/discussions/create',
      name: 'course-discussions-create',
      component: CourseDiscussionsCreate,
      meta: {
        title: 'Добавить обсуждение'
      }
    },
    {
      path: 'courses/:slug/modules',
      name: 'course-modules',
      component: CourseModules,
      meta: {
        title: 'Модули'
      }
    },
    {
      path: 'courses/:slug/users',
      name: 'course-users',
      component: CourseUsers,
      meta: {
        title: 'Пользователи курса'
      }
    },
    {
      path: 'courses/:slug/users/:id/show',
      name: 'course-users-show',
      component: CourseUsersShow,
      meta: {
        title: 'Просмотр пользователя курса'
      }
    },
    {
      path: 'courses/:slug/users/:id/update',
      name: 'course-users-update',
      component: CourseUsersUpdate,
      meta: {
        title: 'Редактирование пользователя курса'
      }
    },
    {
      path: 'courses/:slug/users/create',
      name: 'course-users-create',
      component: CourseUsersCreate,
      meta: {
        title: 'Добавление пользователя к курсу'
      }
    },
    {
      path: 'pages',
      name: 'pages',
      component: Pages,
      meta: {
        title: 'Страницы'
      }
    },
    {
      path: 'pages/create',
      name: 'pages-create',
      component: PageCreate,
      meta: {
        title: 'Создание страницы'
      }
    },
    {
      path: 'pages/show/:slug',
      name: 'pages-show',
      component: PageShow,
      meta: {
        title: 'Просмотр страницы'
      }
    },
    {
      path: 'pages/update/:slug',
      name: 'pages-update',
      component: PageUpdate,
      meta: {
        title: 'Редактировать страницу'
      }
    },
    {
      path: 'profile',
      name: 'profile',
      component: Profile,
      meta: {
        title: 'Профиль'
      }
    },
    {
      path: 'profile/settings',
      name: 'profile-settings',
      component: ProfileSettings,
      meta: {
        title: 'Редактировать профиль'
      }
    },
    {
      path: 'profile/change-password',
      name: 'profile-change-password',
      component: ProfileChangePassword,
      meta: {
        title: 'Редактировать пароль'
      }
    },
    {
      path: 'profile/social-networks',
      name: 'profile-social-networks',
      component: ProfileSocialNetworks,
      meta: {
        title: 'Социальные сети'
      }
    }
  ]
},
{
  path: '/login',
  name: 'login',
  component: Login,
  meta: {
    title: 'Вход'
  }
},
{
  path: '/change-password',
  name: 'change-password',
  component: VerifyEmail,
  meta: {
    title: 'Забыли пароль'
  }
},
{
  path: '/reset-password',
  name: 'reset-password',
  component: ResetPassword,
  meta: {
    title: 'Сбросить пароль'
  }
},
{
  path: '/error-page',
  name: 'error-page',
  component: ErrorPage,
  meta: {
    title: '404 ничего не найдео !'
  }
},
{
  path: '/:pathMatch(.*)*',
  component: ErrorPage,
  meta: {
    title: '404 ничего не найдео !'
  }
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || {
      left: 0,
      top: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} - ${to.meta.title}`
  const publicPages = ['/login', '/register', '/change-password']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
