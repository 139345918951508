/* eslint-disable camelcase */

const state = () => {
  return {
    modules: [],
    slug: null
  }
}

const actions = {
  async getModules({
    commit
  }, course_slug) {
    try {
      const response = await this.$api.get(`dashboard/course/${course_slug}/modules`)
      if (response) {
        commit('SET_MODULES', response)
      }
    } catch (error) {

    }
  }
}

const mutations = {
  SET_MODULES(state, modules) {
    state.modules = modules
  },
  SET_MODULE_SLUG(state, slug) {
    state.slug = slug
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
