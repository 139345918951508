<template>
  <!-- BEGIN: Success Notification Content -->
  <div id="success-notification-content" class="toastify-content hidden flex">
    <CheckCircleIcon class="text-theme-9" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Успешно!</div>
      <div class="text-gray-600 mt-1">
        Процедура прошла успешно !
      </div>
    </div>
  </div>
  <!-- END: Success Notification Content -->
</template>
