<template>
  <div class="grid grid-cols-12 gap-6 mt-8">
    <div class="col-span-12 lg:col-span-3 2xl:col-span-2">
      <h2 class="intro-y text-lg font-medium mr-auto mt-2">
        Файловый менеджер
      </h2>
      <!-- BEGIN: File Manager Menu -->
      <div class="intro-y box p-5 mt-6">
        <div class="mt-1">
          <button
            @click="setTypeMedia('all')"
            type="button"
            :class="[
              { 'bg-theme-1 text-white font-medium': isSelected == 'all' },
              'flex items-center px-3 py-2 rounded-md w-full '
            ]"
          >
            <LayersIcon class="w-4 h-4 mr-2" /> Все Файлы
          </button>
          <button
            @click="setTypeMedia('image')"
            type="button"
            :class="[
              { 'bg-theme-1 text-white font-medium': isSelected == 'image' },
              'flex items-center px-3 py-2 rounded-md w-full '
            ]"
          >
            <ImageIcon class="w-4 h-4 mr-2" /> Изображение
          </button>
          <button
            @click="setTypeMedia('video')"
            type="button"
            :class="[
              { 'bg-theme-1 text-white font-medium': isSelected == 'video' },
              'flex items-center px-3 py-2 rounded-md w-full '
            ]"
          >
            <VideoIcon class="w-4 h-4 mr-2" /> Видео
          </button>
          <button
            @click="setTypeMedia('files')"
            type="button"
            :class="[
              { 'bg-theme-1 text-white font-medium': isSelected == 'files' },
              'flex items-center px-3 py-2 rounded-md w-full '
            ]"
          >
            <FileIcon class="w-4 h-4 mr-2" /> Файлы
          </button>
          <button
            @click="setTypeMedia('archive')"
            type="button"
            :class="[
              { 'bg-theme-1 text-white font-medium': isSelected == 'archive' },
              'flex items-center px-3 py-2 rounded-md w-full '
            ]"
          >
            <ArchiveIcon class="w-4 h-4 mr-2" /> Архивы
          </button>
          <button
            @click="setTypeMedia('deleted')"
            type="button"
            :class="[
              { 'bg-theme-1 text-white font-medium': isSelected == 'deleted' },
              'flex items-center px-3 py-2 rounded-md w-full '
            ]"
          >
            <TrashIcon class="w-4 h-4 mr-2" /> Удаленные
          </button>
        </div>
      </div>
      <!-- END: File Manager Menu -->
    </div>
    <div class="col-span-12 lg:col-span-9 2xl:col-span-10">
      <!-- BEGIN: File Manager Filter -->
      <div
        class="
          intro-y
          flex flex-col-reverse
          sm:flex-row
          items-center
          justify-end
        "
      >
        <div class="w-full sm:w-auto flex">
          <button
            class="btn btn-warning py-1 px-2 mr-2 intro-x"
            v-if="selected.length > 1"
            @click="selected = []"
          >
            Отменить
          </button>
          <button
            class="btn btn-danger py-1 px-2 mr-2 intro-x"
            v-if="selected.length > 1"
            @click="$refs.deleteModal.openModal(selected)"
          >
            Удалить выбранные
          </button>
          <router-link
            to="/file-manager/create"
            class="btn btn-primary shadow-md mr-2"
          >
            Загрузить новый Файл
          </router-link>
        </div>
      </div>
      <!-- END: File Manager Filter -->
      <!-- BEGIN: Directory & Files -->
      <div class="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-5">
        <div
          v-for="media in medias.data"
          :key="media.id"
          class="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2"
        >
          <div
            class="file box rounded-md px-5 pt-8 pb-5 sm:px-5 relative zoom-in"
          >
            <div class="absolute left-0 top-0 mt-3 ml-3">
              <input
                class="form-check-input border border-gray-500"
                type="checkbox"
                :value="media.id"
                v-model="selected"
                id="file-delete"
              />
            </div>
            <a
              href="javascript:;"
              class="w-3/5 file__icon file__icon--empty-directory mx-auto"
              v-if="media.mime_type.search('application') >= 0"
            ></a>
            <a
              href="javascript:;"
              class="w-3/5 file__icon file__icon--directory mx-auto"
              v-else-if="media.mime_type.search('file') >= 0"
            ></a>
            <a
              href="javascript:;"
              class="w-3/5 file__icon file__icon--image mx-auto"
              v-else-if="media.mime_type.search('image') >= 0"
            >
              <div class="file__icon--image__preview image-fit">
                <img
                  :alt="media.name"
                  :src="
                    media.thumbnail_url ||
                      require(`@/assets/images/preview-1.jpg`)
                  "
                />
              </div>
            </a>
            <a
              v-else
              href="javascript:;"
              class="w-3/5 file__icon file__icon--file mx-auto"
            >
              <div class="file__icon__file-name">
                {{ media.mime_type }}
              </div>
            </a>
            <a
              href="javascript:;"
              class="block font-medium mt-4 text-center truncate"
              >{{ media.file_name }}</a
            >
            <div class="text-gray-600 text-xs text-center mt-0.5">
              {{ parseFloat(media.size / 1024).toFixed(2) }} кб
            </div>
            <div class="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
              <a
                class="dropdown-toggle w-5 h-5 block"
                href="javascript:;"
                aria-expanded="false"
              >
                <MoreVerticalIcon class="w-5 h-5 text-gray-600" />
              </a>
              <div class="dropdown-menu w-40">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <a
                    :href="media.url"
                    :download="media.name"
                    class="
                      flex
                      items-center
                      p-2
                      transition
                      duration-300
                      ease-in-out
                      bg-white
                      dark:bg-dark-1
                      hover:bg-gray-200
                      dark:hover:bg-dark-2
                      rounded-md
                    "
                  >
                    <LinkIcon class="w-4 h-4 mr-2" /> Скачать
                  </a>
                  <a
                    href="javascript:;"
                    aria-expanded="false"
                    @click="copy(media.url, media.mime_type)"
                    class="
                      flex
                      items-center
                      p-2
                      transition
                      duration-300
                      ease-in-out
                      bg-white
                      dark:bg-dark-1
                      hover:bg-gray-200
                      dark:hover:bg-dark-2
                      rounded-md
                    "
                  >
                    <PaperclipIcon class="w-4 h-4 mr-2" /> Копировать
                  </a>
                  <a
                    href="javascript:;"
                    aria-expanded="false"
                    @click="$refs.deleteModal.openModal([media.id])"
                    class="
                      flex
                      items-center
                      p-2
                      transition
                      duration-300
                      ease-in-out
                      bg-white
                      dark:bg-dark-1
                      hover:bg-gray-200
                      dark:hover:bg-dark-2
                      rounded-md
                    "
                  >
                    <TrashIcon class="w-4 h-4 mr-2" /> Удалить
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Directory & Files -->
      <AlertRemove ref="deleteModal" :onDelete="onDelete" />
      <Pagination
        v-if="medias"
        :per_page="medias.per_page"
        :total="medias.total"
        :current-page="currentPage"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      medias: [],
      selected: [],
      isSelected: 'all'
    }
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1')
    }
  },
  watch: {
    currentPage() {
      this.init(this.currentPage)
    }
  },
  mounted() {
    this.init(this.currentPage)
  },
  methods: {
    init(page) {
      this.$api
        .get('/dashboard/media', {
          params: { page: page, mime: this.isSelected }
        })
        .then(response => {
          this.medias = response
        })
    },
    setTypeMedia(type) {
      this.isSelected = type
      this.init(1)
      this.$router.push({ query: { page: 1 } })
    },
    copy(url, type) {
      // eslint-disable-next-line no-useless-catch
      try {
        let copy = url
        if (type == 'video/mp4') {
          copy = `<video width="642" height="361" src="${url}" controls></video>`
        }
        navigator.clipboard.writeText(copy)
        confirm('Скопированно в буфер обмена')
      } catch (e) {
        throw e
      }
    },
    onDelete(deletingItem) {
      this.$api
        .delete('/dashboard/media', { params: { medias: deletingItem } })
        .then(response => {
          this.init()
        })
    }
  }
}
</script>
