<template>
    <div class="answers col-span-12 space-y-2 intro-y">
        <ul class="grid grid-cols-2 gap-x-5 mx-auto">
            <li class="relative">
                <input class="sr-only peer" type="radio" :value="variant.answers.indexOf('Да')" v-model="variant.correct_answer" id="answer_yes">
                <label class="flex p-5 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-green-500 peer-checked:ring-2 peer-checked:border-transparent font-bold" for="answer_yes">
                    Да
                </label>

                <div class="absolute hidden w-5 h-5 peer-checked:block top-5 right-3">
                    👍
                </div>
            </li>
            <li class="relative">
                <input class="sr-only peer" type="radio" :value="variant.answers.indexOf('Нет')" v-model="variant.correct_answer" id="answer_no">
                <label class="flex p-5 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-red-500 peer-checked:ring-2 peer-checked:border-transparent font-bold" for="answer_no">
                    Нет
                </label>

                <div class="absolute hidden w-5 h-5 peer-checked:block top-5 right-3">
                    👎
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  props: ['saver', 'count'],
  watch: {
    variant: {
      handler(newVariant) {
        this.saver(newVariant)
      },
      deep: true
    },
    count() {
      Object.assign(this.$data, this.$options.data())
    }
  },
  data() {
    return {
      // The main logic
      variant: {
        answers: ['Да', 'Нет'],
        correct_answer: 0
      }
    }
  },
  methods: {
    validate() {}
  }
}
</script>
