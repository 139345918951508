<template>
    <div class="intro-y box px-5 pt-5 mt-5">
        <div class="
              flex flex-col
              lg:flex-row
              border-b border-gray-200
              dark:border-dark-5
              pb-5
              -mx-5
            ">
            <div class="flex flex-1 px-5 items-center justify-center lg:justify-start">
                <div class="
                  w-20
                  h-20
                  sm:w-24 sm:h-24
                  flex-none
                  lg:w-32 lg:h-32
                  image-fit
                  relative
                ">
                    <img :alt="info?.user?.fullname" class="rounded-full" :src="
                    info?.user?.image
                      ? info?.user?.image
                      : require(`@/assets/images/${'profile-1.jpg'}`)
                  " />
                </div>
                <div class="ml-5">
                    <div class="
                    w-24
                    sm:w-40
                    truncate
                    sm:whitespace-normal
                    font-medium
                    text-lg
                  ">
                        {{ info?.user?.fullname }}
                    </div>
                    <div class="text-gray-600 truncate sm:whitespace-normal">
                        <div class="flex items-center">
                            {{ info?.user?.email }}
                        </div>
                    </div>
                    <div class="truncate sm:whitespace-normal mt-1.5">
                        <div class="flex items-center gap-2">
                            <span class="text-gray-700 ">Баланс:</span> <span class="text-theme-9 font-medium">{{ info?.user?.balance * 100 }} TJS</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="
                mt-6
                lg:mt-0
                flex-1
                dark:text-gray-300
                px-5
                border-l border-r border-gray-200
                dark:border-dark-5
                border-t
                lg:border-t-0
                pt-5
                lg:pt-0
              ">
                <div class="font-medium text-center lg:text-left lg:mt-3">
                    Социальные сети
                </div>
                <div class="
                  flex flex-col
                  justify-center
                  items-center
                  lg:items-start
                ">
                    <div class="flex flex-col justify-center items-center lg:items-start">
                        <div class="truncate sm:whitespace-normal flex items-center mt-3"
                            v-for="(item, index) in info?.user?.social_links" :key="index">
                            <a :href="item.link" target="_blank" class="hover:text-theme-1">
                                <FacebookIcon class="w-4 h-4 mr-2" v-if="item?.name == 'Facebook'" />
                                <InstagramIcon class="w-4 h-4 mr-2" v-if="item?.name == 'Instagram'" />
                                <YoutubeIcon class="w-4 h-4 mr-2" v-if="item?.name == 'YouTube'" />
                                <SendIcon class="w-4 h-4 mr-2" v-if="item?.name == 'Telegram'" />
                                <AnchorIcon class="w-4 h-4 mr-2" v-if="item?.name == 'VK'" />
                                {{ item?.name }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="
                mt-6
                lg:mt-0
                flex-1 flex
                items-center
                justify-center
                px-5
                border-t
                lg:border-0
                border-gray-200
                dark:border-dark-5
                pt-5
                lg:pt-0
              ">
                <div class="text-center rounded-md w-20 py-3">
                    <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">
                        {{ info.medias }}
                    </div>
                    <div class="text-gray-600">Файлов</div>
                </div>
                <div class="text-center rounded-md w-20 py-3">
                    <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">
                        {{ info.courses }}
                    </div>
                    <div class="text-gray-600">Курсов</div>
                </div>
                <div class="text-center rounded-md w-20 py-3">
                    <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">
                        {{ info.ratings }}
                    </div>
                    <div class="text-gray-600">Отзывов</div>
                </div>
            </div>
        </div>
        <div class="
              nav nav-tabs
              flex-col
              sm:flex-row
              justify-center
              lg:justify-start
            " role="tablist">
            <a id="account-tab" data-toggle="tab" data-target="#account" href="javascript:;"
                class="py-4 sm:mr-8 flex items-center active" role="tab" aria-selected="true">
                <ShieldIcon class="w-4 h-4 mr-2" /> Личные данные
            </a>
            <a id="courses-tab" data-toggle="tab" data-target="#courses" href="javascript:;"
                class="py-4 sm:mr-8 flex items-center" role="tab" aria-selected="false">
                <BookIcon class="w-4 h-4 mr-2" /> Курсы
            </a>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: ['info']
})
</script>
