import Toastify from 'toastify-js'

const toaster = ({ ...props }, messages) => {
  Toastify({
    duration: 3000,
    newWindow: true,
    close: true,
    gravity: 'top',
    position: 'right',
    stopOnFocus: true,
    ...props
  }).showToast()
}

export const responser = response => {
  switch (response.status) {
    case 200:
    case 201:
    case 204:
      if (response.config.method !== 'get') {
        toaster({
          node: cash('#success-notification-content')
            .clone()
            .removeClass('hidden')[0]
        })
      }
      break
    case 400:
    case 401:
    case 403:
    case 404:
    case 422:
    case 500:
      if (response.data && response.data.errors) {
        const messages = response.data.errors.map(
          message => `<li>${message}</li>`
        )
        toaster({
          node: cash('#failed-notification-content')
            .find('.notification-message')
            .empty()
            .append(...messages)
            .closest('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0]
        })
      } else if (response.data && response.data.message) {
        toaster({
          node: cash('#failed-notification-content')
            .find('.notification-message')
            .empty()
            .append(`<li>${response.data.message}</li>`)
            .closest('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0]
        })
      } else {
        toaster({
          node: cash('#failed-notification-content')
            .find('.notification-message')
            .empty()
            .append('<li>Произошла ошибка при выполнении запроса</li>')
            .closest('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0]
        })
      }
      break
    default:
      toaster({
        node: cash('#failed-notification-content')
          .find('.notification-message')
          .empty()
          .append('<li>Произошла ошибка при выполнении запроса</li>')
          .closest('#failed-notification-content')
          .clone()
          .removeClass('hidden')[0]
      })
      break
  }
}
