const state = () => {
  return {
    menu: []
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {
  SET_MENU(state, menu) {
    state.menu = menu
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
