<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="/" class="-intro-x flex items-center pt-5">
            <img
              alt="Smarthub"
              class="w-32"
              :src="require(`@/assets/images/logo.svg`)"
            />
          </a>
          <div class="my-auto">
            <img
              alt="Smarthub"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              Еще несколько кликов, чтобы<br />
              войти в свой аккаунт.
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >Сбросить пароль</h2>
            <p class="intro-x mt-2 text-gray-500 xl:block hidden">Заполните форму ниже чтобы сбросить <br /> пароль, я <router-link :to="{name: 'login'}" class="text-theme-4 font-medium">вспомнил пароль !</router-link></p>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              Еще несколько кликов, чтобы войти в свой аккаунт. <router-link :to="{name: 'login'}" class="text-theme-4 font-medium">Вспомнил пароль !</router-link>
            </div>
            <form @submit.prevent="handleReset">
              <div class="password mt-4">
                <div class="intro-x relative w-full js-parent">
                  <div class="absolute inset-y-0 right-0 flex items-center px-2">
                    <input class="hidden js-password-toggle" id="passwordToggle" type="checkbox" @change="passwordToggle" />
                    <label class="absolute inset-y-0 right-0 flex items-center pr-3 top-1 js-password-label cursor-pointer" for="passwordToggle">
                        <span class="show block">
                          <EyeIcon class="h-6 w-6 text-gray-600"/>
                        </span>
                        <span class="hide hidden">
                          <EyeOffIcon class="h-6 w-6 text-gray-600"/>
                        </span>
                    </label>
                  </div>
                  <div class="mt-1">
                      <input id="password" type="password" minlength="4"  v-model.trim="validate.password.$model"
                      :class="{ 'border-theme-6': validate.password.$error }"
                      placeholder="Введите пароль"
                      required  class="form-control py-3 px-4 border-gray-300 js-password" />
                  </div>
                </div>
                <template v-if="validate.password.$error">
                  <div
                    v-for="(error, index) in validate.password.$errors"
                    :key="index"
                    class="text-theme-6 mt-2"
                  >
                    {{ error.$message }}
                  </div>
                </template>
              </div>
              <div class="password mt-4">
                <div class="intro-x relative w-full js-parent">
                  <div class="absolute inset-y-0 right-0 flex items-center px-2">
                    <input class="hidden js-password-toggle" id="password_confirmationToggle" type="checkbox" @change="passwordToggle" />
                    <label class="absolute inset-y-0 right-0 flex items-center pr-3 top-1 js-password-label cursor-pointer" for="password_confirmationToggle">
                        <span class="show block">
                          <EyeIcon class="h-6 w-6 text-gray-600"/>
                        </span>
                        <span class="hide hidden">
                          <EyeOffIcon class="h-6 w-6 text-gray-600"/>
                        </span>
                    </label>
                  </div>
                  <div class="mt-1">
                      <input id="password_confirmation" type="password" minlength="4"  v-model.trim="validate.password_confirmation.$model"
                      :class="{ 'border-theme-6': validate.password_confirmation.$error }"
                      placeholder="Введите пароль"
                      required  class="form-control py-3 px-4 border-gray-300 js-password" />
                  </div>
                </div>
                <template v-if="validate.password_confirmation.$error">
                  <div
                    v-for="(error, index) in validate.password_confirmation.$errors"
                    :key="index"
                    class="text-theme-6 mt-2"
                  >
                    {{ error.$message }}
                  </div>
                </template>
              </div>
              <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                <button type="submit"
                  class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top">
                  Сбросить
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import {
  required,
  minLength,
  maxLength,
  email
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'

export default defineComponent({
  components: {
    DarkModeSwitcher
  },
  setup() {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })
    const message = ref('')
    const form = reactive({
      email: '',
      token: '',
      password: '',
      password_confirmation: ''
    })
    const rules = {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(16)
      },
      password_confirmation: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(16)
      }
    }
    const validate = useVuelidate(rules, toRefs(form))
    return {
      validate,
      form,
      message
    }
  },
  methods: {
    passwordToggle(event) {
      const dels = event.target.closest('.js-parent')
      const password = dels.querySelector('.js-password')
      const passwordLabelShow = dels.querySelector('.js-password-label .show')
      const passwordLabelHide = dels.querySelector('.js-password-label .hide')

      if (password.type === 'password') {
        password.type = 'text'
        passwordLabelShow.classList.add('hidden')
        passwordLabelHide.classList.remove('hidden')
      } else {
        password.type = 'password'
        passwordLabelShow.classList.remove('hidden')
        passwordLabelHide.classList.add('hidden')
      }
      password.focus()
    },
    handleReset() {
      this.$api.post('auth/password/reset', this.form).then(
        (response) => {
          this.$router.push('/')
        }
      )
    }
  }
})
</script>
