<template>
  <div id="update-module">
    <div class="preview">
      <!-- BEGIN: Modal Content -->
      <div
        id="update-module-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <form @submit.prevent="updateModule" class="modal-content">
            <!-- BEGIN: Modal Header -->
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">Изменить модуль</h2>
              <button type="button" data-dismiss="modal">
                <XCircleIcon class="w-6 h-6" />
              </button>
            </div>
            <!-- END: Modal Header -->
            <!-- BEGIN: Modal Body -->
            <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
              <div class="col-span-12">
                <label for="module-title" class="form-label">
                  Название модуля
                </label>
                <input
                  id="module-title"
                  type="text"
                  v-model="form.title"
                  class="form-control"
                  placeholder="Введите название модуля"
                />
              </div>
              <div class="col-span-12">
                <div class="form-check mt-2">
                  <input
                    id="is-block-until"
                    v-model="isUntilBlock"
                    class="form-check-input"
                    type="checkbox"
                    value="1"
                  />
                  <label class="form-check-label" for="is-block-until"
                  >Заблокировать до</label
                  >
                </div>
                <div v-if="isUntilBlock" class="preview mt-4">
                  <div class="relative">
                    <div
                      class="
                        absolute
                        rounded-l
                        w-10
                        h-full
                        flex
                        items-center
                        justify-center
                        bg-gray-100
                        border
                        text-gray-600
                        dark:bg-dark-1 dark:border-dark-4
                      "
                    >
                      <CalendarIcon class="w-4 h-4" />
                    </div>
                    <Litepicker
                      v-model="form.block_untill"
                      :options="{
                        autoApply: false,
                        showWeekNumbers: true,
                        dropdowns: {
                          minYear: 1990,
                          maxYear: null,
                          months: true,
                          years: true
                        },
                        format: 'YYYY-MM-DD 00:00:00'
                      }"
                      class="form-control pl-12"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-12" v-if="modules.length > 0">
                <label
                  for="block_until_module_id"
                  class="form-label font-medium"
                >
                  Заблокировать до прохождения модуля
                </label>
                <TomSelect
                  id="block_until_module_id"
                  v-model="form.block_until_module_id"
                  :options="{ placeholder: 'Выберите опцию для модуля' }"
                  class="w-full"
                >
                  <option value>Выберите модуль</option>
                  <option
                    v-for="module in modules"
                    :key="module.id"
                    :value="module.id"
                    >{{ module.title }}
                  </option>
                </TomSelect>
              </div>
            </div>
            <!-- END: Modal Body -->
            <!-- BEGIN: Modal Footer -->
            <div class="modal-footer text-right">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-auto mr-1 px-2.5"
              >
                Отменить
              </button>
              <button type="submit" class="btn btn-primary w-auto px-2.5">
                Изменить модуль
              </button>
            </div>
            <!-- END: Modal Footer -->
          </form>
        </div>
      </div>
      <!-- END: Modal Content -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['courseId'],
  data() {
    return {
      isUntilBlock: false,
      modules: [],
      form: {
        title: '',
        block_untill: '',
        block_until_module_id: '',
        position: 1,
        course_id: '',
        slug: ''
      }
    }
  },
  methods: {
    init(data) {
      console.log(data)
      cash('#update-module-modal').modal('show')

      const modules = this.$store.state.modules.modules
        .filter(module => module.slug !== data.slug)
        .filter(module => {
          return module.position < data.position
        })

      this.modules = modules

      this.form = {
        title: data.title,
        block_untill: data.block_untill,
        position: data.position,
        course_id: data.course_id,
        block_until_module_id: data.block_until_module_id?.toString() ?? '',
        slug: data.slug
      }
    },
    updateModule() {
      this.form.course_id = this.courseId
      this.$api
        .put(`/dashboard/course/${this.$route.params.slug}/modules/${this.form.slug}`, this.form)
        .then((result) => {
          cash('#update-module-modal').modal('hide')
          this.$store.dispatch('modules/getModules', this.$route.params.slug)
          this.isUntilBlock = false
          this.form = {
            title: '',
            block_untill: '',
            block_until_module_id: '',
            position: 1,
            course_id: ''
          }
        })
    }
  }
}
</script>
