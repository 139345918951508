<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Заявки на возможности</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div class="dropdown">
          <button
            class="dropdown-toggle btn box flex items-center"
            aria-expanded="false"
          >
            Фильтрировать <ChevronDownIcon class="w-4 h-4 ml-2" />
          </button>
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <span @click="currentStatus = 'active'"
                class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"
              >
                <ActivityIcon class="w-4 h-4 mr-2" /> Просмотренный
              </span>
              <span @click="currentStatus = 'pending'"
                class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"
              >
                <ActivityIcon class="w-4 h-4 mr-2" /> В ожидании
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-10">
      <!-- BEGIN: Data List -->
      <div class="intro-y z-10 col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Ф.И.О</th>
              <th class="text-center whitespace-nowrap">Телефон</th>
              <th class="text-center whitespace-nowrap">Статус</th>
              <th class="text-center whitespace-nowrap">Действие</th>
            </tr>
          </thead>
          <tbody v-if="applications">
            <tr
              v-for="(application, index) in applications.data"
              :key="index"
              class="intro-x"
            >
              <td>
                <router-link
                  :to="{ name: 'possibilities-applications-show', params: { slug: $route.params.slug, id: application.id } }"
                  class="font-medium whitespace-nowrap"
                  >{{ application.fullname }}</router-link
                >
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  {{ application.email }}
                </div>
              </td>
              <td class="text-center">{{ application.phone }}</td>
              <td class="w-40">
                <div
                  class="flex items-center justify-center"
                  :class="{
                    'text-theme-12': application?.status.name == 'pending',
                    'text-theme-9': application?.status.name == 'active'
                  }"
                >
                  <span v-if="application?.status.name == 'active'">
                    <CheckSquareIcon class="w-4 h-4 mr-2" />
                    Просмотренно
                  </span>
                   <span v-else>
                    <ClockIcon class="w-4 h-4 mr-2" />
                    В ожидании
                  </span>
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link
                    class="flex items-center mr-3"
                    :to="{ name: 'possibilities-applications-show', params: { slug: $route.params.slug, id: application.id } }">
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Открыть
                  </router-link>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    @click="$refs.deleteModal.openModal(application.id)"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Удалить
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <Pagination
        :per_page="applications.per_page"
        :total="applications.total"
        :current-page="currentPage"
      />
      <AlertRemove ref="deleteModal" :onDelete="onDelete" />
      <!-- END: Pagination -->
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      applications: [],
      currentStatus: 'pending'
    }
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1')
    }
  },
  watch: {
    currentPage() {
      this.getApplications()
    },
    currentStatus() {
      this.currentPage = 1
      this.getApplications()
    }
  },
  mounted() {
    this.getApplications()
  },
  methods: {
    async getApplications() {
      this.$api.get(`/dashboard/opportunities/${this.$route.params.slug}/applications`, { params: { status: this.currentStatus, page: this.currentPage } }).then(response => {
        this.applications = response
      })
    },
    onDelete(deletingItem) {
      this.$api.delete(`/dashboard/opportunities/${this.$route.params.slug}/applications/` + deletingItem)
      this.init()
    }
  }
}
</script>
